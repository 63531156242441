import React from 'react';
import PropTypes from 'prop-types';
import ArticleHeader from './ArticleHeader';
import ArticleSideBanner from './ArticleSideBanner';
import BlogPageDetails from '../Blog/BlogPageDetails';

const ArticlePageDetails = ({ data }) => (
  <article className="columns is-multiline is-mobile m-promo-columns m-promo-columns-wrapper">
    <div className={`column content is-12-mobile is-12-tablet is-8-desktop is-9-widescreen ${(!data.show_side_banner || !data.side_banner) && ('is-12-desktop is-12-widescreen half-padding-right')}`}>
      <ArticleHeader
        title={data.title}
        image={data.header_image}
        video={data.header_video}
        show={data.meta.show_title}
      />
      <div className="m-promo-blog-article content typography has-link-underline" dangerouslySetInnerHTML={{ __html: data.body }} />
      {data.meta.parent.meta.type === 'content.BlogPage'
        && <BlogPageDetails data={data} />}
    </div>
    {
      data.show_side_banner && data.side_banner
      // eslint-disable-next-line max-len
      && <ArticleSideBanner image={data.side_banner.meta.download_url} link={data.side_banner_link} />
    }
  </article>
);

ArticlePageDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ArticlePageDetails;
