import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import 'flickity-fade';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CarouselCell from './CarouselCell';
import {
  clipHTMLBody,
  getPathname,
  isUserLoggedIn,
} from '../Helpers';

class HeroBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.flktyRef = null;
  }

  componentDidMount() {
    const { carousel } = this.props;
    const durations = this.getDurations(carousel);
    this.flktyRef.on('select', () => {
      this.flktyRef.options.autoPlay = durations[this.flktyRef.selectedIndex];
    });
  }

  getDurations = items => (
    items.map(item => item.desktop_video?.duration * 1000 || 4000)
  );

  onButtonClick = ({ button_external_link, button_target }) => {
    const { props } = this;
    if (button_target) {
      props.history.push(getPathname(button_target.html_url));
    } else if (button_external_link.trim() === '') {
      this.openSignUp();
    } else {
      window.location.href = button_external_link;
    }
  };

  openSignUp = () => {
    const { props } = this;
    props.onToggleModal();
    clipHTMLBody();
  };

  carouselCells = () => {
    const { props } = this;

    let key = 0;
    return props.carousel.map((item) => {
      key += 1;

      return (
        <CarouselCell
          key={key}
          videoSource={item.desktop_video.url || null}
          imageSource={item.desktop_image.url || null}
          mobileVideoSource={item.mobile_device_video.url || null}
          mobileImageSource={item.mobile_device_image.url || null}
          buttonLabel={item.button_label || ''}
          buttonColor={item.button_color || ''}
          buttonBackgroundColor={item.button_background_color || ''}
          caption={item.caption || ''}
          captionColor={item.caption_color || ''}
          title={item.title || ''}
          titleColor={item.title_color || ''}
          subtitle={item.subtitle || ''}
          subtitleColor={item.subtitle_color || ''}
          onClickAction={() => this.onButtonClick(item)}
          showContent={!isUserLoggedIn()}
        />
      );
    });
  };

  render() {
    const { props } = this;

    const flickityOptions = {
      lazyload: false,
      pageDots: true,
      prevNextButtons: false,
      fade: true,
      autoPlay: this.getDurations(props.carousel)[0],
      adaptiveHeight: true,
      imagesLoaded: true,
      pauseAutoPlayOnHover: false,
    };

    return (
      <Flickity
        className="carousel m-carousel js-carousel"
        options={flickityOptions}
        flickityRef={c => this.flktyRef = c}
      >
        {this.carouselCells()}
      </Flickity>
    );
  }
}

HeroBanner.propTypes = {
  carousel: PropTypes.instanceOf([]).isRequired,
  onToggleModal: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export const mapStateToProps = state => ({
  userLoggedIn: state.userState.userLoggedIn,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(HeroBanner);
