import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
/* istanbul ignore next */
class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default ErrorBoundary;
