import {
  TOGGLE_MODAL_IN,
  TOGGLE_MODAL_IN_TIME_OUT,
} from '../actions/signInModalActions';

const initialState = {
  isOpen: false,
  errorMessage: null,
  modalType: 'SIGN_IN',
  sourcePage: '',
  providerType: '',
  errorStatus: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_IN:
      return {
        ...state,
        isOpen: !state.isOpen,
        errorMessage: action.errorMessage,
        modalType: action.modalType,
        sourcePage: action.sourcePage,
        providerType: action.providerType,
        errorStatus: action.errorStatus,
      };
    case TOGGLE_MODAL_IN_TIME_OUT:
      return {
        ...state,
        isOpen: !state.isOpen,
        errorMessage: action.errorMessage,
        modalType: 'TIME_OUT',
      };
    default:
      return state;
  }
}