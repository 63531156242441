import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Casino from '@dialinvest/react-casino';
import BasePage from '../Base';
import 'flickity-fade';
import LoyaltyPageHeader from './LoyaltyPageHeader';
import LoyaltyPageHeaderLoggedIn from './LoyaltyPageHeaderLoggedIn';
import LoyaltyPageShop from './LoyaltyPageShop';
import LoyaltyPageLevelsTerms from './LoyaltyPageTerms';
import { clipHTMLBody, isUserLoggedIn } from '../../Helpers';
import { toggleModalLoyaltyShop } from '../../../redux/actions/loyaltyShopActions';

export class LoyaltyPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loyaltyList: [],
    };
  }

  componentDidMount() {
    this.getLoyaltyItems();
  }

  getLoyaltyItems = async () => {
    // eslint-disable-next-line no-return-await,implicit-arrow-linebreak
    const loyaltyList = await new Casino.models.Account().getLoyaltyItems();
    this.setState({ loyaltyList });
  };

  handleSignup = async (event) => {
    event.preventDefault();
    const { onToggleModalIn } = this.props;
    onToggleModalIn();
    clipHTMLBody();
  };

  handleShop = async (itemCode, itemArray) => {
    const { setToggleModalLoyaltyShop } = this.props;

    setToggleModalLoyaltyShop(itemCode, itemArray);
  };

  render() {
    const { t, data, i18n } = this.props;
    const { loyaltyList } = this.state;

    return (
      <BasePage data={data}>
        <div className="has-background-gradient-angled-space-dark-blue">
          <div className="container is-fluid m-loyalty-v2">
            {!isUserLoggedIn() && (
              <LoyaltyPageHeader
                onClick={this.handleSignup}
                data={data}
                t={t}
              />
            )}
            {isUserLoggedIn() && (
              <LoyaltyPageHeaderLoggedIn
                data={data}
                t={t}
              />
            )}
            {loyaltyList.data !== undefined
              && (
                <LoyaltyPageShop
                  loyaltyList={loyaltyList.data}
                  onClick={this.handleShop}
                  t={t}
                  i18n={i18n}
                />
              )}
          </div>
          <LoyaltyPageLevelsTerms
            data={data}
          />
        </div>
      </BasePage>
    );
  }
}

export const mapStateToProps = state => ({
  itemCode: state.loyaltyShopModal?.itemCode,
  loyaltyList: state.loyaltyShopModal?.loyaltyList,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModalIn: () => dispatch({ type: 'TOGGLE_MODAL_IN' }),
  // eslint-disable-next-line max-len
  setToggleModalLoyaltyShop: (itemCode, loyaltyList) => dispatch(toggleModalLoyaltyShop(itemCode, loyaltyList)),
});

LoyaltyPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
  }),
  t: PropTypes.func.isRequired,
  onToggleModalIn: PropTypes.func.isRequired,
  setToggleModalLoyaltyShop: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
};

LoyaltyPage.defaultProps = {
  data: {},
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(),
  withRouter,
)(LoyaltyPage);
