import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import MandatoryField from '../../Layout/Fields/MandatoryField';
import validate from '../../../containers/utitilies/validator_pwd_change_request';
import { RenderField } from '../../Layout/Fields/renderFields';

export const PasswordResetRequestModal = ({
  isOpen,
  message,
  success,
  submitPasswordReset,
  handleEmailChange,
  handleQuestionChange,
  handleAnswerChange,
  toggleModal,
  needSecurityQuestion,
  pristine,
  invalid,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const questions = [];
  let questionList = [];
  let index = 0;
  let itemCount = 0;

  while (i18n.exists(`security_questions:${index}`)) {
    questions.push({ name: t(`security_questions:${index}`), value: t(`security_questions:${index}`) });
    index += 1;
  }

  questionList = questions.map((item) => {
    itemCount += 1;
    return <option key={itemCount} value={item.name}>{item.name}</option>;
  });

  questionList.unshift(<option key={itemCount} hidden disabled selected="true" value="">{t('please_select')}</option>);

  return (
    <div id="modal-password-reset" className={`modal m-modal-base m-modal-password-reset modal-fx-superScaled ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card modal-content">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <h2 className="is-size-3 is-size-4-mobile has-text-white has-text-centered">
              {t('common:password_reset_request_header')}
            </h2>
          </div>
          <button
            type="button"
            id="delete"
            className="delete"
            aria-label="close"
            onClick={toggleModal}
          />
        </header>
        <section className="modal-card-body">
          <h3 className="is-size-4 has-text-light-grey">
            {t('common:password_reset_request_title')}
          </h3>
          <form>
            <fieldset className="m-fieldset">
              {message && (
                <div className={`notification ${success ? 'is-success' : 'is-danger'} content`}>
                  {message}
                </div>
              )}
              <legend className="m-legend is-size-6 is-size-6-mobile">
                {t('common:password_reset_request_text')}
              </legend>
              <MandatoryField />
              <div className="columns is-multiline">
                <div className="column field m-account-auto-margin">
                  <Field
                    name="email_address"
                    component={RenderField}
                    onChange={handleEmailChange}
                    id="reset_email_address"
                    icon="fa-envelope"
                    label={t('fields:email_address')}
                  />
                </div>
              </div>
              {needSecurityQuestion && (
                <div className="columns is-multiline">
                  <div className="column is-half-tablet field m-account-auto-margin">
                    <label htmlFor="secret_answer" id="secret_answer_id" className="label">
                      { t('fields:security_question') }
                      <div className="control has-icons-left">
                        <div className="select is-medium">
                          <select
                            id="reset_secret_question"
                            name="secret_question"
                            required="required"
                            onChange={handleQuestionChange}
                          >
                            {questionList}
                          </select>
                        </div>
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock" />
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className="column is-half-tablet field m-account-auto-margin">
                    <label htmlFor="secret_answer" id="secret_answer_id" className="label">
                      { t('fields:security_answer') }
                      <div className="control has-icons-left">
                        <input
                          className="input is-medium"
                          id="reset_secret_answer"
                          name="secret_answer"
                          required="required"
                          type="text"
                          onChange={handleAnswerChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock" />
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </fieldset>
          </form>
        </section>
        <footer className="modal-card-foot">
          <div className="columns is-centered is-full-width">
            <div className="column is-4-tablet is-hidden-mobile">
              <button
                className="button button-modal-close has-background-light is-medium is-full-width"
                onClick={toggleModal}
                type="button"
              >
                <i className="icon-close" />
                &nbsp;&nbsp;
                {t('buttons:close').toUpperCase()}
                &nbsp;&nbsp;
              </button>
            </div>
            <div className="column is-7-tablet has-text-centered">
              <button
                type="button"
                disabled={pristine || invalid}
                className={`button is-success is-medium is-full-width white-space-unset ${loading ? 'is-loading' : ''}`}
                onClick={submitPasswordReset}
              >
                <span className="is-size-6 is-size-5-tablet">{t('buttons:resetPassword')}</span>
                <span className="icon is-small">
                  <i className="fas fa-arrow-right" />
                </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

PasswordResetRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  submitPasswordReset: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  handleAnswerChange: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  needSecurityQuestion: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'resetRequestForm',
  validate,
})(PasswordResetRequestModal);
