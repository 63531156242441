import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { handleClickOutside, getPathname } from '../../Helpers';

const BlogCategoryMenuItem = (id, slug, category, label, clickedCategory, onClick) => (
  <NavLink
    onClick={onClick}
    key={id}
    to={
      {
        pathname: slug,
        category,
      }
    }
    className={`navbar-item ${clickedCategory === category ? 'is-active' : ''}`}
  >
    {label}
  </NavLink>
);

const BlogCategoryMenu = ({ data, title, clickedCategory }) => {
  const [isActive, setIsActive] = useState(false);
  const setAsFalse = () => setIsActive(false);

  const menuRef = useRef(null);
  useEffect(() => {
    handleClickOutside(menuRef, setAsFalse);
  }, [menuRef]);

  const menuItemsList = () => {
    let menuItems = [];
    let index = 0;
    if (data.categories) {
      menuItems = data.categories.map((item) => {
        index += 1;

        return BlogCategoryMenuItem(
          index, getPathname(data.meta.html_url), item.id, item.name, clickedCategory, setAsFalse,
        );
      });
    }
    index += 1;
    menuItems.unshift(BlogCategoryMenuItem(index, getPathname(data.meta.html_url), clickedCategory, setAsFalse));

    return menuItems;
  };

  return (
    <div className="m-promo-nav m-promo-columns-wrapper container">
      <div className="level is-mobile">
        <div className="level-left">
          <nav className="navbar" role="navigation" aria-label="game navigation" ref={menuRef}>
            <div className="navbar-start">
              <button onClick={() => setIsActive(!isActive)} type="button" className={`m-game-nav-button is-flex-touch is-hidden-desktop ${isActive ? ' is-active' : ''}`} aria-label="game-menu-button" aria-expanded="false" data-menu-target="promotionsNav">
                <div className="is-size-6" aria-label="game-menu-label">{title}</div>
                <div className={`navbar-burger burger is-flex-touch is-static ${isActive ? ' is-active' : ''}`}>
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </div>
              </button>
              <div className={`navbar-menu m-game-nav-menu is-size-5-widescreen ${isActive ? ' is-active' : ''}`} id="promotionsNav">
                {menuItemsList()}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

BlogCategoryMenu.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  clickedCategory: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
};

BlogCategoryMenu.defaultProps = {
  title: '',
  clickedCategory: '',
};

export default BlogCategoryMenu;
