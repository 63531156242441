import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Casino from '@dialinvest/react-casino';
import ArticleThumbnailList, { PlaceholderThumbnailList } from '../Article/ArticleThumbnailList';
import BlogCategoryMenu from './BlogCategoryMenu';

export const LoadButton = ({
  showButton, loadMoreHander, isLoading, label,
}) => (
  <div className="level-item">
    {showButton
      && (
        <button
          type="button"
          onClick={loadMoreHander}
          className={`button m-game-grid-button is-size-4 is-outlined is-primary is-centered ${isLoading ? 'is-loading' : ''}`}
        >
          {label}
        </button>
      )}
  </div>
);

LoadButton.propTypes = {
  showButton: PropTypes.bool.isRequired,
  loadMoreHander: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

LoadButton.defaultProps = {
  isLoading: false,
};

export class BlogArticleManager extends React.PureComponent {
  LIMIT = 16;

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      articles: undefined,
      category: 'all',
      loadMore: true,
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.updatedCategory()) {
      this.load();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldLoad(prevProps, prevState)) {
      this.load();
    }
  }

  updatedCategory = () => {
    const { location, match: { params: slug } } = this.props;
    const { category } = this.state;

    if (Object.keys(slug).length > 0) {
      const categoryBySlug = this.getCategoryBySlug(slug.slug);

      if (categoryBySlug && category !== categoryBySlug.id) {
        this.setState({ category: categoryBySlug.id, offset: 0, articles: undefined });
        return true;
      }

      return false;
    }

    if (location && location.category) {
      if (category !== location.category) {
        this.setState({ category: location.category, offset: 0, articles: undefined });
        return true;
      }
    }
    return false;
  };

  getCategoryBySlug = (slug) => {
    const { props } = this;

    return props.data.categories.find(category => category.slug === slug);
  };

  shouldLoad = (prevProps, prevState) => {
    const { category } = this.state;

    if (this.updatedCategory()) {
      return false;
    }

    if (category !== prevState.category) {
      return true;
    }

    return false;
  }

  fetch = async () => {
    const { data } = this.props;

    const { offset, category } = this.state;
    const today = moment().format('YYYY-MM-DD');
    let url = `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&limit=${this.LIMIT}&offset=${offset}&valid_to__gte=${today}&valid_from__lte=${today}&order=-first_published_at`;

    if (category && category !== 'all') {
      url = `${url}&article_categories=${category}`;
    }
    const result = await new Casino.FetchContent(url).perform();

    return result.items();
  }

  setArticles = (items) => {
    const { offset, articles } = this.state;

    if (articles) {
      this.setState({ articles: [...articles, ...items], offset: offset + items.length });
    } else {
      this.setState({ articles: items, offset: offset + items.length });
    }
  }

  setLoadMoreButton = (items) => {
    if (!items || this.LIMIT !== items.length) {
      this.setState({ loadMore: false });
    } else {
      this.setState({ loadMore: true });
    }
  }

  load = async () => {
    this.setState({ isLoading: true });

    const items = await this.fetch();
    this.setLoadMoreButton(items);
    this.setState({ isLoading: false });

    if (!items) return;

    this.setArticles(items);
  };

  loadMoreHander = () => {
    this.load();
  }

  render() {
    const { t, data } = this.props;
    const {
      articles, isLoading, loadMore, category,
    } = this.state;

    return (
      <>
        <BlogCategoryMenu
          data={data}
          title={t('common:blog')}
          clickedCategory={category || 'all'}
        />
        {articles
          && <ArticleThumbnailList items={articles} />}
        {isLoading ? <PlaceholderThumbnailList />
          : (
            <LoadButton
              showButton={loadMore}
              loadMoreHander={this.loadMoreHander}
              label={t('common:load_more')}
            />
          )}
      </>
    );
  }
}

BlogArticleManager.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    categories: PropTypes.instanceOf(Object),
  }),
  match: PropTypes.shape({
    params: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

BlogArticleManager.defaultProps = {
  data: {},
};

export default compose(
  withTranslation(),
  withRouter,
)(BlogArticleManager);
