import React from 'react';
import { useTranslation } from 'react-i18next';
import CookieBanner, { Cookies } from 'react-cookie-banner';

const acceptedCookies = () => {
  const cookies = new Cookies();

  return !!cookies.get('Cookie-Acceptance');
};

const CookieAcceptance = () => {
  const { t } = useTranslation();
  return (
    <div id="modal-cookie-popup" className={`modal m-modal-base modal-fx-superScaled ${acceptedCookies() ? '' : 'is-active'} has-background-transparent m-modal-contents-fixed-bottom has-notification`}>
      <div className="modal-content">
        <div className="notification has-background-dark-grey is-radiusless">
          <div className="media">
            <div className="media-left">
              <i className="fas fa-info-circle fa-2x has-text-white" />
            </div>
            <div className="media-content content">
              <h3 className="is-size-3 is-size-4-mobile has-text-success">{t('common:cookie_policy')}</h3>
              <p className="has-text-white">
                {t('common:cookie_description')}
                &nbsp;
                <a target="_blank" href="/cookie-policy">{t('common:cookie_policy')}</a>
              </p>
            </div>
            <div className="media-right">
              <CookieBanner
                onAccept={() => {
                  const popup = document.getElementById('modal-cookie-popup');
                  popup.classList.remove('is-active');
                }}
                message=""
                disableStyle
                buttonMessage={t('buttons:accept')}
                cookie="Cookie-Acceptance"
                dismissOnScroll={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieAcceptance;
