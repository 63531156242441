import React, { Component } from 'react';
import PropTypes from 'prop-types';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecords: props.totalRecords,
      totalRecordCount: props.totalRecordCount,
      currentPage: 1,
    };
    const { pageLimit = 10, totalRecords, totalRecordCount } = props;
    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 10;
    (totalRecordCount > 0)
      ? this.totalPages = Math.ceil(totalRecordCount / this.pageLimit)
      : this.totalPages = Math.ceil(totalRecords / this.pageLimit);
  }

  componentDidMount() {
    this.gotoPage(1);
  }

  componentDidUpdate(prevProps) {
    const { totalRecords, totalRecordCount, pageNum } = this.props;
    if (((
      prevProps.totalRecords === totalRecords && prevProps.totalRecordCount === totalRecordCount
    ) && prevProps.pageNum === pageNum)) return;
    this.setState({ totalRecords });
    (totalRecordCount > 0)
      ? this.totalPages = Math.ceil(totalRecordCount / this.pageLimit)
      : this.totalPages = Math.ceil(totalRecords / this.pageLimit);
    (pageNum > 0) ? this.gotoPage(pageNum) : this.gotoPage(1);
  }

  gotoPage = (page) => {
    const { onPageChanged = f => f } = this.props;
    const { totalRecords } = this.state;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords,
    };

    this.setState({ currentPage });

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClickProps = (page) => {
    const { onChange } = this.props;
    onChange(page);
  };

  handleClick = (evt, page, totalRecordCount) => {
    evt.preventDefault();
    // eslint-disable-next-line no-unused-expressions
    (totalRecordCount > 0)
      ? this.handleClickProps(page)
      : this.gotoPage(page);
  };

  fetchPageNumbers = () => {
    const { totalPages, state } = this;
    const { currentPage } = state;

    const totalNumbers = 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const beforeLastPage = totalPages - 1;

      const startPage = currentPage > 2 ? currentPage : 2;
      const endPage = currentPage < beforeLastPage ? currentPage : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [LEFT_PAGE, 1, '...', ...extraPages, ...pages, totalPages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [1, ...pages, ...extraPages, '...', totalPages, RIGHT_PAGE];
      } else if (leftSpill && rightSpill) {
        pages = [LEFT_PAGE, 1, ...pages, '...', totalPages, RIGHT_PAGE];
      }
      return pages;
    }
    return range(1, totalPages);
  };

  jumpCallLeft = (page, currentPage, totalRecordCount, text) => (
    <a
      id="jumpLeft"
      className={`pagination-link ${
        currentPage === page ? 'is-current' : ''
      }`}
      href="/#"
      aria-label={text}
      onClick={e => this.handleClick(e, page, totalRecordCount)}
    >
      {text}
    </a>
  );

  jumpCallRight = (page, currentPage, totalRecordCount, text) => (
    <a
      id="jumpRight"
      className={`pagination-link ${
        currentPage === page ? 'is-current' : ''
      }`}
      href="/#"
      aria-label={text}
      onClick={e => this.handleClick(e, page, totalRecordCount)}
    >
      {text}
    </a>
  );

  buttonCall = (page, currentPage, totalRecordCount) => (
    <a
      id="jumpPage"
      className={`pagination-link ${
        currentPage === page ? 'is-current' : ''
      }`}
      href="/#"
      aria-label={`Go to page ${page}`}
      onClick={e => this.handleClick(e, page, totalRecordCount)}
    >
      {page}
    </a>
  );

  render() {
    const { pLoading } = this.props;
    const { currentPage, totalRecords, totalRecordCount } = this.state;
    if (!totalRecords || this.totalPages === 1) return null;
    const pages = this.fetchPageNumbers();

    return (
      <>
        <nav className={`pagination ${pLoading ? 'is-hidden' : ''}`} role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            {pages.map((page) => {
              if (page === LEFT_PAGE) {
                return (
                  <li key={page}>
                    { this.jumpCallLeft(currentPage - 1, currentPage, totalRecordCount, 'Previous') }
                  </li>
                );
              }

              if (page === RIGHT_PAGE) {
                return (
                  <li key={page}>
                    <li>
                      { this.jumpCallRight(currentPage + 1, currentPage, totalRecordCount, 'Next') }
                    </li>
                  </li>
                );
              }
              if (page === '...') {
                return (
                  <li key={page}>
                    <li>
                      <span className="pagination-ellipsis">&hellip;</span>
                    </li>
                  </li>
                );
              }
              return (
                <li key={page}>
                  { this.buttonCall(page, currentPage, totalRecordCount) }
                </li>
              );
            })}
          </ul>
        </nav>
      </>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  totalRecordCount: PropTypes.number,
  pageNum: PropTypes.number,
  pageLimit: PropTypes.number,
  onPageChanged: PropTypes.func,
  onChange: PropTypes.func,
  pLoading: PropTypes.bool,
};

Pagination.defaultProps = {
  pageNum: null,
  totalRecordCount: 0,
  pageLimit: 10,
  pLoading: false,
};

export default Pagination;
