import React from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import PageTitle from '../PageTitle';

const InternalPage = ({ data }) => (
  <BasePage data={data}>
    <div className="has-background-dark main-content-parent is-viewport-height">
      <section className="section m-page-wrapper">
        <div className="container is-fluid">
          <div className="columns is-centered is-mobile">
            <div className="column is-12-mobile is-12-tablet is-9-widescreen">
              <div className="content">
                <PageTitle title={data.title} show={data.meta.show_title} />
                <div className="content" dangerouslySetInnerHTML={{ __html: data.body }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </BasePage>
);

InternalPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    meta: PropTypes.instanceOf(Object),
  }),
};

InternalPage.defaultProps = {
  data: {
    meta: {
      show_title: true,
    },
  },
};

export default InternalPage;
