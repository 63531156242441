import React from 'react';
import GameNavigation from '../GameNavigation';
import Games from './Games';

function GameBrowser() {
  return (
    <>
      <GameNavigation />
      <Games />
    </>
  );
}

export default GameBrowser;
