import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import store from '../../containers/App/store';
import { lowerCaseAllWordsExceptFirstLetters, formatDateToLocale } from '../Helpers';

export class TableGenerator extends PureComponent {
  onClick = (event, dispatchLink, parameterInfo) => {
    event.preventDefault();
    const action = {
      type: dispatchLink,
    };
    action.parameterInfo = parameterInfo;
    store.dispatch(action);
  };

  render() {
    const {
      values, columnValues, columnLinks, currency, t,
    } = this.props || {};
    const parameterInfo = [];

    return (
      <>
        <tr>
          {columnValues.map((item) => {
            let charSize = '';
            if (item.includes('-')) {
              if (item.includes(':')) {
                const currencyFirst = item.split(':')[0];

                const first = currencyFirst.split('-')[0];
                const second = currencyFirst.split('-')[1];
                values[item] = values[first] - values[second];

                if (values[item] !== '' && currency !== undefined) {
                  values[item] = `${currency}${values[item]}`;
                } else {
                  values[item] = 0;
                }
              } else {
                const first = item.split('-')[0];
                const second = item.split('-')[1];
                values[item] = values[first] - values[second];
              }
            } else if (item.includes('+')) {
              const first = item.split('+')[0];
              const second = item.split('+')[1];

              values[item] = values[first] + values[second];
            } else if (item.includes('?')) {
              const first = item.split('?')[0];
              const second = item.split('?')[1];

              (values[first] !== undefined && values[first] !== null)
                ? values[item] = values[first].toLowerCase()
                : values[item] = values[first];
              charSize = second;
            } else if (item.includes(':')) {
              const first = item.split(':')[0];
              const type = item.split(':')[1];
              if (type === 'link') {
                Object.keys(columnLinks[`${first}`].linkParameters).map((key) => {
                  parameterInfo[key] = values[columnLinks[`${first}`].linkParameters[key]];
                  return 1;
                });

                values[item] = (
                  <a
                    id={columnLinks[`${first}`].id}
                    href={columnLinks[`${first}`].href}
                    onClick={e => this.onClick(e, columnLinks[`${first}`].dispatch, parameterInfo)}
                    className={columnLinks[`${first}`].className}
                  >
                    {columnLinks[`${first}`].linkName}
                  </a>
                );
              } else if (type === 'currency') {
                values[item] = 0;
                if (values[first] !== '' && currency !== undefined) {
                  values[item] = `${currency}${values[first]}`;
                } else if (values[first] === '' && currency !== undefined) {
                  values[item] = `${currency}0`;
                }
              } else if (type === 'button') {
                if (values[first]) {
                  values[item] = (
                    <button
                      type="button"
                      value={values[first]?.id}
                      onClick={values[first]?.onClick}
                      className="button is-small is-rounded is-tiny-touch is-oultine is-primary is-centered"
                    >
                      {values[first]?.text}
                    </button>
                  );
                }
              }
            }

            let itemValue = values[item];

            if (item === 'triggerDate') itemValue = formatDateToLocale(values.triggerDateFormatted);
            if (item === 'expiryDate') itemValue = formatDateToLocale(values.expiryDateFormatted);

            if (item === 'subMethod' && values.subMethod === null && values.paymentMethod === 'CP_COINSPAID') {
              itemValue = 'Crypto';
            }

            if (item === 'subMethod' && values.pspService !== null && values.paymentMethod !== 'CP_COINSPAID') {
              itemValue = lowerCaseAllWordsExceptFirstLetters(values.pspService);
            }

            if (['status', 'tranType?capitalize'].includes(item)) itemValue = t(`${values[item]}`);

            return (
              <td key={item} style={{ textTransform: `${charSize}` }}>{itemValue}</td>
            );
          })}
        </tr>
      </>
    );
  }
}

export default withTranslation('account')(TableGenerator);
