import i18next from 'i18next';
import CMSApi from '../CMSApi';

class GamesApi extends CMSApi {
  constructor(baseUrl, authToken) {
    super(baseUrl, authToken);
    this.baseUrl = baseUrl;
    this.client.interceptors.request.use((config) => {
      const extendedHeaders = {
        ...config.headers,
        'content-language': i18next.language,
      };
      config.headers = extendedHeaders;
      return config;
    },
    error => Promise.reject(error));
  }
}

export default new GamesApi(
  process.env.REACT_APP_API_BASE_URL,
  process.env.REACT_APP_CMS_AUTHORIZATION,
);
