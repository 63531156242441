import i18next from 'i18next';
import validatorRules from './validator_rules';

const validate = (values) => {
  const errors = {};
  const { minLength, maxLength } = validatorRules.password;

  const validateMinMax = value => value.length < minLength || value.length > maxLength;

  if (!values.new_password) {
    errors.new_password = i18next.t('errors:required');
  } else if (!validatorRules.password.pattern.test(values.new_password)) {
    errors.new_password = i18next.t('errors:new_password');
  } else if (validateMinMax(values.new_password)) {
    errors.new_password = i18next.t('errors:INVALID_LENGTH');
  }

  if (!values.confirm_new_password) {
    errors.confirm_new_password = i18next.t('errors:required');
  } else if (values.new_password !== values.confirm_new_password) {
    errors.confirm_new_password = i18next.t('errors:password_mismatched');
  } else if (validateMinMax(values.confirm_new_password)) {
    errors.confirm_new_password = i18next.t('errors:INVALID_LENGTH');
  }

  return errors;
};

export default validate;
