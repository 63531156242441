import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, isUserLoggedIn } from '../Helpers';
import Image from '../Layout/Image';

const Video = ({ source }) => (
  <div className="carousel-video">
    <video muted loop autoPlay playsInline>
      <source src={source} type="video/mp4" />
    </video>
  </div>
);

Video.propTypes = {
  source: PropTypes.string.isRequired,
};

const CarouselCell = ({
  videoSource,
  imageSource,
  mobileVideoSource,
  mobileImageSource,
  buttonLabel,
  buttonColor,
  buttonBackgroundColor,
  caption,
  captionColor,
  title,
  titleColor,
  subtitle,
  subtitleColor,
  onClickAction,
  showContent,
  vip,
}) => {
  let video = null;
  let containerStyle = {
    backgroundImage: 'url(/images/bg-carousel-default.jpg)',
  };

  if (isMobile().any() && mobileImageSource) {
    containerStyle = {
      backgroundImage: `url(${mobileImageSource})`,
    };
  } else if (isMobile().any() && mobileVideoSource) {
    video = <Video source={mobileVideoSource} />;
  } else if (!isMobile().any() && videoSource) {
    video = <Video source={videoSource} />;
  } else if (!isMobile().any() && imageSource) {
    containerStyle = {
      backgroundImage: `url(${imageSource})`,
    };
  }

  if (video) {
    containerStyle = {
      backgroundImage: 'none',
    };
  }

  return (
    <div className="carousel-cell">
      <div className="carousel-bg-image" style={containerStyle}>
        {video}
        {imageSource !== null
          && <Image customClass="is-hidden-touch" src={`${imageSource}`} alt="" width="100%" height="500px" />}
      </div>

      {showContent
        && (
          <div className="container is-fluid">
            <div className="hero">
              <div className="hero-body has-text-centered has-text-left-widescreen content">
                <div className="hero-title-wrapper has-text-centered">
                  <h2
                    className="hero-title"
                    ref={
                      (el) => {
                        if (el) {
                          el.style.setProperty('color', titleColor, 'important');
                        }
                      }
                    }
                  >
                    {title}
                  </h2>
                  <br />
                  <h2
                    className="hero-sub-title"
                    ref={
                      (el) => {
                        if (el) {
                          el.style.setProperty('color', subtitleColor, 'important');
                        }
                      }
                    }
                  >
                    {subtitle
                      && (
                        <>
                          {subtitle}
                        </>
                      )}
                  </h2>
                </div>
                <p
                  className="is-size-4 has-text-weight-bold has-text-centered"
                  ref={
                    (el) => {
                      if (el) {
                        el.style.setProperty('color', captionColor, 'important');
                      }
                    }
                  }
                >
                  {caption}
                </p>
                <div className="m-button-holder">
                  {(buttonLabel.length > 0) && (
                    <button
                      type="button"
                      className={`button is-dark-green is-size-4 ${isUserLoggedIn() && vip ? 'vip' : ''}`}
                      ref={
                        (el) => {
                          if (el) {
                            el.style.setProperty('color', buttonColor, 'important');
                            el.style.setProperty('background-color', buttonBackgroundColor, 'important');
                            el.style.setProperty('border-color', buttonBackgroundColor, 'important');
                          }
                        }
                      }
                      data-modal-target="modal-signup"
                      onClick={onClickAction}
                    >
                      {buttonLabel}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

CarouselCell.propTypes = {
  videoSource: PropTypes.string,
  imageSource: PropTypes.string,
  mobileVideoSource: PropTypes.string,
  mobileImageSource: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  captionColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
  vip: PropTypes.bool,
};

CarouselCell.defaultProps = {
  videoSource: null,
  imageSource: null,
  mobileVideoSource: null,
  mobileImageSource: null,
  vip: null,
};

export default CarouselCell;
