import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../../Helpers';
import BasePage from '../Base';

export const OfferItem = ({ title, description, more }) => (
  <div className="m-offer-item column is-12-touch is-6-desktop">
    <div className="m-offer-item-content content">
      <h3 className="m-offer-item-title is-size-3 is-size-1-widescreen">
        <b dangerouslySetInnerHTML={{ __html: title }} />
      </h3>
      <p className="is-size-2-widescreen is-size-3 m-offer-description">
        <b dangerouslySetInnerHTML={{ __html: description }} />
      </p>
      <p className="is-size-4-widescreen is-size-5 is-size-3-widescreen">
        <b dangerouslySetInnerHTML={{ __html: more }} />
      </p>
    </div>
  </div>
);

OfferItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  more: PropTypes.string,
};

OfferItem.defaultProps = {
  description: '',
  more: '',
};

const AffiliateLandingPage = ({ data }) => (
  <BasePage data={data}>
    <div className="m-landing-page-container" style={{ backgroundImage: `url( ${data.background_image?.meta.download_url} )` }}>
      <div className="container">
        <h2 className="m-landing-page-title has-text-white" dangerouslySetInnerHTML={{ __html: data.offer_name }} />
        <div className="m-landing-page-items">
          <img className="m-landing-page-plus is-hidden-touch" src="/images/landing_pages/plus-symbol.png" alt="plus symbol" />
          <div className="columns is-mobile is-centered is-multiline">
            {data.offer.map(item => (
              <OfferItem key={item.id} {...item.value} />
            ))}
          </div>
        </div>
        {data.button_target && (
          <div className="buttons">
            <NavLink
              className="button is-centered is-large is-dark-green"
              to={getPathname(data.button_target.html_url)}
              dangerouslySetInnerHTML={{ __html: data.button_text }}
            />
          </div>
        )}
        <p className="has-text-white has-text-left m-landing-page-terms" dangerouslySetInnerHTML={{ __html: data.body }} />
      </div>
    </div>
  </BasePage>
);

AffiliateLandingPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AffiliateLandingPage;
