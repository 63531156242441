import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../Helpers';

const WidgetLink = ({ href, text }) => (
  <NavLink to={getPathname(href)}>
    <h5 className="has-text-primary has-text-underline has-text-right">
      {text}
    </h5>
  </NavLink>
);


WidgetLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default WidgetLink;
