export const TOGGLE_MODAL_IN = 'TOGGLE_MODAL_IN';
export const TOGGLE_MODAL_IN_TIME_OUT = 'TOGGLE_MODAL_IN_TIME_OUT';

export function toggleModalIn(errorMessage, modalType, sourcePage, providerType, errorStatus = null) {
  return {
    type: TOGGLE_MODAL_IN,
    errorMessage,
    modalType,
    sourcePage,
    providerType,
    errorStatus,
  };
}

export function toggleModalInTimeOut() {
  return {
    type: TOGGLE_MODAL_IN_TIME_OUT,
  };
}
