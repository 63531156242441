/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GameSearchInput from '../GameSearchInput';


const GameSearch = ({ title, onTrigger }) => {
  const search = new URLSearchParams(window.location.search)?.get('search');
  const [isActive, setIsActive] = useState(!!search);
  const menuRef = useRef();

  const triggerActive = (active) => {
    setIsActive(active);
    onTrigger(active);
  };

  return (
    <div className="m-game-nav-search" ref={menuRef}>
      <button type="button" onClick={() => triggerActive(!isActive)} className={`button m-game-nav-search-button is-hidden-desktop icon is-medium has-background-dark is-flex ${isActive ? ' is-active' : ''}`} data-menu-target="mobileSearch">
        <i className="fas fa-search has-text-white" />
      </button>
      <div className={`m-game-nav-search-field ${isActive ? ' is-active' : ''}`} id="mobileSearch">
        <label htmlFor="game_search" id="game-search-label" className="is-sr-only">
          {title}
        </label>
        <form autoComplete="off" className="control has-icons-left is-flex">
          <GameSearchInput />
          <span className="icon is-left"><i className="fas fa-search has-text-primary" /></span>
        </form>
      </div>
    </div>
  );
};

GameSearch.propTypes = {
  title: PropTypes.string,
  onTrigger: PropTypes.func,
};

GameSearch.defaultProps = {
  title: '',
  onTrigger: () => {},
};

export default GameSearch;
