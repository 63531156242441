import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SignupState from './SignupState';
import FooterMessage from '../FooterMessage';
import RecaptchaText from '../../Layout/Fields/RecaptchaText';

export const SignupPreviousButton = (props) => {
  const { t } = useTranslation('buttons');

  const { onClick, buttonClass } = props;
  return (
    <div className="level-left step-action is-4-tablet is-6-mobile">
      <button
        type="button"
        className={`button is-white is-outlined button-modal-prev is-medium is-small-mobile is-full-width is-size-6-mobile ${buttonClass}`}
        data-nav="previous"
        onClick={onClick}
      >
        <i className="fa fa-arrow-left" />
        &nbsp;&nbsp;
        { t('previous') }
      </button>
    </div>
  );
};

SignupPreviousButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export const SignupNextButton = ({ onClick, buttonClass, isValidRecaptcha }) => {
  const { t } = useTranslation('buttons');

  return (
    <div className="level-right step-action is-6-mobile is-4-tablet">
      <button
        type="submit"
        className={`button is-success button-modal-next is-medium is-small-mobile is-full-width is-size-6-mobile ${buttonClass}`}
        data-nav="next"
        disabled={!isValidRecaptcha}
        onClick={onClick}
      >
        { t('next') }
        &nbsp;&nbsp;
        <i className="fa fa-arrow-right" />
      </button>
    </div>
  );
};

SignupNextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string.isRequired,
  isValidRecaptcha: PropTypes.bool.isRequired,
};

export const SignupCloseButton = (props) => {
  const { t } = useTranslation('buttons');
  const { closeButton } = props;

  return (
    <div className="columns is-mobile is-centered steps-actions-completed">
      <div className="column step-action has-text-centered is-9">
        <button
          type="button"
          className="button button-modal-close has-background-light is-medium is-small-mobile is-full-width is-uppercase"
          onClick={closeButton}
        >
          <i className="icon-close" />
          &nbsp;&nbsp;
          { t('close') }
          &nbsp;&nbsp;
        </button>
      </div>
    </div>
  );
};

SignupCloseButton.propTypes = {
  closeButton: PropTypes.func.isRequired,
};

class SignupFooter extends PureComponent {
  buttonsConfiguration = () => {
    const { signupState } = this.props;
    const isDefault = '';
    const isHidden = 'is-hidden';
    const buttonActive = 'is-success';
    const prevButtonActive = 'is-white';
    const prevButtonDisabled = 'is-white is-disabled';
    const config = {
      cancelButtonClass: isHidden,
      previousButtonClass: isHidden,
      nextButtonClass: isHidden,
    };

    switch (signupState) {
      case SignupState.first:
        config.previousButtonClass = prevButtonDisabled;
        config.nextButtonClass = buttonActive;
        break;
      case SignupState.second:
        config.previousButtonClass = prevButtonActive;
        config.nextButtonClass = buttonActive;
        break;
      case SignupState.third:
        config.cancelButtonClass = isDefault;
        break;
      default:
        break;
    }

    return config;
  };

  render() {
    const {
      signupState,
      closeButton,
      previousButton,
      nextButton,
      isValidRecaptcha,
    } = this.props;
    let renderedStep = '';

    if (signupState !== SignupState.fourth && signupState !== SignupState.error) {
      const config = this.buttonsConfiguration();
      renderedStep = (
        <div className="level is-mobile is-centered steps-actions">
          <SignupPreviousButton
            buttonClass={config.previousButtonClass}
            onClick={previousButton}
          />
          <SignupNextButton
            buttonClass={config.nextButtonClass}
            isValidRecaptcha={isValidRecaptcha}
            onClick={nextButton}
          />
        </div>
      );
    } else {
      renderedStep = <SignupCloseButton closeButton={closeButton} />;
    }

    return (
      <>
        <footer className="modal-card-foot">
          {renderedStep}
        </footer>
        <FooterMessage>
          <RecaptchaText />
        </FooterMessage>
      </>
    );
  }
}

SignupFooter.propTypes = {
  signupState: PropTypes.instanceOf(SignupState).isRequired,
  closeButton: PropTypes.func.isRequired,
  previousButton: PropTypes.func.isRequired,
  nextButton: PropTypes.func.isRequired,
  isValidRecaptcha: PropTypes.bool.isRequired,
};

export default SignupFooter;
