import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import { adjustRecurrences } from './promotionHelper';

const PromotionWidgetWrapper = ({
  id, limit, children, type = 'calendar',
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const today = moment().format('YYYY-MM-DD');
      const validTo = moment(today, 'YYYY-MM-DD').add(30, 'days');
      let url = '';
      if (type === 'calendar') {
        url = `pages/articles/?child_of=${id}&fields=body,excerpt,valid_from,valid_to,thumbnail,title,first_published_at&valid_from__gte=${moment().format('YYYY-MM-DD')}&valid_from__lte=${validTo.format('YYYY-MM-DD')}&limit=${limit}&show_in_calendar=true&recurrences=true&order=valid_from`;
      } else {
        url = `pages/articles/?child_of=${id}&fields=body,excerpt,valid_from,valid_to,thumbnail,title,first_published_at&limit=${limit}&show_in_calendar=true&recurrences=false`;
      }
      const result = await new Casino.FetchContent(url).perform();

      let values = result.items();

      if (type === 'calendar') {
        values = adjustRecurrences(result.items()).slice(0, limit);
      }

      setItems(values);
    };

    fetchArticles();
  }, [id, limit, type]);

  return children({
    items,
  });
};

PromotionWidgetWrapper.propTypes = {
  children: PropTypes.func,
  id: PropTypes.number.isRequired,
  limit: PropTypes.node,
  type: PropTypes.string,
};

PromotionWidgetWrapper.defaultProps = {
  children: null,
  limit: 4,
  type: 'calendar',
};

export default PromotionWidgetWrapper;
