import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../../Helpers';

const ArticleSideBanner = ({ image, link }) => {
  const style = { backgroundImage: `url(${image})` };

  let component = (
    <div className="column is-12-mobile is-12-tablet is-4-desktop is-3-widescreen is-hidden-touch">
      <aside
        className="m-promo-tall-banner has-background-gradient-neon content"
        style={style}
      />
    </div>
  );

  if (link) {
    component = (
      <NavLink to={getPathname(link.html_url)} className="column is-12-mobile is-12-tablet is-4-desktop is-3-widescreen is-hidden-touch">
        <aside
          className="m-promo-tall-banner has-background-gradient-neon content"
          style={style}
        />
      </NavLink>
    );
  }

  return component;
};

ArticleSideBanner.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.instanceOf(Object),
};

ArticleSideBanner.defaultProps = {
  link: null,
};

export default ArticleSideBanner;
