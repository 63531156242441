import React from 'react';
import PropTypes from 'prop-types';
import ArticleThumbnailList from '../Article/ArticleThumbnailList';

const LoggedIn = ({
  articles,
  nextMonthArticles,
  t,
}) => (
  <>
    {articles.length > 0
    && (
    <div className="container is-fluid">
      <div className="has-text-centered content section is-marginless">
        <h2 className="has-text-primary">{t('common:vip_calendar_title')}</h2>
      </div>
      <ArticleThumbnailList items={articles} needDay type="vip" />
      {nextMonthArticles !== undefined && nextMonthArticles.length > 0
        && <ArticleThumbnailList items={nextMonthArticles} title={`${t('common:next_month')}:`} needDay type="vip" />}
    </div>
    )}
  </>
);

LoggedIn.propTypes = {
  articles: PropTypes.instanceOf(Array),
  nextMonthArticles: PropTypes.instanceOf(Array),
  t: PropTypes.func.isRequired,
};

LoggedIn.defaultProps = {
  articles: [],
  nextMonthArticles: [],
};

export default LoggedIn;
