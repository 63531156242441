import React from 'react';
import PropTypes from 'prop-types';

const CarouselCellLevels = ({
  item,
}) => (
  <div className="m-loyalty-status-carousel-item">
    <div className="card-content content is-flex">
      <div className="level has-text-centered-mobile">
        <div className="level-left">
          <p className="m-loyalty-status has-text-grey is-size-5 is-size-6-mobile">
            <img width="50" src={`${item.icon.url}`} alt="Level one" />
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </p>
        </div>
        <div className="level-right">
          <h3 className="has-text-grey is-marginless is-size-6-touch is-size-3">{ item.level }</h3>
        </div>
      </div>
    </div>
    <div className="card-content">
      <div className="m-loyalty-status-carousel-image">
        <div className="has-text-centered has-text-light-grey">
          <img
            src={`${item.image.url}`}
            alt={item.title}
          />
          <p>
            {item.subtitle}
            <br />
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: item.description }} />
          </p>
        </div>
      </div>
    </div>
  </div>
);

CarouselCellLevels.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    image: PropTypes.instanceOf(Object),
    icon: PropTypes.instanceOf(Object),
  }).isRequired,
};

export default CarouselCellLevels;
