/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const defaultPropertyContent = [
  {
    property: 'fb:app_id',
    content: '1905689679469734',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:description',
    content: 'Join Fruits4Real, the online casino with more than 1000 slots ➤ Play Fruit Machines, Videoslots, Jackpot Slots, Blackjack, Roulette & Live Table Games!',
  },
  {
    property: 'og:site_name',
    content: 'Fruits4Real',
  },
  {
    property: 'og:image',
    content: `${process.env.PUBLIC_URL}/images/favicons/Fruits4Real.png`,
  },
];

const overrideDescription = (content, seoMetaDescription) => (
  seoMetaDescription !== '' && content.name === 'description'
    ? (<meta name={content.name} content={seoMetaDescription} />)
    : (<meta name={content.name} content={content.content} />));

const removeDuplciation = data => data.filter(
  (v, i, a) => a.findIndex(t => (t.property === v.property)) === i,
);

const MetaData = ({ seoMeta, seoMetaDescription }) => {
  // eslint-disable-next-line camelcase
  const { meta_data } = seoMeta;

  const nameContent = meta_data.name_content ? meta_data.name_content.map(
    content => overrideDescription(content, seoMetaDescription),
  ) : [];

  const hasDescription = meta_data.name_content ? meta_data.name_content.some(meta => meta.name === 'description') : false;

  if (!hasDescription && seoMetaDescription !== '') {
    nameContent.push(<meta name="description" content={seoMetaDescription} />);
  }

  let propertyContent = meta_data.property_content
    ? [...meta_data.property_content, ...defaultPropertyContent] : [...defaultPropertyContent];

  propertyContent = removeDuplciation(propertyContent);

  propertyContent = propertyContent.map(
    content => (<meta property={content.property} content={content.content} />),
  );

  const hasOgTitle = meta_data.property_content ? meta_data.property_content.some(meta => meta.property === 'og:title') : false;
  if (!hasOgTitle) propertyContent.push(<meta property="og:title" content={seoMeta.title} />);

  const hasOgDescByUser = meta_data.property_content ? meta_data.property_content.some(meta => meta.property === 'og:description') : false;

  if (!hasOgDescByUser) {
    const regularDesc = nameContent.find(meta => meta.props.name === 'description');
    if (regularDesc) {
      const currentOgDescIndex = propertyContent.findIndex(meta => meta.props.property === 'og:description');

      propertyContent[currentOgDescIndex] = <meta property="og:description" content={regularDesc.props.content} />;
    }
  }

  const httpEquivContent = meta_data.http_equiv_content ? meta_data.http_equiv_content.map(
    content => (<meta httpEquiv={content.http_equiv} content={content.content} />),
  ) : '';

  const metaLinks = meta_data.link ? meta_data.link.map((link) => {
    const attributes = {
      rel: link.rel,
      href: link.href,
    };

    if (link.sizes !== '') attributes.sizes = link.sizes;
    if (link.type !== '') attributes.type = link.type;
    return <link {...attributes} />;
  }) : '';

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>{seoMeta.title}</title>
        {nameContent}
        {propertyContent}
        {httpEquivContent}
        {metaLinks}
      </Helmet>
    </>
  );
};

MetaData.propTypes = {
  seoMeta: PropTypes.instanceOf(Object),
  seoMetaDescription: PropTypes.string,
};

MetaData.defaultProps = {
  seoMeta: {},
  seoMetaDescription: '',
};

export default MetaData;
