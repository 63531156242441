import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BalanceField } from './HeroBalance';
import AccountBalanceWrapper from '../Account/AccountBalanceWrapper';
import { callCashierModal } from '../../Helpers';

export const HeroBalanceBreakDownBody = ({ balance, withdrawableBalance, bonusBalance }) => {
  function callModal(e) {
    callCashierModal(e);
  }
  const { t } = useTranslation();

  return (
    <div className="card m-user-dashboard-card m-user-dashboard-breakdown content">
      <div className="card-title">
        <h4 className="has-text-white is-size-6-mobile has-text-centered">
          {t('account:my_account')}
        </h4>
      </div>
      <div className="card-content pt-0 has-text-white content">
        <BalanceField text={t('account:your_balance')} balance={balance} />
        <BalanceField text={t('account:withdrawable_balance')} balance={withdrawableBalance} />
        <BalanceField text={t('account:bonus_balance')} balance={bonusBalance} />
        <div className="buttons">
          <a
            href="/#"
            id="withdraw"
            className="button is-large is-secondary-bg is-size-5-mobile is-size-6 is-uppercase is-full-width"
            onClick={e => callModal(e)}
          >
            {t('buttons:deposit')}
          </a>
        </div>
        <div className="card-image">
          <img src={`${process.env.PUBLIC_URL}/images/secure-transactions-white.svg`} alt="Secure Transactions" />
        </div>
      </div>
    </div>
  );
};

HeroBalanceBreakDownBody.propTypes = {
  balance: PropTypes.string,
  withdrawableBalance: PropTypes.string,
  bonusBalance: PropTypes.string,
};

HeroBalanceBreakDownBody.defaultProps = {
  balance: '00.00',
  withdrawableBalance: '00.00',
  bonusBalance: '00.00',
};

const HeroBalanceBreakdown = () => (
  <AccountBalanceWrapper>
    {props => (
      <HeroBalanceBreakDownBody {...props} />
    )}
  </AccountBalanceWrapper>
);

export default HeroBalanceBreakdown;
