import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { handleClickOutside } from '../../Helpers';
import useGames from '../../../hooks/games/useGames';
import GameProviderModal from './GameProviderModal';
import useActiveGames from '../../../hooks/games/useActiveGames';

const GameProviderMenu = ({ title }) => {
  const [isActive, setIsActive] = useState(false);
  const clearIsActive = () => setIsActive(false);
  const { providers, gamesByProviders } = useGames();
  const { activeProvider, activeStudio } = useActiveGames();
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    handleClickOutside(menuRef, () => clearIsActive());
  }, [menuRef]);

  const text = gamesByProviders[activeProvider?.id || activeStudio?.id]?.display_name || gamesByProviders[activeProvider?.id || activeStudio?.id]?.name || title;

  return (
    <div className="navbar" ref={menuRef}>
      <div className="navbar-item is-paddingless m-game-nav-secondary is-mobile">
        <button
          ref={menuButtonRef}
          type="button"
          onClick={() => setIsActive(!isActive)}
          className={`m-game-nav-button is-flex ${
            isActive ? ' is-active' : ''
          }`}
          aria-label="game-menu-button"
          aria-expanded="false"
          data-menu-target="navbarProviderNav"
        >
          <div
            className="m-game-nav-menu-label is-size-7-mobile"
            aria-label="game-menu-label"
          >
            {text}
          </div>
          <button
            aria-haspopup="true"
            type="button"
            className={`navbar-burger burger is-static is-flex ${
              isActive ? ' is-active' : ''
            }`}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </button>
        <div
          className={`navbar-menu navbar-dropdown m-game-nav-menu is-size-6 ${
            isActive ? ' is-active' : ''
          }`}
          id="navbarProviderNav"
        >
          <GameProviderModal
            title={title}
            menuItems={providers.items}
            menuButton={menuButtonRef}
            menu={menuRef}
          />
        </div>
      </div>
    </div>
  );
};

GameProviderMenu.propTypes = {
  title: PropTypes.string,
};

GameProviderMenu.defaultProps = {
  title: '',
};

export default GameProviderMenu;
