import React, { PureComponent, createRef } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

class BonusSlider extends PureComponent {
  MAX_SPINS = 300;
  MIN_SPINS = 0;

  constructor() {
    super();
    this.state = {
      bonuses: [],
      freeSpins: [],
      selectedBonus: 0,
    };
    this.bonusSliderContainer = createRef();
    this.freeSpinsContainer = createRef();
    this.bonusContainer = createRef();
    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
  }

  componentDidMount() {
    if (this.cookieValues !== undefined) {
      this.initBonuses();
    }
  }

  initBonuses = () => {
    const { onBonusChange, availableBonuses } = this.props;
    let bonuses = availableBonuses;

    bonuses = bonuses.sort((a, b) => (a.bonusAmount > b.bonusAmount ? 1 : -1));
    const selectedBonus = parseInt((bonuses.length) / 2, 10);
    const step = (this.MAX_SPINS - this.MIN_SPINS) / (bonuses.length - 1);
    const freeSpins = Array.from(
      { length: bonuses.length }, (_, i) => parseInt(this.MAX_SPINS - (step * i), 10),
    );
    this.setState({ bonuses, selectedBonus, freeSpins });
    onBonusChange(bonuses[selectedBonus].bonusPlanId);
  };

  handleRange = (e) => {
    const { onBonusChange } = this.props;
    const { bonuses } = this.state;

    const rangeValue = e.target.value;
    const selectedBonus = parseInt(rangeValue, 10);
    if (bonuses.length >= selectedBonus) {
      this.setState({ selectedBonus });
      onBonusChange(bonuses[selectedBonus].bonusPlanId);
    }

    if (selectedBonus === 0) { this.freeSpinsContainer.current.classList.add('pow'); }
    if (selectedBonus === 1) { this.freeSpinsContainer.current.classList.remove('pow'); }
    if (selectedBonus === bonuses.length - 2) { this.bonusContainer.current.classList.remove('pow'); }
    if (selectedBonus === bonuses.length - 1) { this.bonusContainer.current.classList.add('pow'); }
  }

  handleOptOut = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    this.setState({
      bonuses: [],
    });
    onClose();
    return true;
  }

  render() {
    const {
      t,
      i18n,
      disableSlider,
      bonusPage,
      currentBonus,
    } = this.props;

    const { freeSpins, bonuses, selectedBonus } = this.state;

    return (
      <div
        ref={this.bonusSliderContainer}
        id="bonusSliderContainer"
        className={`m-bonus-slider has-text-centered has-text-dark ${(bonuses.length > 0) ? 'is-active' : 'is-inactive'}`}
        style={{
          background: `black url(${ bonusPage?.banner?.meta?.download_url || bonusPage?.header_image?.meta?.download_url}) center right no-repeat`,
        }}
      >
        {!disableSlider && (
          <div>
            <h2 className="m-bonus-slider__title">{t('account:bonus_slider_title')}</h2>
            <p>{t('account:bonus_slider_subtitle')}</p>
          </div>
        )}
        {disableSlider && (
          <div className="columns is-mobile is-vcentered m-bonus-slider__container is-centered">
            <div className="column is-8-tablet">
              <p className="has-text-weight-bold">
                {t('account:bonus_slider_warning_message_1')}
                <br />
                {t('account:bonus_slider_warning_message_2')}
                <br />
                <br />
                {t('account:bonus_slider_warning_message_3')}
              </p>
            </div>
          </div>
        )}
        {!disableSlider && (
          <div>
            <div className="level">
              <div className="level-left" style={{ justifyContent: 'center' }}>
                <p className="has-text-weight-light">
                  <a
                    href={`/${i18n.language}/promotions/${currentBonus?.description?.slug}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('account:bonus_slider_bonus_terms')}
                  </a>
                </p>
              </div>
              <div className="level-right" style={{ justifyContent: 'center' }}>
                <a href="/#" id="delete" onClick={this.handleOptOut}>
                  <u>{t('account:continue_without_bonus')}</u>
                </a>
              </div>
            </div>
            <div className="columns is-mobile is-vcentered m-bonus-slider__container">
              <div ref={this.freeSpinsContainer} id="freeSpinsContainer" className="m-bonus-slider__element column is-2-mobile is-2-tablet">
                <div id="inputRangeFirstValue" className="m-bonus-slider__value">
                  {(freeSpins.length > 0) && freeSpins[selectedBonus]}
                </div>
                <p className="m-bonus-slider__label">{t('account:bonus_slider_free_spins')}</p>
              </div>
              <div className="m-bonus-slider__bar column is-8-tablet ">
                <input onInput={this.handleRange} id="bonusSlider" value={selectedBonus} step="1" type="range" min="0" max={bonuses.length - 1} />
              </div>
              <div ref={this.bonusContainer} id="bonusContainer" className="m-bonus-slider__element column is-2-mobile is-2-tablet">
                <div id="inputRangeSecondValue" className="m-bonus-slider__value">
                  {(bonuses.length > 0) && bonuses[selectedBonus].bonusAmount}
                  %
                </div>
                <p className="m-bonus-slider__label">{t('account:bonus_slider_bonus')}</p>
              </div>
            </div>
            <div className="columns is-mobile is-vcentered m-bonus-slider__container">
              <div className="level-right m-bonus-slider__game-name">
                <small>Book of Cleopatra <br></br> Super Stake Edition</small>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BonusSlider;

BonusSlider.propTypes = {
  availableBonuses: PropTypes.instanceOf(Array).isRequired,
  currentBonus: PropTypes.instanceOf(Object).isRequired,
  bonusPage: PropTypes.instanceOf(Object).isRequired,
  onBonusChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disableSlider: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};
