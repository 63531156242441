import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import {
  stripHtml,
  truncate,
  getPathname,
  getLocaleFirstPart,
} from '../../Helpers';
import Image from '../../Layout/Image';

export const PlaceholderThumbnail = () => (
  <div className="column is-12-mobile is-6-tablet is-3-desktop">
    <figure className="m-grid-item" />
  </div>
);

const ArticleThumbnail = ({ data, needDay, type }) => {

  const thumbnail = (data.thumbnail) ? data.thumbnail.meta.download_url : 'https://placehold.it/900x313';
  const excerpt = data.excerpt ? truncate(stripHtml(data.excerpt), 60) : truncate(stripHtml(data.body), 50);

  return (
    <NavLink to={getPathname(data.meta.html_url)} className="column is-12-mobile is-6-tablet is-3-desktop">
      <figure className={`m-grid-item ${needDay && ('m-grid-item-promotions')}`}>
        {needDay
        && (
        <span className="m-grid-item-calendar-day">
          <span>{moment(data.valid_from).locale(getLocaleFirstPart()).format('Do')}</span>
        </span>
        )}
        <Image src={thumbnail} width="392" />
        {!needDay
        && (
          <div className="m-grid-item-caption-overlay" style={{ backgroundImage: `url( ${thumbnail} )` }} />
        )}
        <figcaption className="m-grid-item-caption is-size-6-desktop is-size-5-fullhd">
          { excerpt }
        </figcaption>
      </figure>
      {type === 'vip'
      && (
        <span className="m-grid-item-icon">
          <i className="icon-VIP" />
        </span>
      )}
    </NavLink>
  );
};

ArticleThumbnail.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  needDay: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

ArticleThumbnail.defaultProps = {
  needDay: false,
};

export default ArticleThumbnail;
