import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import store from '../../../containers/App/store';
import { modalMap, openModal } from '../../Modal/ModalManager';

const ModalPage = ({ data }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { search } = useLocation();

  history.push({
    pathname: `/${i18n.language}/`,
    state: { from: data.modal_type },
    search,
  });

  const modalState = modalMap[data.modal_type]?.reducer;
  if (modalState && !store.getState()[modalState].isOpen) openModal(data.modal_type);

  return null;
};

ModalPage.propTypes = {
  data: PropTypes.shape({
    modal_type: PropTypes.string.isRequired,
  }),
};

export default ModalPage;
