import omegaApi from '../../omegaApi';

class FavoriteGames {
  getFavoriteGames = async (sessionKey) => {
    try {
      const result = await omegaApi.client.get(
        `getFavoriteGames?sessionKey=${sessionKey}&version=2`,
      );
      return result;
    } catch (error) {
      return { error: error.message, status: error.request?.status };
    }
  };

  addFavoriteGame = async (gameId, platformCode, sessionKey) => {
    try {
      const result = await omegaApi.client.get(
        `addFavoriteGame?sessionKey=${sessionKey}&platformCode=${platformCode}&gameId=${gameId}`,
      );
      return result;
    } catch (error) {
      return { error: error.message, status: error.request?.status };
    }
  };

  removeFavoriteGame = async (gameId, platformCode, sessionKey) => {
    try {
      const result = await omegaApi.client.get(
        `removeFavoriteGame?sessionKey=${sessionKey}&platformCode=${platformCode}&gameId=${gameId}`,
      );
      return result;
    } catch (error) {
      return { error: error.message, status: error.request?.status };
    }
  };
}

export default FavoriteGames;
