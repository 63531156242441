import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { setGamesByCategories, setSearchedGames } from '../../redux/actions/gamesActions';
import Games from '../../lib/api/cms/gamesService/Games';

const gamesApi = new Games();

function useGamesActions() {
  const dispatch = useDispatch();

  const setFeaturedGames = useCallback((featuredGames) => {
    if (featuredGames?.id) {
      dispatch(setGamesByCategories([featuredGames], isMobile));
    }
  }, [dispatch]);

  const search = useCallback((searchText, categoryName, providerId, providerType) => {
    if (searchText?.length >= 3) {
      (async () => {
        const searchResult = await gamesApi.search(
          searchText,
          categoryName,
          providerId,
          isMobile,
          null,
          providerType,
        );
        dispatch(setSearchedGames(
          searchResult?.data?.items,
          searchResult?.data?.meta?.links?.next,
          categoryName,
          providerId,
          isMobile,
        ));
      })();
    }
  }, [dispatch]);

  return {
    setFeaturedGames,
    search,
  };
}

export default useGamesActions;
