import i18next from 'i18next';

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = i18next.t('errors:required');
  }

  return errors;
};

export default validate;
