import {
  TOGGLE_MODAL_CASHIER,
} from '../actions/cashierModalActions';

const initialState = {
  isOpen: false,
  cashierType: 'deposit',
  providerType: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_CASHIER:
      return {
        ...state,
        isOpen: !state.isOpen,
        cashierType: action.cashierType,
        providerType: action.providerType,
      };
    default:
      return state;
  }
}
