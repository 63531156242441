import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { init as SentryInit } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import store from './containers/App/store';
import App from './containers/App';
import ErrorBoundary from './components/ErrorBoundary';

/* istanbul ignore next */
if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<ErrorBoundary><Provider store={store}><App /></Provider></ErrorBoundary>, document.getElementById('root'));
