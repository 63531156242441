import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LabelGenerator extends PureComponent {
  render() {
    const { props } = this;
    const column = props.values || '';

    return (
      <>
        <th className="table-label"><strong>{column}</strong></th>
      </>
    );
  }
}

LabelGenerator.propTypes = {
  values: PropTypes.string.isRequired,
};

export default LabelGenerator;
