import React from 'react';
import PropTypes from 'prop-types';

const padding = value => (value < 10 ? `0${value}` : value);

const DateSelect = ({
  value,
  id,
  index,
  onChange,
  title,
  items,
}) => {
  let selected = false;

  const options = items.map((item, i) => {
    if (padding(item).toString() === value && !selected) selected = true;
    return <option selected={padding(item).toString() === value && selected} key={`${i}`} value={padding(item)}>{padding(item)}</option>;
  });

  return (
    <p className="control">
      <span className="select">
        <select name={title} id={id} data-index={index} className="is-borderless ref-select" onChange={onChange}>
          <option disabled selected={!selected}>{ title }</option>
          {options}
        </select>
      </span>
    </p>
  );
};

DateSelect.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
};

DateSelect.defaultProps = {
  value: '',
};

export default DateSelect;
