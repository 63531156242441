/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { useTranslation } from 'react-i18next';
import AccountHeaderSection from './HeaderSection';
import AccountBalance from './AccountBalance';
import BodySection from './BodySection';
import SubPageIcons from '../SubPageIcons';
import BasePage from '../Base';

const LandingPage = ({ data }) => {
  const [subPages, setSubPages] = useState([]);
  const { t } = useTranslation('common');

  const fetchContent = async () => {
    const content = await new Casino.FetchSubPagesInfo(data.id).perform();
    setSubPages(content.items());
  };

  useEffect(() => {
    fetchContent();
  }, []);

  return (
    <BasePage data={data}>
      <AccountHeaderSection data={data} subPages={subPages} />
      <BodySection title={t('your_account')}>
        <div className="columns">
          <AccountBalance />
        </div>
        <SubPageIcons subPages={subPages} />
      </BodySection>
    </BasePage>
  );
};

LandingPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default LandingPage;
