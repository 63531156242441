import {
  TOGGLE_MODAL_RESET,
} from '../actions/passwordResetActions';

const initialState = {
  isOpen: false,
  queryParams: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_RESET:
      return {
        ...state,
        isOpen: !state.isOpen,
        queryParams: action.queryParams,
      };

    default:
      return state;
  }
}
