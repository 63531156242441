import React from 'react';
import PropTypes from 'prop-types';

const WidgetThumbnail = ({ thumbnail, alt }) => {
  const bg = (thumbnail && thumbnail.meta !== undefined) ? thumbnail.meta.download_url : 'https://placehold.it/88x72';
  return (
    <div className="level-left">
      <div className="m-widget-thumbnail-holder" style={{ backgroundImage: `url( ${bg} )` }} title={alt} />
    </div>
  );
};


WidgetThumbnail.propTypes = {
  thumbnail: PropTypes.instanceOf(Object),
  alt: PropTypes.string.isRequired,
};

WidgetThumbnail.defaultProps = {
  thumbnail: [{
    meta: {
      download_url: '',
    },
  }],
};

export default WidgetThumbnail;
