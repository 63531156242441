import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ title, show }) => (
  <>
    {show && (
      <h1 className="has-text-primary">
        {title}
      </h1>
    )}
  </>
);

PageTitle.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: '',
  show: true,
};

export default PageTitle;
