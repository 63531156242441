import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PageTypeContext from './PageTypeContext';

class PageTypeContextProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageType: null,
      prevPageType: null,
      layout: {
        topMenu: true,
        footer: true,
      },
    };
  }

  setPageType = (pageType) => {
    const { props, state } = this;

    this.setState(prevState => ({
      ...prevState,
      prevPageType: prevState.pageType,
      pageType,
    }));

    if (props.onPageTypeChange) props.onPageTypeChange(pageType, state.prevPageType);
  };

  setLayoutItemVisibility = (config) => {
    const { state } = this;

    const newConfig = {
      ...state.layout,
      ...config,
    };

    if (JSON.stringify(newConfig) === JSON.stringify(state.layout)) return;

    this.setState(prevState => ({
      ...prevState,
      layout: {
        ...prevState.layout,
        ...config,
      },
    }));
  };

  render() {
    const { children } = this.props;
    const { state } = this;

    return (
      <PageTypeContext.Provider
        value={
          {
            pageType: state.pageType,
            layout: state.layout,
            prevPageType: state.prevPageType,
            setPageType: this.setPageType,
            setLayoutItemVisibility: this.setLayoutItemVisibility,
          }
        }
      >
        {children}
      </PageTypeContext.Provider>
    );
  }
}

PageTypeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onPageTypeChange: PropTypes.func,
};

PageTypeContextProvider.defaultProps = {
  onPageTypeChange: null,
};

export default PageTypeContextProvider;
