import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import LabelGenerator from './LabelGenerator';
import TableGenerator from './TableGenerator';
import { paginationLoader } from '../Helpers';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allElements: [],
      currentElements: [],
      columnLabels: [],
      columnValues: [],
      columnLinks: [],
      totalElements: 0,
      totalRecordCount: 0,
      perPage: 1,
      pageNum: null,
      currency: null,
    };
  }

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;
    this.initState();
  }

  initState = () => {
    const {
      columns, perPage, arrayFilter, values, links, totalRecordCount, pageNum, gridType, currency,
    } = this.props;
    let currentElements;

    const allElements = this.arrayFilter(
      arrayFilter.data,
      arrayFilter.operator,
      arrayFilter.column,
      arrayFilter.value,
    );

    if (gridType === 'modal') {
      currentElements = allElements;
    } else {
      currentElements = allElements.slice(0, perPage);
    }

    const totalElements = allElements.length;

    this.setState({
      allElements,
      columnLabels: columns,
      perPage,
      columnValues: values,
      columnLinks: links,
      totalElements,
      totalRecordCount,
      currentElements,
      pageNum,
      currency,
    });
  };

  onPageChanged = (data) => {
    const {
      allElements, columnLabels, columnValues, columnLinks, totalRecordCount, currency,
    } = this.state;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;

    this.setState({
      currentElements: (totalRecordCount === 0 || totalRecordCount === undefined)
        ? allElements.slice(offset, offset + pageLimit)
        : allElements,
      columnLabels,
      columnValues,
      columnLinks,
      currency,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  arrayFilter(data, operator, column, value) {
    switch (operator) {
      case '===':
        return data.filter(
          items => value.indexOf(items[column]) > -1,
        );
      case '!==':
        return data.filter(
          items => value.indexOf(items[column]) < 0,
        );
      case 'contains':
        return data.filter(items => value.includes(items[column]));
      default:
        return data;
    }
  }

  render() {
    const {
      totalElements,
      currentElements,
      columnLabels,
      columnValues,
      columnLinks,
      perPage,
      totalRecordCount,
      pageNum,
      currency,
    } = this.state;
    const { emptyMessage, onChange, pLoading } = this.props;

    if (totalElements === 0) {
      return (
        <>
          { paginationLoader(pLoading) }
          { !pLoading && <p>{emptyMessage}</p> }
        </>
      );
    }
    let columnCounter = 0;
    let elementCounter = 0;
    return (
      <>
        { paginationLoader(pLoading) }
        <table className={`table is-full-width is-striped is-responsive ${pLoading ? 'is-hidden' : ''}`}>
          <tbody>
            <tr>
              {columnLabels.map((item) => {
                columnCounter += 1;
                return (
                  <LabelGenerator key={columnCounter} values={item} />
                );
              })}
            </tr>
            {totalElements !== 0 && currentElements.map((item) => {
              elementCounter += 1;
              return (
                <TableGenerator
                  key={elementCounter}
                  values={item}
                  columnValues={columnValues}
                  columnLinks={columnLinks}
                  currency={currency}
                />
              );
            })}
          </tbody>
        </table>
        <Pagination
          currentElements={currentElements}
          totalRecordCount={totalRecordCount}
          totalRecords={totalElements}
          pageLimit={perPage}
          onPageChanged={this.onPageChanged}
          onChange={onChange}
          pageNum={pageNum}
          pLoading={pLoading}
        />
      </>
    );
  }
}

App.propTypes = {
  totalRecordCount: PropTypes.number,
  pageNum: PropTypes.number,
  columns: PropTypes.instanceOf([]).isRequired,
  values: PropTypes.instanceOf([]).isRequired,
  links: PropTypes.instanceOf([]),
  perPage: PropTypes.number.isRequired,
  arrayFilter: PropTypes.instanceOf([]).isRequired,
  onChange: PropTypes.func,
  emptyMessage: PropTypes.string,
  gridType: PropTypes.string,
  currency: PropTypes.string,
  pLoading: PropTypes.bool,
};

App.defaultProps = {
  emptyMessage: null,
  gridType: null,
  pageNum: null,
  currency: null,
  totalRecordCount: 0,
  pLoading: false,
  links: undefined,
  onChange: undefined,
};

export default App;
