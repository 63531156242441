import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  loadMore,
  loadMoreFromCms,
  loadMoreSearchFromCms,
} from '../../../redux/actions/gamesActions';
import useQuery from '../../../hooks/useQuery';

const GameLoadMoreButton = ({
  categoryId,
  displayGamesCount,
  count,
  totalCount,
  maxLimit,
  loadAllGames,
  isProvider,
  nextUrl,
  type,
  slug,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { query } = useQuery();
  const searchText = query.get('search');

  if (!nextUrl && (
    maxLimit || count < displayGamesCount || count === totalCount || searchText
  )) return null;

  const handleClick = () => {
    if (isProvider || type === 'studio') {
      setIsFetching(true);
      dispatch(loadMoreFromCms(categoryId, type, slug));
    } else if (nextUrl) {
      setIsFetching(true);
      dispatch(loadMoreSearchFromCms(searchText));
      setIsFetching(false);
    } else {
      dispatch(loadMore(categoryId));
    }
  };

  return (
    <div className="level m-button-holder">
      <div className="level-item">
        <button
          type="button"
          onClick={handleClick}
          className={`button m-game-grid-button is-size-5 is-outlined is-primary is-centered mt-4 ${
            isFetching ? 'is-loading' : ''
          }`}
        >
          {!loadAllGames ? t('common:more_games') : t('common:all_games')}
        </button>
      </div>
    </div>
  );
};

GameLoadMoreButton.propTypes = {
  categoryId: PropTypes.number.isRequired,
  displayGamesCount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  maxLimit: PropTypes.number,
  totalCount: PropTypes.number,
  loadAllGames: PropTypes.bool.isRequired,
  isProvider: PropTypes.bool.isRequired,
  nextUrl: PropTypes.string,
  type: PropTypes.string,
  slug: PropTypes.string,
};

GameLoadMoreButton.defaultProps = {
  totalCount: null,
  maxLimit: null,
  nextUrl: undefined,
  type: undefined,
  slug: undefined,
};

export default GameLoadMoreButton;
