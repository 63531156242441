import React from 'react';
import PropTypes from 'prop-types';

const ArticleHeader = ({ title, image, video, show }) => {
  const imagePlaceholder = (image) ? (image.meta.download_url) : ('http://placehold.it/900x313/black');
  return (
    <>
      {!video && (
        <img className="m-promo-blog-image" src={imagePlaceholder} alt={title} />
      )}
      {video && (
        <video muted loop autoPlay playsInline>
          <source src={video.url} type="video/mp4" />
        </video>
      )}
      <div className="m-promo-blog-article">
        {show && (
          <h1 className="m-promo-blog-title has-text-primary is-size-2 is-size-3-mobile has-text-centered-touch"><span>{title}</span></h1>
        )}
      </div>
    </>
  );
};

ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.instanceOf(Object),
  video: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  show: PropTypes.bool,
};

ArticleHeader.defaultProps = {
  image: '',
  video: '',
  show: true,
};

export default ArticleHeader;
