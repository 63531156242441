import React from 'react';
import Casino, { context } from '@dialinvest/react-casino';
import { Cookies } from 'react-cookie';

function usePlayer() {
  const cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
  const sessionKey = cookieValues?.sessionKey;
  const player = context.useUserData();

  const updatePlayerExtraInfo = React.useCallback(
    async (data) => {
      if (sessionKey) {
        try {
          const parameters = {
            sessionKey,
            extraInfo: JSON.stringify(data),
          };

          const response = await new Casino.models.Account().updateExtraInfo(parameters);

          return response;
        } catch (err) {
          return err;
        }
      }

      return null;
    }, [sessionKey],
  );

  return { updatePlayerExtraInfo, player };
}

export default usePlayer;
