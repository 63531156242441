import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getUserAllBonuses } from '../../Helpers';

export const BonusWrapper = ({ data, children }) => (
  <div
    className="card m-user-dashboard-card m-user-dashboard-card-bg content is-size-6-touch"
    style={{
      background: `#29303C url(${
        data.bonusPageDetails?.thumbnail?.meta?.download_url
      }) center center no-repeat`,
    }}
  >
    {children}
  </div>
);

BonusWrapper.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
};

export const Bonus = ({ t, i18n, data }) => (
  <BonusWrapper data={data} i18n={i18n}>
    {data.bonusPageDetails?.title === 'Weekend Booster' && (
      <div className="card-head">
        <h2>{data.bonusPageDetails?.title}</h2>
      </div>
    )}
    <div className="card-foot">
      {data?.bonusPageDetails?.english_slug && (
      <Link
        className="has-text-white"
        to={`/${i18n.language}/promotions/${data?.bonusPageDetails?.english_slug}`}
      >
        {data.bonusPageDetails?.meta?.slug && t('common:more')}
      </Link>
)}
    </div>
  </BonusWrapper>
);

Bonus.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export const HeroBonus = () => {
  const { t, i18n } = useTranslation();
  const [bonus, setBonus] = React.useState({});

  useEffect(() => {
    getUserAllBonuses()
      .then(userBonus => setBonus(userBonus));
  }, []);

  return (
    <>
      <Bonus data={bonus} t={t} i18n={i18n} />
    </>
  );
};

export default HeroBonus;
