import {
  TOGGLE_MODAL_GENERAL_ERROR,
} from '../actions/generalErrorModalActions';

const initialState = {
  isOpen: false,
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_GENERAL_ERROR:
      return {
        ...state,
        isOpen: action.isOpen || !state.isOpen,
        message: action.message,
      };
    default:
      return state;
  }
}
