import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {
  getPathname,
  stripHtml,
  truncate,
} from '../../Helpers';
import WidgetThumbnail from '../WidgetThumbnail';

export const HeroPromotionWidgetItem = ({ data }) => {
  const excerpt = data.excerpt ? stripHtml(data.excerpt) : truncate(stripHtml(data.body), 20);

  return (
    <NavLink to={getPathname(data.meta.html_url)} className="level is-mobile m-promo-widget-item">
      <WidgetThumbnail alt={data.title} thumbnail={data.thumbnail} />
      <div className="level-item">
        <div className="m-promo-widget-item-text">
          <h3 className="has-text-white m-promo-widget-title has-text-weight-semibold is-size-6">{data.title}</h3>
          <small className="has-text-left">{excerpt}</small>
        </div>
      </div>
    </NavLink>
  );
};

HeroPromotionWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const ButtonWindowScroller = ({ direction, onTrigger }) => (
  <>
    <button type="button" className={`m-button-arrow-${direction} m-button-arrow`} onClick={onTrigger}>
      <i className={`fas fa-caret-${direction}`} />
    </button>
  </>
);

ButtonWindowScroller.propTypes = {
  direction: PropTypes.string.isRequired,
  onTrigger: PropTypes.instanceOf(Object).isRequired,
};

export class HeroPromotionWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollWindow = createRef();

    this.state = {
      currentSlide: 0,
      autoPlay: true,
    };
  }

  promotions = () => {
    const { items } = this.props;
    let index = 0;
    return items.map((item) => {
      index += 1;
      return <HeroPromotionWidgetItem key={index} data={item} />;
    });
  };

  next = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide + 1,
    }));
  };

  prev = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide - 1,
    }));
  };

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  render() {
    const { t, items } = this.props;
    const { autoPlay, currentSlide } = this.state;

    return (
      <div className="card m-user-dashboard-card m-promo-widget content">
        <div className="card-title">
          <h4 className="has-text-white is-size-6-mobile has-text-centered">{`${t('common:promotions')}`}</h4>
        </div>
        <div className="card-content content">
          <div className="m-promo-widget-scroll-arrows">
            <ButtonWindowScroller direction="up" onTrigger={this.next} />
            <ButtonWindowScroller direction="down" onTrigger={this.prev} />
          </div>
          <div className="m-promo-widget-scroll-window">
            {items.length > 0 && (
              <Carousel
                autoPlay={autoPlay}
                selectedItem={currentSlide}
                onChange={this.updateCurrentSlide}
                infiniteLoop
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                interval={3000}
                showThumbs={false}
                axis="vertical"
                swipeable={false}
                preventMovementUntilSwipeScrollTolerance={false}
                swipeScrollTolerance={0}
                {...this.props}
              >
                {this.promotions()}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    );
  }
}

HeroPromotionWidget.propTypes = {
  items: PropTypes.instanceOf(Array),
  t: PropTypes.func,
};

HeroPromotionWidget.defaultProps = {
  items: [],
  t: () => {},
};

export default compose(
  withTranslation(),
)(HeroPromotionWidget);
