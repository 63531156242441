/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Casino from '@dialinvest/react-casino';
import currencyMark from '../../../containers/utitilies/currencies';
import { apiCall, getSession } from '../../Helpers';

const AccountBalanceWrapper = ({ children, disableGeneralError }) => {
  const [balance, setBalance] = useState('0.00');
  const refresh = useSelector(state => state.balance.refresh);
  const [withdrawableBalance, setWithdrawableBalance] = useState('0.00');
  const [bonusBalance, setBonusBalance] = useState('0.00');
  const account = new Casino.models.Account();

  const fetchContent = async () => {
    const { sessionKey } = getSession();

    const response = (!disableGeneralError)
      ? await apiCall(account.getBalanceSimple(sessionKey), 'getBalanceSimple')
      : await account.getBalanceSimple(sessionKey);

    if (response.success()) {
      setBalance(`${currencyMark[response.data.currency]}${response.data.totalBalance}`);
      setWithdrawableBalance(`${currencyMark[response.data.currency]}${response.data.withdrawableBalance}`);
      setBonusBalance(`${currencyMark[response.data.currency]}${response.data.bonusBalance}`);
    }
  };

  useEffect(() => {
    fetchContent();
    const interval = setInterval(() => { fetchContent(); }, 30000);
    return () => clearInterval(interval);
  }, [refresh]);

  return children({
    balance,
    withdrawableBalance,
    bonusBalance,
  });
};

AccountBalanceWrapper.propTypes = {
  disableGeneralError: PropTypes.bool,
};

AccountBalanceWrapper.defaultProps = {
  disableGeneralError: false,
};

export default AccountBalanceWrapper;
