import React from 'react';
import PropTypes from 'prop-types';

const BaseSwitch = (props) => {
  const {
    onChange,
    title,
    description,
    value,
    children,
  } = props;

  return (
    <div className="column is-12 m-margin-top-gap">
      <div className="field">
        <input onChange={onChange} id={title} value={value} checked={value} type="checkbox" name={title} className="switch is-rounded is-outlined is-success" />
        <label id={title} htmlFor={title}>
          {description}
        </label>
      </div>
      {children}
    </div>
  );
};

BaseSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
};

export default BaseSwitch;
