/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Casino from '@dialinvest/react-casino';
import { getPathname, showAsPopUp } from '../../Helpers';
import BackgroundImage from '../../Layout/BackgroundImage';

const BucketWidgetItem = ({ data }) => {
  const buttonDefault = (
    <button
      type="button"
      className="button is-medium is-wide-desktop is-secondary-bg is-size-6-touch"
      ref={
        (el) => {
          if (el) {
            el.style.setProperty('color', data.button_color, 'important');
            el.style.setProperty('background-color', data.button_background_color, 'important');
            el.style.setProperty('border-color', data.button_background_color, 'important');
          }
        }
      }
    >
      {data.button_label}
    </button>
  );

  const [widgetButton, setWidgetButton] = useState(buttonDefault);

  const onClick = () => {
    showAsPopUp(data.button_external_link);
  };

  const fetchWidgetLink = async (id) => {
    const result = await new Casino.FetchContent(`pages/${id}`).perform();
    const button = (
      <NavLink
        to={result.data.meta ? getPathname(result.data.meta.html_url) : ''}
        className="button is-medium is-wide-desktop is-secondary-bg is-size-6-touch"
        ref={
          (el) => {
            if (el) {
              el.style.setProperty('color', data.button_color, 'important');
              el.style.setProperty('background-color', data.button_background_color, 'important');
              el.style.setProperty('border-color', data.button_background_color, 'important');
            }
          }
        }
      >
        {data.button_label}
      </NavLink>
    );
    setWidgetButton(button);
  };

  const setExternalButton = () => {
    let button;
    if (data.has_pop_up) {
      button = (
        <button
          type="button"
          onClick={onClick}
          className="button is-medium is-wide-desktop is-secondary-bg is-size-6-touch"
          ref={
            (el) => {
              if (el) {
                el.style.setProperty('color', data.button_color, 'important');
                el.style.setProperty('background-color', data.button_background_color, 'important');
                el.style.setProperty('border-color', data.button_background_color, 'important');
              }
            }
          }
        >
          {data.button_label}
        </button>
      );
    } else {
      button = (
        <a
          target="_blank"
          href={data.button_external_link}
          rel="nofollow noopener noreferrer"
          className="button is-medium is-wide-desktop is-secondary-bg is-size-6-touch"
          ref={
            (el) => {
              if (el) {
                el.style.setProperty('color', data.button_color, 'important');
                el.style.setProperty('background-color', data.button_background_color, 'important');
                el.style.setProperty('border-color', data.button_background_color, 'important');
              }
            }
          }
        >
          {data.button_label}
        </a>
      );
    }
    setWidgetButton(button);
  };

  useEffect(() => {
    if (data.button_target) {
      fetchWidgetLink(data.button_target);
    } else if (data.button_external_link) {
      setExternalButton();
    }
  }, [data]);

  return (
    <div className="column is-6 m-support-item">
      <div className="m-support-item-content">
        <BackgroundImage src={data.image.url} elementClass="m-support-item-image">
          <h2
            className="is-size-2 is-size-3-touch has-text-white has-text-left"
            ref={
              (el) => {
                if (el) {
                  el.style.setProperty('color', data.title_color, 'important');
                }
              }
            }
          >
            {data.title}
          </h2>
        </BackgroundImage>
        <div className="m-support-item-text content">
          <p>{data.description}</p>
        </div>
        <div className="m-support-item-button has-text-centered">
          {widgetButton}
        </div>
      </div>
    </div>
  );
};

BucketWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BucketWidgetItem;
