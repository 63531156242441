import Axios from 'axios';

class CMSApi {
  constructor(baseUrl, authToken) {
    this.client = Axios.create({
      baseURL: baseUrl,
      timeout: 50000,
      headers: {
        'x-forwarded-host': process.env.REACT_APP_FRONTEND_URL,
        authorization: authToken,
      },
    });
  }
}

export default CMSApi;
