import React, { PureComponent } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

class SimpleBonusSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bonuses: [],
    };
    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
  }

  componentDidMount() {
    if (this.cookieValues !== undefined) {
      this.initBonuses();
    }
  }

  initBonuses = () => {
    const { availableBonuses } = this.props;
    const bonuses = availableBonuses;

    this.setState({ bonuses });
  };

  handleOptOut = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    this.setState({
      bonuses: [],
    });
    onClose();
    return true;
  };

  render() {
    const {
      t,
      i18n,
      currentBonus,
    } = this.props;

    const { bonuses } = this.state;
    const isoLanguage = i18n.language.split('-')[0];

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        ref={this.bonusSliderContainer}
        id="bonusSliderContainer"
        className={`m-bonus-slider m-bonus-slider-simple has-text-centered has-text-dark 
          ${(bonuses.length > 0) ? 'is-active' : 'is-inactive'}`}
        style={{
          background: 'black url(/images/F4R-pop-window-deposit-1000x245.png) center right no-repeat',
        }}
      >
        {bonuses.length > 0 && (
          <>
            <div>
              <h2 className="m-bonus-slider-simple__title">{ currentBonus?.bonusPlanName }</h2>
              <p>{ currentBonus?.description[isoLanguage]?.subTitle }</p>
            </div>
            <div>
              <p style={{ fontWeight: '100', paddingTop: '10px', paddingBottom: '10px' }}>
                { currentBonus?.description[isoLanguage]?.Description }
              </p>
              <span>
                <p style={{ fontWeight: '700', paddingLeft: '0.5rem' }}>
                  { `Enter Bonus Code: ${currentBonus?.bonusPlanId}` }
                </p>
                <p>{ t('account:minimum_deposit') }</p>
              </span>
              <p className="has-text-weight-light">
                <a
                  href={`/${i18n.language}/promotions/bonus-promotion-rules/`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="bonus-rules"
                >
                  {t('account:bonus_slider_bonus_terms')}
                </a>
              </p>
            </div>
          </>
        )}
        <div className="level-left" style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <p className="has-text-weight-light">
            <a href="/#" id="delete" onClick={this.handleOptOut}>
              <u>{t('account:continue_without_bonus')}</u>
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default SimpleBonusSlider;

SimpleBonusSlider.propTypes = {
  availableBonuses: PropTypes.instanceOf(Array).isRequired,
  currentBonus: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};
