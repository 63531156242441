import React from 'react';
import PropTypes from 'prop-types';
import GameThumbnailList from '../GameThumbnailList';
import useGames from '../../../hooks/games/useGames';

function GamesByCategories({ categories }) {
  const { gamesByCategories } = useGames();

  return (
    <>
      {Object.values(categories)
        .sort((a, b) => a.position - b.position)
        .map(item => (
          <React.Fragment key={item?.id}>
            {!!gamesByCategories[item?.id]?.games?.length && (
              <GameThumbnailList
                key={item.id}
                category={item}
                maxLimit={item.displayGamesCount}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export default GamesByCategories;

GamesByCategories.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
};
