/* eslint-disable no-use-before-define */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGames from '../../../hooks/games/useGames';
import { providerFilter, searchFilter } from './FilteredGames';
import GameThumbnailList from '../GameThumbnailList';
import useActiveGames from '../../../hooks/games/useActiveGames';
import useQuery from '../../../hooks/useQuery';

function FavoriteGamesList() {
  const { favoriteGames } = useGames();
  const { t } = useTranslation();
  const { activeProvider } = useActiveGames();
  const { query } = useQuery();
  const searchText = query.get('search');

  const filteredGames = favoriteGames.games
    ?.filter(game => searchFilter(game, searchText))
    ?.filter(game => providerFilter(game, activeProvider?.id));

  const filteredCategory = {
    id: 999999,
    slug: 'favorite-games',
    name: t('common:favorite_games'),
    displayGamesCount: Infinity,
    moreGamesToDisplay: Infinity,
    position: 9999,
    showWinnersList: false,
    isMobile: false,
    loadAllGames: true,
    games: filteredGames
      .map(game => ({ ...game, mega_thumb: false, vertical_thumbnail: {} })),
    count: filteredGames?.length,
  };

  return (
    <>
      {!favoriteGames.isLoading && (
        <GameThumbnailList
          category={filteredCategory}
          maxLimit={filteredCategory?.displayGamesCount}
        />
      )}
    </>
  );
}

export default FavoriteGamesList;
