import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isUserLoggedIn, clipHTMLBody } from '../../Helpers';
import store from '../../../containers/App/store';
import { toggleModalIn } from '../../../redux/actions/signInModalActions';
import FavoriteGamesHearthIcon from '../GameMenu/FavoriteGamesHeartIcon';
import useFavoriteGames from '../../../hooks/games/useFavoriteGames';
import useGames from '../../../hooks/games/useGames';

const FavoriteButton = ({ id, provider }) => {
  const { addToFavoriteGames, removeFromFavoriteGames } = useFavoriteGames();
  const [isLoading, setIsLoading] = useState(false);
  const { favoriteGames } = useGames();

  const isFavorite = favoriteGames.games.find(game => game.reference === id)?.id;

  const addToFavorites = async () => {
    await addToFavoriteGames(id, provider);
  };

  const removeFromFavorites = async () => {
    await removeFromFavoriteGames(id, provider);
  };

  const openLoginModal = () => {
    clipHTMLBody();
    store.dispatch(toggleModalIn());
  };

  const toggleFavorite = async () => {
    setIsLoading(true);

    if (isUserLoggedIn()) {
      // eslint-disable-next-line no-unused-expressions
      if (isFavorite) {
        await removeFromFavorites();
      } else {
        await addToFavorites();
      }
    } else {
      openLoginModal();
    }
    setIsLoading(false);
  };

  return (
    <button
      className={`favorite__buttons ${isFavorite ? 'favorited' : ''}`}
      type="button"
      disabled={isLoading}
    >
      {isFavorite ? (
        <FavoriteGamesHearthIcon
          favorite
          fn={toggleFavorite}
          outlineColor="#FFF"
        />
      ) : (
        <FavoriteGamesHearthIcon fn={toggleFavorite} outlineColor="#FFF" />
      )}
    </button>
  );
};

FavoriteButton.propTypes = {
  id: PropTypes.string.isRequired,
  provider: PropTypes.number.isRequired,
};

export default FavoriteButton;
