import {
  TOGGLE_MODAL_TRANSACTIONS,
} from '../actions/transactionModalActions';

const initialState = {
  isOpen: false,
  parameterInfo: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_TRANSACTIONS:
      return {
        ...state,
        isOpen: !state.isOpen,
        parameterInfo: action.parameterInfo,
      };

    default:
      return state;
  }
}
