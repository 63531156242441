import React from 'react';
import PropTypes from 'prop-types';
import GameThumbnailList from '../GameThumbnailList';

function GamesByCategory({ category }) {
  return (
    <>
      {category?.id && (
        <GameThumbnailList
          category={category}
          maxLimit={category?.displayGamesCount}
        />
      )}
    </>
  );
}

export default GamesByCategory;

GamesByCategory.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
};
