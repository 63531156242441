import React from 'react';
import PropTypes from 'prop-types';
import { isUserLoggedIn } from '../../Helpers';

const CarouselCell = ({
  t,
  item,
  onClick,
  i18n,
  itemArray,
}) => {
  const isoLanguage = i18n.language.split('-')[0];
  const languages = {};
  languages.languages = {};
  languages.languages[isoLanguage] = {};
  languages.languages[isoLanguage].imageURL = '';
  let imageUrl = [];
  try {
    const parsedValue = JSON.parse(item.imageUrl);
    /* istanbul ignore next */
    // eslint-disable-next-line no-unused-expressions
    (parsedValue !== null) ? imageUrl = parsedValue : '';
  } catch (err) {
    imageUrl[0] = languages;
  }

  // eslint-disable-next-line no-unused-expressions
  (imageUrl[0] === undefined || imageUrl[0].languages === undefined) ? imageUrl[0] = languages : '';

  return (
    <div className="m-loyalty-shop-item-container">
      <button
        id={item.itemCode}
        type="button"
        data-modal-target="modal-loyalty"
        className="loyaltyShop m-loyalty-shop-item"
        /* istanbul ignore next */
        onClick={isUserLoggedIn() ? () => onClick(item.itemCode, itemArray) : ''}
      >
        <p className="m-loyalty-shop-item-label">
          <span>{item.price}</span>
          <br />
          {t('account:whammies')}
        </p>
        <img src={`${imageUrl[0].languages[isoLanguage].imageURL === '' || imageUrl[0].languages[isoLanguage].imageURL === null ? 'https://placehold.it/700x700' : imageUrl[0].languages[isoLanguage].imageURL}`} width="700px" height="700px" alt="" />
      </button>
    </div>
  );
};

CarouselCell.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    itemCode: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.func.isRequired,
  itemArray: PropTypes.instanceOf(Object).isRequired,
};

export default CarouselCell;
