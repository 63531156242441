import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Casino from '@dialinvest/react-casino';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import BasePage from '../Base';
import { clipHTMLBody, isUserLoggedIn } from '../../Helpers';
import 'flickity-fade';
import CarouselCell from '../../HeroBanner/CarouselCell';
import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';
import { toggleModalIn } from '../../../redux/actions/signInModalActions';

const flickityOptions = {
  lazyload: true,
  pageDots: true,
  prevNextButtons: false,
  fade: true,
  autoPlay: 7000,
  adaptiveHeight: true,
  imagesLoaded: true,
};

class VipPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      nextMonthArticles: [],
    };
  }

  componentDidMount() {
    this.fetchThisMonthArticles();
    this.fetchNextMonthArticles();
  }

  fetchThisMonthArticles = () => {
    const { data } = this.props;
    const startDate = moment('2001-01-01').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');

    const url = `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&valid_from__gte=${startDate}&valid_to__gte=${today}&valid_from__lte=${endDate}&order=valid_from`;
    this.fetchArticles(url, 'articles');
  };

  fetchNextMonthArticles = () => {
    const { data } = this.props;
    const startDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    const endDate = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');

    const url = `pages/articles/?child_of=${data.id}&show_in_menus=true&fields=body,excerpt,valid_from,valid_to,thumbnail,title&valid_from__gte=${startDate}&valid_from__lte=${endDate}&order=valid_from`;
    this.fetchArticles(url, 'nextMonthArticles');
  };

  fetchArticles = async (url, stateName) => {
    const result = await new Casino.FetchContent(url).perform();

    this.setState({
      [stateName]: result.items(),
    });
  };

  handleSignup = async (event) => {
    event.preventDefault();
    const { onToggleModal, onVipApplyToggleModal, onErrorToggleModal } = this.props;
    if (isUserLoggedIn()) {
      const { sessionKey } = new Cookies().get('Session');
      const vipApply = new Cookies().get('vip-apply');
      const substring = 'Email sent to';
      const subject = 'I would like to apply for VIP membership!';
      const response = await new Casino.models.Account().fetchPlayerInfo(sessionKey);
      const result = await new Casino.SendMail('post', 'send-email/', response.data.firstName, response.data.lastName, 'VIP membership request', response.data.email, subject).run();

      if (vipApply !== undefined && result.success) {
        onVipApplyToggleModal(result.data);
        clipHTMLBody();
        return;
      }

      if (result.data !== undefined && result.data.indexOf(substring) !== -1) {
        onVipApplyToggleModal();
        clipHTMLBody();
      } else {
        onErrorToggleModal();
        clipHTMLBody();
      }
    } else {
      onToggleModal();
      clipHTMLBody();
    }
  };

  render() {
    const { t, data } = this.props;
    const {
      articles,
      nextMonthArticles,
    } = this.state;
    let key = 0;
    const carouselCells = data.carousel.map((item) => {
      key += 1;

      return (
        <CarouselCell
          key={key}
          videoSource={item.desktop_video.url || null}
          imageSource={item.desktop_image.url || null}
          mobileVideoSource={item.mobile_device_video.url || null}
          mobileImageSource={item.mobile_device_image.url || null}
          description={data.banner_description}
          buttonLabel={(isUserLoggedIn()) ? `${t('buttons:vip_apply')}` : `${t('buttons:vip_sign_up')}`}
          buttonColor={item.button_color || ''}
          buttonBackgroundColor={item.button_background_color || ''}
          caption={item.caption || ''}
          captionColor={item.caption_color || ''}
          title={item.title || ''}
          titleColor={item.title_color || ''}
          subtitle={item.subtitle || ''}
          subtitleColor={item.subtitle_color || ''}
          onClickAction={e => this.handleSignup(e)}
          showContent
          vip
        />
      );
    });

    return (
      <BasePage data={data}>
        <Flickity
          className="carousel m-carousel js-carousel"
          options={flickityOptions}
        >
          {carouselCells}
        </Flickity>
        <section className="">
          <LoggedOut
            data={data}
            buttonLabel={(isUserLoggedIn()) ? `${t('buttons:vip_apply')}` : `${t('buttons:vip_sign_up')}`}
            onClick={e => this.handleSignup(e)}
            t={t}
          />
          {isUserLoggedIn()
          && (
          <LoggedIn
            title={t('common:vip_calendar_title')}
            articles={articles}
            nextMonthArticles={nextMonthArticles}
            t={t}
          />
          )}
        </section>
      </BasePage>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch(toggleModalIn()),
  onVipApplyToggleModal: data => dispatch({ type: 'TOGGLE_MODAL_VIP_APPLY', payload: data }),
  onErrorToggleModal: () => dispatch({ type: 'TOGGLE_MODAL_ERROR' }),
});

VipPage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    banner_title: PropTypes.string.isRequired,
    banner_description: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }),
  onToggleModal: PropTypes.func.isRequired,
  onVipApplyToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onErrorToggleModal: PropTypes.func.isRequired,
};

VipPage.defaultProps = {
  data: {},
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(),
  withRouter,
)(VipPage);
