import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { reset } from 'redux-form';
import { withCookies, Cookies } from 'react-cookie';
import store from '../../../containers/App/store';
import ResendVerificationCodeModal from './ResendVerificationCodeModal';
import ResendSuccessModal from './ResendSuccessModal';
import { clipHTMLBody } from '../../Helpers';

class ResendVerificationCode extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      message: '',
      success: '',
      onlySendCodeView: false,
      loading: false,
      confirmed: false,
      verificationType: 'mobile',
    };
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.onlySendCodeView === props.onlySendCodeView) return;

    this.setState(prevState => ({
      ...prevState,
      onlySendCodeView: props.onlySendCodeView,
    }));
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  setConfirmed = () => {
    const { cookies } = this.props;

    cookies.remove('signupState', { path: '/' });
    this.setState({ confirmed: true });
  };

  toggleModal = () => {
    const { props } = this;
    store.dispatch(reset('resetResendForm'));
    this.setState({ message: '', success: '', confirmed: false }, () => {
      props.toggleModalResend();
      clipHTMLBody();
    });
  };

  submitResendCode = () => {
    const { state } = this;
    this.setState({ loading: true }, async () => {
      const result = await new Casino.models.ResendVerificationCode(state.username, state.verificationType).perform();
      this.setState({ loading: false }, () => {
        this.setState({
          success: result.success(),
          message: result.message(),
        });
      });
    });
  };

  requestNewCode = (e, verificationType) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      success: '',
      onlySendCodeView: false,
      message: '',
      verificationType,
    }));
  };

  render() {
    const { isOpen } = this.props;
    const {
      message, success, loading, username, confirmed, onlySendCodeView, verificationType,
    } = this.state;

    return (
      <div>
        {!confirmed
          ? (
            <ResendVerificationCodeModal
              isOpen={isOpen}
              message={message}
              success={success || onlySendCodeView}
              submitResendCode={this.submitResendCode}
              handleUsernameChange={this.handleUsernameChange}
              toggleModal={this.toggleModal}
              loading={loading}
              username={username}
              onVerification={this.setConfirmed}
              requestNewCode={this.requestNewCode}
              verificationType={verificationType}
            />
          )
          : (
            <ResendSuccessModal
              isOpen={isOpen}
              toggleModal={this.toggleModal}
            />
          )}
      </div>
    );
  }
}

ResendVerificationCode.propTypes = {
  toggleModalResend: PropTypes.func.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onlySendCodeView: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.resendVerificationCodeModal?.isOpen,
  onlySendCodeView: state.resendVerificationCodeModal?.onlySendCodeView,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalResend: () => dispatch({ type: 'TOGGLE_MODAL_RESEND' }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCookies,
)(ResendVerificationCode);
