import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useLodashDebounce = (callback, time) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      if(ref.current) {
        ref.current();
      }
    };

    return debounce(func, time ?? 1000);
  }, [time]);

  return debouncedCallback;
};
