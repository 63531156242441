import React from 'react';
import PropTypes from 'prop-types';

const LoyaltyPageLevelsTerms = ({
  data,
}) => (
  <>
    <section className="container  m-content-top-padding-x2 m-margin-bottom" dangerouslySetInnerHTML={{ __html: data.levels }} />
    <section className="content section m-page-wrapper" dangerouslySetInnerHTML={{ __html: data.description }} />
  </>
);

LoyaltyPageLevelsTerms.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    levels: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
    sections: PropTypes.instanceOf(Object),
  }).isRequired,
};

export default LoyaltyPageLevelsTerms;
