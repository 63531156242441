import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import { toggleModalEmailSent } from '../../../redux/actions/emailSentModalActions';
import { clipHTMLBody } from '../../Helpers';

export class EmailSent extends PureComponent {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  toggleModal = (e) => {
    e.preventDefault();
    const { props } = this;
    props.toggleModalEmailSent();
    clipHTMLBody();
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      // Do whatever when esc is pressed
      props.toggleModalEmailSent();
      clipHTMLBody();
    }
  };

  render() {
    const { t, isOpen } = this.props;

    return (
      <div id="modal-email-support-confirm" className={`modal m-modal-base modal-confirm modal-fx-superScaled ${isOpen ? 'is-active' : 'is-inactive'}`}>
        <div className="modal-background" />
        <div className="modal-card modal-content">
          <header className="modal-card-head">
            <div className="modal-card-title">
              <h2 className="is-size-3 is-size-5-mobile has-text-white has-text-centered">
                {t('common:email_sent_title')}
              </h2>
            </div>
            <button
              id="delete"
              className="delete"
              aria-label="close"
              type="button"
              onClick={e => this.toggleModal(e)}
            />
          </header>
          <i className="fas has-text-success fa-check-circle fa-3x" />
          <section className="modal-card-body">
            <div className="is-success has-text-white">
              <div className="media">
                <div className="media-content has-text-centered">
                  <br />
                  <strong className="has-text-white is-size-4 is-size-5-mobile">
                    {t('common:email_sent_body_1')}
                  </strong>
                  <br />
                  {t('common:email_sent_body_2')}
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="columns is-centered is-full-width">
              <div className="column is-4-tablet is-hidden-mobile">
                <button
                  id="close"
                  type="button"
                  className="button button-modal-close has-background-light is-medium is-full-width"
                  onClick={e => this.toggleModal(e)}
                >
                  <i className="icon-close" />
                  &nbsp;&nbsp;
                  {t('buttons:close')}
                  &nbsp;&nbsp;
                </button>
              </div>

            </div>
          </footer>
        </div>
      </div>
    );
  }
}

EmailSent.propTypes = {
  toggleModalEmailSent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.emailSentModal?.isOpen,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalEmailSent: () => dispatch(toggleModalEmailSent()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(EmailSent);
