import React from 'react';
import PropTypes from 'prop-types';

const RenderTextAreaField = ({
  name,
  label,
  input,
  placeholder,
  meta: {
    touched,
    error,
    active,
  },
}) => (
  <label htmlFor={name} id={`${name}_label`} className="label">
    {label}
    <div className="control has-icons-left">
      <textarea
        {...input}
        className={`textarea is-medium${(active || touched) && error ? ' is-danger' : ''}`}
        required="required"
        placeholder={placeholder}
      />
    </div>
    {(active || touched) && ((error && <span><p className="help is-danger">{error}</p></span>))}
  </label>
);

RenderTextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

RenderTextAreaField.defaultProps = {
  name: '',
};

export default RenderTextAreaField;
