import React from 'react';
import PropTypes from 'prop-types';

const ProviderLogo = ({ providerList, slug }) => {
  const providerLogo = {
    name: '',
    url: '',
  };
  for (let i = 0; i < providerList?.length; i += 1) {
    if (slug === providerList[i].slug) {
      providerLogo.name = providerList[i].name;
      providerLogo.url = providerList[i].logo?.meta?.download_url;
    }
  }
  return (
    <>
      {providerLogo.url
        && (
          <div className="m-game-grid-item__provider">
            <img className={`${providerLogo.name}`} src={providerLogo.url} alt={providerLogo.name} />
          </div>
        )}
    </>
  );
};

ProviderLogo.propTypes = {
  providerList: PropTypes.instanceOf(Object).isRequired,
  slug: PropTypes.string.isRequired,
};


export default ProviderLogo;
