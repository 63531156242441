/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import validatorRules from '../../../../containers/utitilies/validator_rules';

const maxDateForCalendar = moment().subtract(process.env.REACT_APP_AGE_RESTRICTION, 'years').format('YYYY-MM-DD');

export const accountDetailsConfig = {
  title: 'account_details',
  fields: {
    username: {
      elementType: 'input',
      id: 1,
      elementDescription: 'common:choose_a_username',
      defaultErrorDescription: 'errors:username',
      errorDescription: '',
      title: 'username',
      elementConfig: {
        type: 'text',
        leftIcon: 'fas fa-user',
      },
      value: '',
      validation: {
        required: true,
        pattern: /^[A-Za-z0-9._]+$/,
        minLength: '8',
        maxLength: '50',
        inline: true,
      },
      valid: false,
      touched: false,
      onlineValid: false,
    },
    password: {
      elementType: 'input',
      id: 2,
      title: 'password',
      elementDescription: 'common:choose_a_password',
      defaultErrorDescription: 'errors:password',
      errorDescription: '',
      elementConfig: {
        type: 'password',
        leftIcon: 'fas fa-key',
      },
      value: '',
      validation: {
        required: true,
        pattern: validatorRules.password.pattern,
        minLength: validatorRules.password.minLength,
        maxLength: validatorRules.password.maxLength,
      },
      valid: false,
      touched: false,
    },
    email: {
      elementType: 'input',
      elementDescription: 'fields:email_address',
      defaultErrorDescription: 'errors:email',
      errorDescription: '',
      elementConfig: {
        type: 'email',
        leftIcon: 'fas fa-envelope',
      },
      id: 3,
      title: 'email',
      value: '',
      validation: {
        required: true,
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        minLength: '6',
        maxLength: '254',
        inline: true,
      },
      valid: false,
      touched: false,
      onlineValid: false,
    },
    emailConfirmation: {
      id: 4,
      title: 'emailConfirmation',
      elementType: 'input',
      elementDescription: 'fields:confirm_email_address',
      defaultErrorDescription: 'errors:confirm_email_address',
      errorDescription: '',
      elementConfig: {
        type: 'email',
        leftIcon: 'fas fa-envelope',
      },
      value: '',
      validation: {
        required: true,
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        minLength: '6',
        maxLength: '254',
        confirm: true,
      },
      valid: false,
      touched: false,
    },
    birthDate: {
      elementType: 'date-selects',
      elementDescription: 'fields:dob',
      defaultErrorDescription: 'errors:required_must_be_older_than_18',
      id: 6,
      title: 'birthDate',
      elementConfig: {
        leftIcon: 'fas fa-calendar',
      },
      value: '',
      validation: {
        required: true,
        pattern: /^\d{4}-\d{2}-\d{2}$/,
        maxDate: maxDateForCalendar,
      },
      valid: false,
      touched: false,
    },
    language: {
      id: 8,
      title: 'langCode',
      elementType: 'select',
      elementDescription: 'common:language',
      defaultErrorDescription: 'errors:language',
      errorDescription: '',
      elementConfig: {
        leftIcon: 'fas fa-globe',
      },
      items: [
        { value: 'en', name: 'languages:english' },
        { value: 'de', name: 'languages:deutsch' },
        { value: 'es', name: 'languages:espanol' },
        { value: 'fr', name: 'languages:french' },
        { value: 'fi', name: 'languages:soumi' },
      ],
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      columnClass: 'is-6',
    },
  },
};
