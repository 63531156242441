import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGamesActions from '../../../hooks/games/useGamesActions';
import useQuery from '../../../hooks/useQuery';
import { clearSearchedGames } from '../../../redux/actions/gamesActions';
import useActiveGames from '../../../hooks/games/useActiveGames';
import { useLodashDebounce } from '../../../hooks/useLodashDebounce';

const GameSearchInput = () => {
  const { t } = useTranslation();
  const { query, addQuery, removeQuery } = useQuery();
  const searchText = query.get('search');
  const { search } = useGamesActions();
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();
  const [value, setValue] = React.useState(searchText);
  const inputRef = React.useRef();
  const dispatch = useDispatch();
  
  const debouncedRequest = useLodashDebounce(() => {
    if (!value) {
      removeQuery('search');
      dispatch(clearSearchedGames());
    } else {
      addQuery('search', value);
      search(
        value,
        activeCategory?.name,
        activeProvider?.id || activeStudio?.id,
        activeStudio?.type,
      );
    }
  }, 500);

  useEffect(() => {
    const newSearch = new URLSearchParams(window.location.search).get('search');

    if (!!newSearch) {
      debouncedRequest();
    }
  }, [debouncedRequest]);

  useEffect(() => {
    inputRef.current.focus();

    const buttonEvent = window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') e.preventDefault();
    });

    return () => {
      window.removeEventListener('keydown', buttonEvent);
    };
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    debouncedRequest();
  };

  return (
    <input
      id="game_search_input"
      onChange={handleChange}
      className="input m-game-search-field has-text-primary"
      type="search"
      placeholder={t('common:search_games')}
      autoComplete="off"
      value={value ?? ''}
      name="game_search_input"
      ref={inputRef}
      autoFocus
    />
  );
};

export default GameSearchInput;
