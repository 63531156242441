import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import CarouselCell from './CarouselCell';

let flkty;
let flkty2;
let flkty3;

const LoyaltyPageShop = ({
  loyaltyList,
  onClick,
  t,
  i18n,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(null);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [maxIndex2, setMaxIndex2] = useState(null);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [maxIndex3, setMaxIndex3] = useState(null);
  const flickityOptions = {
    prevNextButtons: false,
    adaptiveHeight: true,
    contain: true,
    pageDots: false,
    wrapAround: false,
    initialIndex: 0,
    groupCells: true,
  };

  useEffect(() => {
    if (flkty !== undefined) {
      /* istanbul ignore next */
      flkty.on('scroll', () => {
        setMaxIndex(flkty.slides.length);
        setCurrentIndex(flkty.selectedIndex);
      });
    }
    if (flkty2 !== undefined) {
      /* istanbul ignore next */
      flkty2.on('scroll', () => {
        setMaxIndex2(flkty2.slides.length);
        setCurrentIndex2(flkty2.selectedIndex);
      });
    }
    if (flkty3 !== undefined) {
      /* istanbul ignore next */
      flkty3.on('scroll', () => {
        setMaxIndex3(flkty3.slides.length);
        setCurrentIndex3(flkty3.selectedIndex);
      });
    }
  });

  function myCustomNext() {
    /* istanbul ignore next */
    flkty.next(false);
  }

  function myCustomPrev() {
    /* istanbul ignore next */
    flkty.previous(false);
  }

  function myCustomNextSecond() {
    /* istanbul ignore next */
    flkty2.next(false);
  }

  function myCustomPrevSecond() {
    /* istanbul ignore next */
    flkty2.previous(false);
  }

  function myCustomNextThird() {
    /* istanbul ignore next */
    flkty3.next(false);
  }

  function myCustomPrevThird() {
    /* istanbul ignore next */
    flkty3.previous(false);
  }

  const bonusItems = loyaltyList.loyaltyItems.filter(items => items.type === 'BONUS_PLAN' && !items.description.toLowerCase().includes('free spin') && !items.description.toLowerCase().includes('freespin') && items.loyaltyItemStatus === 'AVAILABLE');
  const spinItems = loyaltyList.loyaltyItems.filter(items => items.type === 'BONUS_PLAN' && (items.description.toLowerCase().includes('free spin') || items.description.toLowerCase().includes('freespin')) && items.loyaltyItemStatus === 'AVAILABLE');
  const normalItems = loyaltyList.loyaltyItems.filter(items => items.type === 'NORMAL_ITEM' && items.loyaltyItemStatus === 'AVAILABLE');

  return (
    <>
      { bonusItems.length > 0
      && (
        <>
          <div className="content">
            <h2 className="has-text-white">{t('common:bonus_money')}</h2>
          </div>
          <div className="m-loyalty-shop-carousel m-loyalty-section">
            <div id="js-bonus-loyalty-carousel" className="js-carousel-loyalty-items">
              <Flickity
                  /* eslint-disable-next-line no-return-assign */
                className="js-carousel-slide"
                options={flickityOptions}
                flickityRef={c => flkty = c}
              >

                {/* eslint-disable-next-line react/prop-types */}
                { bonusItems.map(item => (
                  CarouselCell({
                    t, item, onClick, i18n, itemArray: bonusItems,
                  })
                ))}

              </Flickity>
              <div id="js-loyalty-shop-carousel-arrows" className="m-loyalty-shop-carousel-arrows">
                <button
                  type="button"
                  id="js-carousel-loyalty-items-nav-left-2"
                  className="m-loyalty-shop-carousel-arrow"
                  style={{ opacity: `${currentIndex === 0 ? '0.25' : ''}` }}
                  disabled={currentIndex === 0}
                  onClick={myCustomPrev}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                </button>
                <button
                  type="button"
                  id="js-carousel-loyalty-items-nav-right-2"
                  className="m-loyalty-shop-carousel-arrow"
                  style={{ opacity: `${currentIndex === maxIndex - 1 ? '0.25' : ''}` }}
                  disabled={currentIndex === maxIndex - 1}
                  onClick={myCustomNext}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      { spinItems.length > 0
      && (
        <>
          <div className="content">
            <h2 className="has-text-white">{t('common:free_spins')}</h2>
          </div>
          <div className="m-loyalty-shop-carousel m-loyalty-section">
            <div id="js-bonus-loyalty-carousel" className="js-carousel-loyalty-items">
              <Flickity
                /* eslint-disable-next-line no-return-assign */
                className="js-carousel-slide"
                options={flickityOptions}
                flickityRef={c => flkty2 = c}
              >

                {/* eslint-disable-next-line react/prop-types */}

                { spinItems.map(item => (
                  CarouselCell({
                    t, item, onClick, i18n, itemArray: spinItems,
                  })
                ))}

              </Flickity>
              <div id="js-loyalty-shop-carousel-arrows" className="m-loyalty-shop-carousel-arrows">
                <button
                  type="button"
                  id="js-carousel-loyalty-items-nav-left-2"
                  className="m-loyalty-shop-carousel-arrow"
                  style={{ opacity: `${currentIndex2 === 0 ? '0.25' : ''}` }}
                  disabled={currentIndex2 === 0}
                  onClick={myCustomPrevSecond}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                </button>
                <button
                  type="button"
                  id="js-carousel-loyalty-items-nav-right-2"
                  className="m-loyalty-shop-carousel-arrow"
                  style={{ opacity: `${currentIndex2 === maxIndex2 - 1 ? '0.25' : ''}` }}
                  disabled={currentIndex2 === maxIndex2 - 1}
                  onClick={myCustomNextSecond}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      { normalItems.length > 0
          && (
            <>
              <div className="content">
                <h2 className="has-text-white">{t('common:normal_items')}</h2>
              </div>
              <div className="m-loyalty-shop-carousel m-loyalty-section">
                <div id="js-bonus-loyalty-carousel" className="js-carousel-loyalty-items">
                  <Flickity
                    /* eslint-disable-next-line no-return-assign */
                    className="js-carousel-slide"
                    options={flickityOptions}
                    flickityRef={c => flkty3 = c}
                  >

                    {/* eslint-disable-next-line react/prop-types */}
                    { normalItems.map(item => (
                      CarouselCell({
                        t, item, onClick, i18n, itemArray: normalItems,
                      })
                    ))}

                  </Flickity>
                  <div id="js-loyalty-shop-carousel-arrows" className="m-loyalty-shop-carousel-arrows">
                    <button
                      type="button"
                      id="js-carousel-loyalty-items-nav-left-2"
                      className="m-loyalty-shop-carousel-arrow"
                      style={{ opacity: `${currentIndex3 === 0 ? '0.25' : ''}` }}
                      disabled={currentIndex3 === 0}
                      onClick={myCustomPrevThird}
                    >
                      <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                    </button>
                    <button
                      type="button"
                      id="js-carousel-loyalty-items-nav-right-2"
                      className="m-loyalty-shop-carousel-arrow"
                      style={{ opacity: `${currentIndex3 === maxIndex3 - 1 ? '0.25' : ''}` }}
                      disabled={currentIndex3 === maxIndex3 - 1}
                      onClick={myCustomNextThird}
                    >
                      <img src={`${process.env.PUBLIC_URL}/images/carousel-arrow.svg`} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
    </>
  );
};

LoyaltyPageShop.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  loyaltyList: PropTypes.shape({
    loyaltyItems: PropTypes.shape({}).isRequired,
  }).isRequired,
  i18n: PropTypes.func.isRequired,
};

export default LoyaltyPageShop;
