import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { callCashierModal } from '../../Helpers';

const icons = {
  'content.TextPage': 'icon-deposit',
  'content.AccountEditPage': 'icon-edit-account',
  'content.AccountTransactionHistory': 'icon-history',
  'content.AccountWithdraw': 'icon-payout',
  'content.AccountVerificationPage': 'icon-verification-default',
  'content.AccountMyLimits': 'icon-my-limits',
  'content.AccountGameHistory': 'icon-game-history',
  'content.AccountDeposit': 'icon-deposit',
  'content.AccountBonuses': 'icon-bonus',
};

const SubPageIcons = ({ subPages }) => (
  <div className="content columns is-centered m-account-icons">
    <div className="column is-12">
      <div className="box columns is-multiline is-centered has-background-mid-grey">
        <div className="column is-8-tablet is-10-desktop is-12-widescreen">
          <div className="columns is-multiline is-mobile is-centered">
            {subPages.map(subPage => (
              <NavLink
                id={subPage.title}
                key={subPage.id}
                to={new URL(subPage.meta.html_url).pathname}
                className={`column is-6-mobile has-text-centered ${subPage.title}`}
                onClick={(subPage.meta.type === 'content.AccountDeposit') ? e => callCashierModal(e) : () => null}
              >
                <div className="square-element-container">
                  <span className="icon">
                    <i className={icons[subPage.meta.type]} />
                  </span>
                  <p className="has-text-white is-uppercase">{subPage.title}</p>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

SubPageIcons.propTypes = {
  subPages: PropTypes.instanceOf(Array),
};

SubPageIcons.defaultProps = {
  subPages: [],
};

export default SubPageIcons;
