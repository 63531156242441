/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Component, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Casino from '@dialinvest/react-casino';
import Input from './inputs/Input';
import BaseSwitch from '../../Layout/Fields/BaseSwitch';
import SignupState from './SignupState';
import SignupHeader from './SignupHeader';
import SignupFooter from './SignupFooter';
import { callCashierModal, callSignInModal } from '../../Helpers';

export const SignupFirstStep = ({ detailsTitle, detailsFields }) => (
  <div className="step-content is-active">
    <fieldset className="m-fieldset">
      <legend className="is-sr-only">{detailsTitle}</legend>
      <div className="columns is-multiline">
        {detailsFields}
      </div>
    </fieldset>
  </div>
);

SignupFirstStep.propTypes = {
  detailsTitle: PropTypes.string.isRequired,
  detailsFields: PropTypes.instanceOf(Object).isRequired,
};

export const SignupSecondStep = ({
  personalDetailsTitle, personalDetailsFields, addressDetailsTitle,
  addressDetailsFields, acceptFields, acceptFieldsValues, onChange,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);

  const confirmAllSwitch = () => {
    Object.entries(acceptFieldsValues).map(
      ([key, item]) => (item.value === value ? onChange({}, key) : undefined),
    );
    setValue(!value);
  };

  useEffect(() => {
    const acceptedFields = Object.entries(acceptFieldsValues).filter(
      ([, item]) => item.value === true,
    );
    setValue(acceptedFields.length === Object.entries(acceptFieldsValues).length);
  }, [acceptFields]);

  const confirmField = (
    <BaseSwitch
      description={t('common:confirm_all_switch')}
      onChange={confirmAllSwitch}
      title="Test"
      value={value}
    >
      {acceptFields}
    </BaseSwitch>
  );

  return (
    <div className="step-content is-active">
      <fieldset className="m-fieldset">
        <legend className="is-sr-only">{personalDetailsTitle}</legend>
        <div className="columns is-multiline">
          {personalDetailsFields}
          <legend className="is-sr-only">{addressDetailsTitle}</legend>
          {addressDetailsFields}
        </div>
      </fieldset>
      <fieldset className="m-fieldset m-field-checkbox-group">
        {confirmField}

      </fieldset>
    </div>
  );
};

SignupSecondStep.propTypes = {
  personalDetailsTitle: PropTypes.string.isRequired,
  personalDetailsFields: PropTypes.instanceOf(Object).isRequired,
  addressDetailsTitle: PropTypes.string.isRequired,
  addressDetailsFields: PropTypes.instanceOf(Object).isRequired,
  acceptFields: PropTypes.instanceOf(Object).isRequired,
  acceptFieldsValues: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const SignupThirdStep = ({
  email, username, onVerification, requestNewCode, verificationType = 'email',
}) => {
  let input;
  const { t } = useTranslation();
  const refFirst = React.createRef();
  const refSecond = React.createRef();
  const refThird = React.createRef();
  const refFourth = React.createRef();
  const [state, setState] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
    email_address: email,
    error: null,
    errorFromApi: false,
    success: null,
    loading: false,
    verification: false,
    verificationType,
  });

  const formatCode = () => `${state.first}${state.second}${state.third}${state.fourth}`;

  useEffect(() => {
    const checkCode = () => {
      const code = formatCode();
      const isnum = /^\d+$/.test(code);
      const emailAddress = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email_address);
      if (code.length === 4 && isnum === true && emailAddress === true) {
        /* istanbul ignore next */
        setState(prev => ({
          ...prev,
          verification: true,
          errorFromApi: false,
          error: null,
        }));
      } else {
        setState(prev => ({
          ...prev,
          verification: false,
          errorFromApi: false,
          error: '',
        }));
      }
    };
    checkCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.first, state.second, state.third, state.fourth, state.email_address]);

  const [cookie, setCookie, removeCookie] = useCookies();

  const smsButtonRef = useRef();
  const emailButtonRef = useRef();

  const disableSMSButton = () => {
    smsButtonRef.current.classList.add('button-disabled-resend');
  };

  const disableEmailButton = () => {
    emailButtonRef.current.classList.add('button-disabled-resend');
  };

  const checkResendButtons = () => {
    if (cookie.smsSent === 'true') {
      disableSMSButton();
    }

    if (cookie.emailSent === 'true') {
      disableEmailButton();
    }
  };

  useEffect(() => {
    checkResendButtons();
  }, [cookie]);

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const autoTab = (e, name) => {
    const BACKSPACE_KEY = 8;

    if (name === 'first' && e.keyCode !== BACKSPACE_KEY) {
      refSecond.current.focus();
    } else if (name === 'second' && e.keyCode !== BACKSPACE_KEY) {
      refThird.current.focus();
    } else if (name === 'second' && e.keyCode === BACKSPACE_KEY && state.second === '') {
      refFirst.current.focus();
    } else if (name === 'third' && e.keyCode !== BACKSPACE_KEY) {
      refFourth.current.focus();
    } else if (name === 'third' && e.keyCode === BACKSPACE_KEY && state.third === '') {
      refSecond.current.focus();
    } else if (name === 'fourth' && e.keyCode === BACKSPACE_KEY && state.fourth === '') {
      refThird.current.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    autoTab(e, name);

    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const disableResendButtonByType = (type) => {
    if (type === 'mobile') {
      setCookie('smsSent', true);
      disableSMSButton();
    } else {
      setCookie('emailSent', true);
      disableEmailButton();
    }
  };

  const removeResendCookies = () => {
    removeCookie('smsSent');
    removeCookie('emailSent');
  };

  const submitResendCode = async (type) => {
    setState(prev => ({
      ...prev,
      success: null,
      error: null,
      errorFromApi: false,
      loading: true,
    }));
    const response = await new Casino.models.ResendVerificationCode(username, type).perform();
    if (response.success()) {
      const message = (type === 'mobile') ? t('common:code_sent_sms') : response.message();
      setState(prev => ({
        ...prev,
        success: message,
        loading: false,
      }));
      disableResendButtonByType(type);
    } else {
      setState(prev => ({
        ...prev,
        error: response.message(),
        errorFromApi: true,
        loading: false,
      }));
      checkResendButtons();
    }
  };

  const submitResendCodeByType = (e, type) => {
    e.preventDefault();

    if (username.trim() === '') {
      requestNewCode(e, type);
    } else {
      submitResendCode(type);
    }

    setState(prev => ({
      ...prev,
      verificationType: type,
    }));
  };

  const verify = async () => {
    const emailAddress = state.email_address;
    setState(prev => ({
      ...prev,
      success: null,
      error: null,
      errorFromApi: false,
      loading: true,
    }));

    const params = {
      confirmationCode: formatCode(),
      email: emailAddress,
    };

    const response = await new Casino.models.SignupConfirmationCode(params).perform();

    if (response.success()) {
      setState(prev => ({
        ...prev,
        success: response.message(),
        loading: false,
      }));
      removeResendCookies();
      onVerification();
    } else {
      setState(prev => ({
        ...prev,
        error: response.message(),
        errorFromApi: true,
        loading: false,
      }));
      checkResendButtons();
    }
  };

  if (email === null || email === undefined) {
    input = (
      <div className="columns is-mobile">
        <div className="column">
          <input
            className="input is-medium"
            id="email_address"
            name="email_address"
            icon="fa-envelope"
            required="required"
            type="text"
            placeholder={t('buttons:e_mail_address')}
            onChange={handleEmailChange}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="step-content is-active has-text-centered">
      <div className="content">
        {(state.verificationType === 'mobile') && (email === null || email === undefined) && (
          <p>{t('common:verify_account_title_sms_with_mail')}</p>
        )}
        {(state.verificationType === 'email') && (email === null || email === undefined) && (
          <p>{t('common:verify_account_title_email_with_mail')}</p>
        )}
        {(state.verificationType === 'mobile') && email !== null && email !== undefined && (
          <p>{t('common:verify_account_title_sms')}</p>
        )}
        {(state.verificationType === 'email') && email !== null && email !== undefined && (
          <p>{t('common:verify_account_title_email')}</p>
        )}
      </div>
      {state.error && (
        <div className={`notification content${state.errorFromApi ? ' is-danger' : ''}`}>
          { state.error}
        </div>
      )}
      {state.success && (
        <div className="notification is-success content">
          { state.success}
        </div>
      )}
      { input}
      <div id="js-verify-sms" className="columns is-mobile">
        <div className="column">
          <input name="first" placeholder="_" key="first" data-index="0" className="input has-text-centered" ref={refFirst} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="second" placeholder="_" key="second" data-index="1" className="input has-text-centered" ref={refSecond} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="third" placeholder="_" key="third" data-index="2" className="input has-text-centered" ref={refThird} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
        <div className="column">
          <input name="fourth" placeholder="_" key="fourth" data-index="3" className="input has-text-centered" ref={refFourth} maxLength={1} minLength={1} type="text" pattern="[0-9]*" onKeyUp={handleChange} />
        </div>
      </div>
      <button type="button" id="verify" className={`button is-success is-medium ${state.loading ? 'is-loading' : ''}`} disabled={state.loading === true || state.verification === false} onClick={verify}>
        {t('buttons:verify')}
      </button>
      {!state.loading && (
        <div className="content">
          <br />
          <p>
            {t('common:resend_text_1')}
            <br />
            <a href="/#" id="resend" style={{ textDecoration: 'underline', fontWeight: 800 }} onClick={e => submitResendCodeByType(e, 'email')} ref={emailButtonRef}>
              EMAIL
            </a>
            {' '}
            {t('common:or')}
            {' '}
            <a href="/#" id="request-code" style={{ textDecoration: 'underline', fontWeight: 800 }} onClick={e => submitResendCodeByType(e, 'mobile')} ref={smsButtonRef}>
              SMS
            </a>
            .
            <br />
            <small className="resend-info">
              (
              {t('common:verification_codes_only_sent_once')}
              )
            </small>
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

SignupThirdStep.propTypes = {
  email: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onVerification: PropTypes.func.isRequired,
  requestNewCode: PropTypes.func.isRequired,
  verificationType: PropTypes.string.isRequired,
};

export const SignupLoadingStep = () => {
  const { t } = useTranslation();
  return (
    <div className="content has-text-centered step-content is-active is-centered">
      <div className="notification">
        <progress className="progress is-large is-success is-primary" max="100" />
        <h1>{t('common:please_wait')}</h1>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
export const SignupFourthStep = ({ closeButton }) => {
  const history = useHistory();
  const { t } = useTranslation();
  function onClick(e) {
    closeButton();
    callCashierModal(e, 'deposit');
  }

  function onClickLimit(e) {
    e.preventDefault();
    closeButton();
    if (process.env.REACT_APP_REGISTRATION_AUTO_LOGIN !== 'true') callSignInModal(e);
    history.push('/account/my-limits');
  }

  return (
    <div className="content has-text-centered step-content is-active">
      <div className="notification">
        <h1>{t('common:registration_successful')}</h1>
        <p className="has-text-black">{t('common:you_will_be_eligible')}</p>
        <a
          className="button is-success is-medium is-uppercase"
          id="deposit"
          href="/#"
          onClick={onClick}
        >
          {t('buttons:deposit_now')}
        </a>
        <br />
        <br />
        <a
          className="is-success is-medium is-uppercase"
          id="limit"
          href="/#"
          onClick={onClickLimit}
        >
          {t('buttons:set_your_limits')}
        </a>
      </div>
    </div>
  );
};

SignupFourthStep.propTypes = {
  closeButton: PropTypes.func.isRequired,
};

export const SignupFailedStep = () => {
  const { t } = useTranslation('common');

  return (
    <div className="content has-text-centered step-content is-active">
      <div className="notification">
        <h1>{t('registration_failed')}</h1>
        <p>{t('please_contact_support')}</p>
      </div>
    </div>
  );
};

export const SignupSection = ({ renderedStep }) => (
  <section id="sign-up-steps-body" className="modal-card-body">
    <div className="columns">
      <div className="column is-12 steps">
        <div className="steps-content">
          {renderedStep}
        </div>
      </div>
    </div>
  </section>
);

SignupSection.propTypes = {
  renderedStep: PropTypes.instanceOf(Object).isRequired,
};

class SignupForm extends Component {
  onChange(key, event) {
    this.props.inputChangedHandler(event, key);
  }

  renderFields(fields) {
    const { t } = this.props;

    return Object.entries(fields).map(([key, value]) => (
      <Input
        key={value.id}
        title={value.title}
        elementType={value.elementType}
        elementDescription={t(value.elementDescription)}
        errorDescription={t(value.errorDescription || value.defaultErrorDescription)}
        elementConfig={value.elementConfig}
        value={value.value}
        onlineValid={value.onlineValid}
        items={value.items}
        onChange={this.onChange.bind(this, key)}
        valid={value.valid}
        touched={value.touched}
        validation={value.validation}
        columnClass={value.columnClass}
        corrected={value.corrected}
      />
    ));
  }

  renderFirstStep() {
    const { accountDetails } = this.props;
    const accountDetailsFields = this.renderFields(accountDetails.fields);
    return (
      <SignupFirstStep
        detailsTitle={accountDetails.title}
        detailsFields={accountDetailsFields}
      />
    );
  }

  renderSecondStep() {
    const {
      personalDetails, addressDetails, acceptFields, inputChangedHandler,
    } = this.props;
    const personalDetailsFields = this.renderFields(personalDetails.fields);
    const addressDetailsFields = this.renderFields(addressDetails.fields);
    const acceptDetailsFields = this.renderFields(acceptFields.fields);

    return (
      <SignupSecondStep
        personalDetailsTitle={personalDetails.title}
        personalDetailsFields={personalDetailsFields}
        addressDetailsTitle={addressDetails.title}
        addressDetailsFields={addressDetailsFields}
        acceptFields={acceptDetailsFields}
        acceptFieldsValues={acceptFields.fields}
        onChange={inputChangedHandler}
      />
    );
  }

  renderThirdStep() {
    const { accountDetails: { fields: { email, username } }, nextButton } = this.props;

    return (
      <SignupThirdStep
        email={email.value}
        username={username.value}
        onVerification={nextButton}
      />
    );
  }

  renderStep(signupState) {
    const { closeButton } = this.props;
    let renderedStep = '';

    if (this.lastState !== signupState) {
      this.lastState = signupState;
      const modal = document.getElementById('modal-card-head');
      if (modal) modal.scrollIntoView();
    }

    switch (signupState) {
      case SignupState.first:
        renderedStep = this.renderFirstStep();
        break;
      case SignupState.second:
        renderedStep = this.renderSecondStep();
        break;
      case SignupState.third:
        renderedStep = this.renderThirdStep();
        break;
      case SignupState.fourth:
        renderedStep = (<SignupFourthStep closeButton={closeButton} />);
        break;
      case SignupState.error:
        renderedStep = (<SignupFailedStep />);
        break;
      default:
        renderedStep = (<SignupLoadingStep />);
        break;
    }

    return renderedStep;
  }

  render() {
    const {
      signupState, nextButton, previousButton, closeButton, cancelButton, isValidRecaptcha,
    } = this.props;

    const renderedStep = this.renderStep(signupState);

    return (
      <div id={`sign-up-steps-${signupState}`} className="modal-card modal-content">
        <SignupHeader
          signupState={signupState}
          cancelButton={cancelButton}
        />
        <SignupSection renderedStep={renderedStep} />
        <SignupFooter
          nextButton={nextButton}
          previousButton={previousButton}
          closeButton={closeButton}
          cancelButton={cancelButton}
          signupState={signupState}
          isValidRecaptcha={isValidRecaptcha}
        />
      </div>
    );
  }
}

SignupForm.propTypes = {
  signupState: PropTypes.instanceOf(SignupState).isRequired,
  inputChangedHandler: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  previousButton: PropTypes.func.isRequired,
  nextButton: PropTypes.func.isRequired,
  cancelButton: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  personalDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  addressDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  accountDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  acceptFields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  isValidRecaptcha: PropTypes.bool.isRequired,
};

export default withTranslation()(SignupForm);
