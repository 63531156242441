import React from 'react';
import PropTypes from 'prop-types';

const CarouselCell = ({
  t,
  item,
  onClick,
  onClickBuy,
  loading,
  i18n,
}) => {
  const isoLanguage = i18n.language.split('-')[0];
  let imageUrl = [];
  const languages = {};
  languages.languages = {};
  languages.languages[isoLanguage] = {};
  languages.languages[isoLanguage].imageURL = '';
  languages.languages[isoLanguage].imageDescription = '';
  languages.languages[isoLanguage].Description = '';
  try {
    const parsedValue = JSON.parse(item.imageUrl);
    /* istanbul ignore next */
    // eslint-disable-next-line no-unused-expressions
    (parsedValue !== null) ? imageUrl = parsedValue : '';
  } catch (err) {
    imageUrl[0] = languages;
  }

  // eslint-disable-next-line no-unused-expressions
  (imageUrl[0] === undefined || imageUrl[0].languages === undefined) ? imageUrl[0] = languages : '';

  return (
    <div className="m-loyalty-carousel-item">
      <div className="m-loyalty-shop-item">
        <p className="m-loyalty-shop-item-label">
          <span>{item.price}</span>
          <br />
          {t('account:whammies')}
        </p>
        <img src={`${imageUrl[0].languages[isoLanguage].imageURL === '' || imageUrl[0].languages[isoLanguage].imageURL === null ? 'https://placehold.it/300x300' : imageUrl[0].languages[isoLanguage].imageURL}`} width="300px" height="300px" alt="" />
      </div>
      <div className="content has-text-centered">
        <h3 className="m-loyalty-modal-title is-size-3-tablet is-size-4">
          {imageUrl[0].languages[isoLanguage].Description}
        </h3>
        <div className="has-text-centered">
          {/* eslint-disable-next-line max-len */}
          <p>
            {imageUrl[0].languages[isoLanguage].imageDescription}
          </p>
        </div>
        <div className="buttons has-text-centered">
          <button
            id="close"
            type="button"
            className="button is-primary is-size-6 is-outlined button-modal-close"
            onClick={onClick}
          >
            {t('buttons:close')}
          </button>
          <button
            id="buy"
            type="button"
            className={`button is-success is-size-6 ${loading ? 'is-loading' : ''}`}
            onClick={() => onClickBuy(item.price, item.itemCode)}
          >
            {t('buttons:buyNow')}
          </button>
        </div>
      </div>
    </div>
  );
};

CarouselCell.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickBuy: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    itemCode: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.func.isRequired,
};

export default CarouselCell;
