import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GameThumbnail from '../GameThumbnail';
import useGamesActions from '../../../hooks/games/useGamesActions';
import useGames from '../../../hooks/games/useGames';
import useActiveGames from '../../../hooks/games/useActiveGames';
import useQuery from '../../../hooks/useQuery';

export function getFeaturedGamesCategory(categories, translate) {
  return categories.items.find(
    category => category.slug === translate('common:featured_games_category'),
  );
}

const FeaturedGameThumbnails = () => {
  const { t } = useTranslation();
  const { categories, gamesByCategories } = useGames();
  const { setFeaturedGames } = useGamesActions();
  const { activeCategory, activeProvider } = useActiveGames();
  const { query } = useQuery();

  const searchQuery = query.get('search');
  const featuredGamesCategory = getFeaturedGamesCategory(categories, t);
  const featuredGames = gamesByCategories[featuredGamesCategory?.id];

  useEffect(() => {
    if (!activeCategory?.id && !activeProvider?.id && !searchQuery) return;

    if (!featuredGames?.id) {
      setFeaturedGames(featuredGamesCategory);
    }
  }, [
    featuredGames,
    setFeaturedGames,
    activeCategory,
    activeProvider,
    featuredGamesCategory,
    searchQuery,
  ]);

  if (!featuredGames?.id) return null;

  return (
    <>
      <h2 className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered mt-6 ">
        {featuredGames.name}
      </h2>
      <div
        id="game-grid"
        className="m-game-grid columns is-multiline is-mobile parent-grid"
      >
        {featuredGames?.games.map((item, key) => (
          <GameThumbnail
            favorite={item.favorite}
            key={`${key.toString()}${item.id.toString()}`}
            {...item}
            getMaintenance={t('buttons:under_maintenance')}
          />
        ))}
      </div>
    </>
  );
};

export default FeaturedGameThumbnails;
