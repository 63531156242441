import i18next from 'i18next';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = i18next.t('errors:required');
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
    errors.email = i18next.t('errors:email_format');
  }

  if (!values.question || values.question.trim() === '') {
    errors.question = i18next.t('errors:required');
  }
  return errors;
};

export default validate;
