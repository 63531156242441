import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../../Helpers';

export const BlogNavigationButtons = ({ data }) => {
  const { t } = useTranslation();

  const previousButton = data.prev_page ? (
    <NavLink to={getPathname(data.prev_page.html_url)} className="pagination-previous is-borderless is-size-5 is-size-6-mobile">
      <i className="fas fa-angle-left fa-lg" />
          &nbsp;&nbsp;
      {t('common:previous_button')}
    </NavLink>
  ) : (<div className="pagination-previous is-borderless is-size-5 is-size-6-mobile"> </div>);

  const nextButton = data.next_page ? (
    <NavLink to={getPathname(data.next_page.html_url)} className="pagination-next is-borderless is-size-5 is-size-6-mobile">
      {t('common:next_button')}
          &nbsp;&nbsp;
      <i className="fas fa-angle-right fa-lg" />
    </NavLink>
  ) : (<div className="pagination-next is-borderless is-size-5 is-size-6-mobile"> </div>);

  return (
    <nav className="pagination is-centered" role="navigation" aria-label="pagination">
      {previousButton}
      {nextButton}
    </nav>
  );
};

BlogNavigationButtons.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

/* istanbul ignore next */
export const BlogNavButton = ({ data }) => (
  <div className="buttons">
    {
      data.button_target
        ? (
          <NavLink
            className="button is-wide is-centered is-large is-dark-green"
            replace
            to={getPathname(
              data.button_target.html_url,
            )}
          >
            {data.button_label}
          </NavLink>
        ) : (
          <a
            className="button is-wide is-centered is-large is-dark-green"
            replace
            href={data.button_external_link}
          >
            {data.button_label}
          </a>
        )
    }
  </div>
);

BlogNavButton.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const BlogPageDetails = ({ data }) => (
  <>
    {data.button_label
      && ((data.button_target && data.button_target.html_url) || data.button_external_link)
      && (
        <BlogNavButton
          data={data}
        />
      )}
    <div className="columns is-centered">
      <div className="column is-6">
        <BlogNavigationButtons data={data} />
      </div>
    </div>
  </>
);

BlogPageDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BlogPageDetails;
