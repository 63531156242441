import React from 'react';
import PropTypes from 'prop-types';
import SignupDispatcher from './Signup/SignupDispatcher';
import Signin from './Signin';
import Cashier from './Cashier';
import SupportPanel from './SupportPanel';
import CookieAcceptance from './CookieAcceptance';
import ResendVerificationCode from './ResendVerificationCode';
import PasswordResetRequest from './PasswordResetRequest';
import PasswordReset from './PasswordReset';
import Transactions from './Transactions';
import VipApply from './VipApply';
import EmailSent from './EmailSent';
import { usePageTypeContext } from '../../containers/App/PageTypeContext';
import { needHeaderFooter } from '../Helpers';
import ConnectionErrorPopUp from './ConnectionErrorPopUp';
import LoyaltyShop from './LoyaltyShop';
import ErrorModal from './ErrorModal';

const ModalWrapper = ({ children }) => {
  const { pageType } = usePageTypeContext();

  return (
    <>
      {children}
      <SignupDispatcher />
      {pageType !== 'InternalPage' && <CookieAcceptance />}
      <ResendVerificationCode />
      <PasswordResetRequest />
      <PasswordReset />
      <Signin />
      <Transactions />
      {needHeaderFooter(pageType) && <SupportPanel />}
      <Cashier />
      <VipApply />
      <EmailSent />
      <ConnectionErrorPopUp />
      <LoyaltyShop />
      <ErrorModal />
    </>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalWrapper;
