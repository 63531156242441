import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { reset } from 'redux-form';
import store from '../../../containers/App/store';
import PasswordResetModal from './PasswordResetModal';
import { toggleModalReset } from '../../../redux/actions/passwordResetActions';
import { clipHTMLBody } from '../../Helpers';

class PasswordReset extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      new_password: '',
      confirm_new_password: '',
      reset_key: '',
      message: '',
      success: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen === true) {
      clipHTMLBody();
    }
  }

  handleNewPasswordChange = (event) => {
    this.setState({ new_password: event.target.value });
  };

  handleConfirmNewPasswordChange = (event) => {
    this.setState({ confirm_new_password: event.target.value });
  };

  toggleModal = () => {
    const { props, state } = this;
    store.dispatch(reset('resetForm'));
    this.setState({ ...state, message: '' });
    props.toggleModalReset();
    clipHTMLBody();
  };

  submitPasswordChange = async () => {
    const { props, state } = this;
    const parameters = {
      email: props.queryParams.email,
      resetPasswordKey: props.queryParams.confirmationCode,
      newPassword: state.new_password,
    };
    this.setState({ loading: true }, async () => {
      const result = await new Casino.ForgotPasswordStep3(parameters).perform();
      this.setState({ loading: false }, () => {
        store.dispatch(reset('resetForm'));
        if (result.success()) {
          this.setState({
            success: result.success(),
            message: result.message(),
          });
        } else {
          this.showError(result);
        }
      });
    });
  };

  showError(result) {
    if (result.message() === null) {
      this.setState({ message: `${result.data.status}` });
    } else {
      this.setState({ message: result.message() });
    }
  }

  render() {
    const { isOpen } = this.props;
    const { message, success, loading } = this.state;

    return (
      <PasswordResetModal
        isOpen={isOpen}
        message={message}
        success={success}
        submitPasswordChange={this.submitPasswordChange}
        handleNewPasswordChange={this.handleNewPasswordChange}
        handleConfirmNewPasswordChange={this.handleConfirmNewPasswordChange}
        toggleModal={this.toggleModal}
        loading={loading}
      />
    );
  }
}

PasswordReset.propTypes = {
  toggleModalReset: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  queryParams: PropTypes.string,
};

PasswordReset.defaultProps = {
  queryParams: '',
};

export const mapStateToProps = state => ({
  isOpen: state.passwordResetModal?.isOpen,
  queryParams: state.passwordResetModal?.queryParams,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalReset: queryParams => dispatch(toggleModalReset(queryParams)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PasswordReset);
