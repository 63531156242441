import React from 'react';
import PropTypes from 'prop-types';
import PromotionWidgetWrapper from '../Promotion/PromotionWidgetWrapper';
import HeroPromotionWidget from './HeroPromotionWidget';

const HeroPromotions = ({ featuredPromotion }) => (
  <PromotionWidgetWrapper id={featuredPromotion.id} type="non-calendar">
    {({ items }) => (
      <HeroPromotionWidget items={items} linkTo={featuredPromotion.html_url} />
    )}
  </PromotionWidgetWrapper>
);

HeroPromotions.propTypes = {
  featuredPromotion: PropTypes.instanceOf(Object).isRequired,
};

export default HeroPromotions;
