import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { meta, components } from '@dialinvest/react-casino';
import { modalIsOpen } from '../Helpers';
import MetaData from './MetaData';
import PageLoader from '../PageLoader';

const BasePage = ({ data, children }) => {
  const [loading, setLoading] = useState(true);
  const schemas = useRef(null);
  const { t } = useTranslation('common');

  const appendSeoSchema = (schema) => {
    schemas.current.innerHTML = '';
    const range = document.createRange();
    const documentFragment = range.createContextualFragment(schema);
    schemas.current.appendChild(documentFragment);
  };

  useEffect(() => {
    if (data.seo_schema !== '') appendSeoSchema(data.seo_schema);
    setTimeout(() => setLoading(false), 400);
  }, [data]);

  return (
    <>
      {loading && <PageLoader />}
      {data.seo_meta && <MetaData seoMeta={data.seo_meta} seoMetaDescription={data.seo_meta_description || t('default_seo_description')} />}
      <Helmet>
        <link rel="canonical" href={data.meta.html_url} />
        <meta property="og:url" content={data.meta.html_url} />
        {modalIsOpen() && <meta name="robots" content="noindex" />}
        <meta property="og:site_name" content="Fruits4Real" />
        <meta property="og:image" content="/images/favicons/fruits4real-og-image.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://fruits4real.com/" />
        <meta property="twitter:image" content="/images/favicons/fruits4real-twitter-image.jpg" />
      </Helmet>
      <main className="main-content-parent main-content-parent-short">
        {children}
      </main>
      <components.Accordion data={data} />
      <div id="schemas" ref={schemas} />
    </>
  );
};

BasePage.propTypes = {
  data: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,

};

BasePage.defaultProps = {
  data: {},
};

export default BasePage;
