import {
  TOGGLE_MODAL_LOYALTY_SHOP,
} from '../actions/loyaltyShopActions';

const initialState = {
  isOpen: false,
  itemCode: '',
  loyaltyList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_LOYALTY_SHOP:
      return {
        ...state,
        isOpen: !state.isOpen,
        itemCode: action.itemCode,
        loyaltyList: action.loyaltyList,
      };
    default:
      return state;
  }
}
