import moment from 'moment';
import momentTz from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import { Cookies } from 'react-cookie';
import i18next from 'i18next';
import Casino from '@dialinvest/react-casino';
import React from 'react';
import store from '../../containers/App/store';
import { toggleModalCashier } from '../../redux/actions/cashierModalActions';
import { toggleModalIn } from '../../redux/actions/signInModalActions';
import TimeOut from '../../containers/App/timeOut';
import { toggleModalError } from '../../redux/actions/errorModalActions';

export default function handleClick(e) {
  if (typeof this.node !== 'undefined' && !this.node.contains(e.target)) {
    this.setState({
      isActive: false,
    });
  }
}

export const clipHTMLBody = () => {
  document.body.classList.toggle('is-clipped');
};

export const unClipHTMLBody = () => {
  document.body.classList.remove('is-clipped');
};

export function callCashierModal(e, cashierType = 'deposit') {
  e.preventDefault();
  store.dispatch(toggleModalCashier(cashierType));
  clipHTMLBody();
}

export function callSignInModal(e) {
  e.preventDefault();
  store.dispatch(toggleModalIn());
  clipHTMLBody();
}

export function handleClickOutside(ref, callback) {
  const toggleIsActive = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  document.addEventListener('mousedown', toggleIsActive);
}

export function truncate(string, length) {
  if (string.length > length) {
    return `${string.substring(0, length)}...`;
  }

  return string;
}

export function stripHtml(string) {
  return string?.replace(/<br\/>/g, ' ').replace(/(<([^>]+)>)/ig, '');
}

export function getPathname(url) {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return '';
  }
}

export function getLocalTime(date, language = 'en', format = 'll HH:mm:ss') {
  const localTz = momentTz.tz.guess();
  const currentLocal = language.split('-')[0];
  const time = momentTz.tz(date, localTz);

  return time.isValid() ? time.locale(currentLocal).format(format) : '';
}

export function getFormattedLocalTime(date, format) {
  const localTz = momentTz.tz.guess();
  const currentLocal = i18next.language.split('-')[0];

  return momentTz.tz(date, localTz).locale(currentLocal).format(format);
}

export function formatLocalizedDate(date, format) {
  const currentLocal = i18next.language.split('-')[0];

  return moment(date).locale(currentLocal).format(format);
}

export function formatDateToLocale(date) {
  return new Date(date).toLocaleString().replace(',', '');
}

export function formatToOmegaDatetime(date) {
  let formattedDate = date;

  if (typeof formattedDate === 'string') formattedDate = moment(date);

  return `${formattedDate.format('YYYY-MM-DDThh:mm:ss')}Z`;
}

export function eraseCookie(name) {
  document.cookie = `${name} =; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getSessionCookie = () => {
  const logoutPath = window.location.pathname.includes('logout');
  if (logoutPath) return null;

  const cookie = new Casino.Cookie().get(process.env.REACT_APP_API_COOKIE_NAME);

  if (cookie) {
    try {
      const sessionCookie = JSON.parse(cookie);
      return sessionCookie;
    } catch (error) {
      new Cookies().remove(process.env.REACT_APP_API_COOKIE_NAME);
      return null;
    }
  }

  return null;
};

export function checkSessionCookie() {
  const cookie = getSessionCookie();
  if (!cookie) return false;

  if (cookie?.sessionKey) return true;

  return false;
}

export function isUserLoggedIn() {
  return checkSessionCookie();
}

export const getLocaleFirstPart = () => i18next.language.split('-')[0];

export function launchLiveChat() {
  const locale = getLocaleFirstPart().toUpperCase();
  let url;

  if (process.env[`REACT_APP_LIVE_CHAT_LINK_${locale}`]) {
    url = process.env[`REACT_APP_LIVE_CHAT_LINK_${locale}`];
  } else {
    url = process.env.REACT_APP_LIVE_CHAT_LINK_EN;
  }

  const top = (window.innerHeight + 420);
  const left = (window.innerWidth + 280);
  window.open(url, '<iframe>', `height=420,width=280,location=no,menubar=no,status=no,titlebar=no,toolbar=no,top=${top},left=${left}`);
}

export const showAsPopUp = (url) => {
  const top = (window.innerHeight + 420);
  const left = (window.innerWidth + 280);
  window.open(url, '<iframe>', `height=420,width=280,location=no,menubar=no,status=no,titlebar=no,toolbar=no,top=${top},left=${left}`);
};

export const showItemInFlatMenu = (item, userLoggedIn) => {
  if (item === undefined) {
    return true;
  }

  if (item.show_when_logged_in === undefined) {
    return true;
  }

  if (item.show_when_logged_in === false) {
    return true;
  }

  if (userLoggedIn === true) {
    if (isUserLoggedIn()) {
      return true;
    }
  }

  return false;
};

export const paginationLoader = pLoading => (
  // eslint-disable-next-line react/jsx-filename-extension
  <div className={`loader-wrapper has-background-dark ${pLoading ? 'is-active' : 'is-hidden'}`}>
    <div className="loader is-loading" />
  </div>
);

export const getQueryParam = (param, searchParams = null) => {
  const search = (searchParams || window.location.search);
  return new URLSearchParams(search).get(param);
};

export const isRealPlay = (searchParams = null) => {
  const playForReal = getQueryParam('mode', searchParams);

  return (playForReal === 'real');
};

export const getQueryLocale = (searchParams = null) => {
  const { locales: { availableLocales } } = store.getState();
  const allowedLocales = availableLocales.map(lang => lang.locale);

  const localeMap = {
    'en-eu': 'en-int',
  };
  const locale = getQueryParam('locale', searchParams);
  if (locale === null || !allowedLocales.includes(locale.toLowerCase())) return null;

  return localeMap[locale.toLowerCase()] || locale.toLowerCase();
};

export const setFullScreen = (element) => {
  document.body.classList.add('fullscreen-mode');

  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) { /* Firefox */
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) { /* IE/Edge */
    element.msRequestFullscreen();
  }
};

export const isMobile = () => ({
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
  // eslint-disable-next-line max-len
  any: () => (isMobile().Android() || isMobile().BlackBerry() || isMobile().iOS() || isMobile().Opera() || isMobile().Windows()),
});

export const getCookieValues = () => {
  if (document.cookie.indexOf(process.env.REACT_APP_API_COOKIE_NAME) < 0) return null;

  return JSON.parse(new Casino.Cookie().get(process.env.REACT_APP_API_COOKIE_NAME));
};

export const getSession = () => {
  if (document.cookie.indexOf(process.env.REACT_APP_API_COOKIE_NAME) < 0) {
    new TimeOut().openModal();
    return false;
  }
  return new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
};

export const isPrerenderAgent = () => (navigator.userAgent.includes('Prerender') || navigator.userAgent.includes('prerender'));

export const capitalize = str => str?.charAt(0).toUpperCase() + str?.slice(1);

export const isBodyClipped = () => document.body.classList.contains('is-clipped');

export function showGeneraErrorModal(message = null) {
  store.dispatch(toggleModalError(message, true));
  if (!isBodyClipped()) clipHTMLBody();
}

export function showConnectionErrorPopUp(connectionError, calledFunction = '') {
  let message;

  if (!connectionError) {
    message = i18next.t('common:connection_restored');
  } else if (!navigator.onLine) {
    message = i18next.t('errors:currently_offline');
  } else {
    message = i18next.t('errors:system_not_responding');
  }

  store.dispatch(toggleModalError(message, true, connectionError, calledFunction));
}

export function apiCall(callback, calledFunction = '') {
  const storeState = store.getState();

  return new Promise((resolve, reject) => {
    const responseStatusFail = response => response.data && response.data.status && response.data.status === 'FAIL';

    const error = () => {
      showConnectionErrorPopUp(true, calledFunction);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(false);
    };

    // eslint-disable-next-line consistent-return
    callback.then((response) => {
      if (response.status === 404 || responseStatusFail(response)) {
        return error();
      }

      if (storeState.errorModal.connectionError && storeState.errorModal.calledFunction === calledFunction) {
        showConnectionErrorPopUp(false);
      }

      resolve(response);
    }).catch(() => error());
  });
}

export const needHeaderFooter = pageType => !['InternalPage', '404'].includes(pageType);

export const formatWhammies = points => String(points).padStart(6, '0');

export const formatAmount = amount => amount.toFixed(2);

export const generateAlternateLinks = () => {
  const state = store.getState();
  const addLanguageToLink = language => (window.location.origin + language);
  const split = locale => (locale.split('-').length > 1 ? locale.split('-')[0] : locale);

  const links = state.locales.availableLocales.map(
    item => <link rel="alternate" href={addLanguageToLink(`/${item.locale}/`)} hrefLang={split(item.locale)} />,
  );
  links.push(<link rel="alternate" href={addLanguageToLink('')} hrefLang="x-default" />);

  return links;
};

export const modalIsOpen = () => {
  const state = store.getState();
  return Object.entries(state).findIndex(([, value]) => value.isOpen === true) > -1;
};

export const formatLangToIso = lang => lang.split('-')[0];

export const checkBonusPlanAvailable = async () => {
  let depositNr = 0;

  if (isUserLoggedIn()) {
    const cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
    const { sessionKey } = cookieValues;
    const startDate = '2020-01-01';
    const endDate = moment().format('YYYY-MM-DD');
    await new Casino.models.Account().getBankingHistory({
      sessionKey,
      startDate,
      endDate,
      order: 'DESC',
    }).then(
      (content) => {
        if (content.data.histories !== undefined) {
          depositNr = content.data.histories.filter(item => item.type === 'DEPOSIT' && item.status === 'COMPLETED').length;
          return depositNr;
        }
      },
    );
  }

  return depositNr;
};

export const getPlayerInfo = async () => {
  const { sessionKey } = new Cookies().get('Session');

  const playerInfo = await new Casino.PlayerInfo(sessionKey).perform();

  return playerInfo;
};

export const filterBonusByDescription = (bonuses) => {
  if (!bonuses || bonuses.length === 0) return [];

  const filteredBonuses = [...bonuses];
  const filteredItems = process.env.REACT_APP_EXCLUDED_BONUS_NAME.split(',');

  for (let i = 0; i < bonuses.length; i++) {
    if (bonuses[i].description !== null) {
      const bonusDescriptionArray = Object.keys(bonuses[i].description);
      for (let j = 0; j < filteredItems.length; j++) {
        if (bonusDescriptionArray.includes(filteredItems[j])
          || bonuses[i].description === filteredItems[j]) {
          filteredBonuses.splice(filteredBonuses.indexOf(bonuses[i]), 1);
        }
      }
    }
  }

  return filteredBonuses;
};

export const isJSON = (data) => {
  if (!data) return false;

  if (typeof data !== 'string') JSON.stringify(data);

  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseDescription = (string) => {
  let parsedValue = '';

  if (!string) return parsedValue;
  if (!isJSON(string)) return string;

  if (isJSON(string)) {
    parsedValue = Array.isArray(JSON.parse(string))
      ? JSON.parse(string)[0]
      : JSON.parse(string);
  }

  return parsedValue;
};
export const getBonusPageBySlug = async (data) => {
  let bonusPage = {};

  const url = `${i18next.language}/promotions/weekend-booster`;
  if (!data || !data.description || !(data.description.slug)) {
    bonusPage.message = 'Default Promo Page';
    const { data: pageData } = await new Casino.models.Page().fetch(url);
    return { ...bonusPage, ...pageData };
  }

  // eslint-disable-next-line no-unused-expressions
  data.description?.slug?.length
    ? bonusPage = data.description
    : bonusPage.message = 'not found';

  // eslint-disable-next-line no-unused-expressions
  bonusPage.slug?.length
    ? (bonusPage.url = `${i18next.language}/promotions/${bonusPage.slug}`)
    : (bonusPage.url = url);

  const { data: pageData } = await new Casino.models.Page().fetch(bonusPage.url);

  if (pageData.message === 'not found') {
    const { data: pageDataInfo } = await new Casino.models.Page().fetch(url);
    bonusPage.pageDetails = pageDataInfo;
    return bonusPage.pageDetails;
  }

  bonusPage.pageDetails = pageData;
  return bonusPage.pageDetails;
};

const getParseDepositBouns = (data) => {
  const result = data.map(bonus => ({
    ...bonus,
    description: typeof bonus.description === 'string'
      ? parseDescription(bonus.description)
      : bonus.description,
  }));
  return result;
};

export const getBonusesCurrent = (parsedDepositBonus, getBonusesDate) => {
  if (parsedDepositBonus.length > 0) {
    getBonusesDate.current = parsedDepositBonus[0];
  } else {
    getBonusesDate.current = null;
  }
  getBonusesDate.all = parsedDepositBonus;

  return getBonusesDate;
};

export const getBonuses = async (sessionKey) => {
  const getBonusesDate = {};

  const BONUS_PATTERN_SECOND = '2nd deposit';
  const BONUS_PATTERN_THIRD = '3rd deposit';

  const params = {
    sessionKey,
    depositAmount: '3500',
    paymentMethod: 'PAYMENTIQ_ALL',
    itemsPerPage: 0,
  };
  const startDate = '2020-01-01';
  const endDate = moment().format('YYYY-MM-DD');
  let filteredAvailable = [];
  const available = await new Casino.models.CasinoBase().request('get', 'getDepositEligibleBonusPlans', params);

  if (available.success()) {
    if (available.data.bonusPlans !== undefined) {
      const bankHistoryResponse = await new Casino.models.Account().getBankingHistory({
        sessionKey,
        startDate,
        endDate,
        order: 'DESC',
      });

      if (bankHistoryResponse.data.histories !== undefined) {
        const depositNr = bankHistoryResponse.data.histories.filter(item => item.type === 'DEPOSIT' && item.status === 'COMPLETED').length;
        getBonusesDate.depositNr = depositNr;

        if (depositNr === 0) {
          filteredAvailable = available.data.bonusPlans.filter(bonus => !bonus.bonusPlanName.toLowerCase().includes(BONUS_PATTERN_SECOND) && !bonus.bonusPlanName.toLowerCase().includes(BONUS_PATTERN_THIRD));
          const parsedDepositBonus = getParseDepositBouns(filteredAvailable);

          return getBonusesCurrent(parsedDepositBonus, getBonusesDate);
        }
      }
      const parsedDeposit = getParseDepositBouns(available.data.bonusPlans);
      return {
        current: null,
        all: parsedDeposit,
      };
    }
  }
};

export const getPlayerOptInBonus = async (sessionKey, partyId) => {
  const optInBonus = {};

  const availableDepositBonus = await checkBonusPlanAvailable();
  const depositBonuses = await new Casino.models.Account().getEligibleOptInBonusPlans(sessionKey, partyId, 'DEPOSIT');

  const { optInBonusPlans, status } = depositBonuses.data;

  if (status === 'SUCCESS') {
    const parsedDepositBonus = getParseDepositBouns(optInBonusPlans);

    if (parsedDepositBonus.length > 0) {
      optInBonus.current = parsedDepositBonus[0];
    } else {
      optInBonus.current = null;
    }

    optInBonus.all = parsedDepositBonus;
    optInBonus.depositNr = availableDepositBonus;
  }
  return optInBonus;
};

export const getUserActiveBonuses = async (sessionKey, currency) => {
  let activeBonus = {};

  const account = new Casino.models.Account();
  const {
    data: { status, bonuses },
  } = await account.getActiveBonuses({ sessionKey, currency });

  activeBonus = status === 'SUCCESS' ? bonuses : activeBonus;

  // eslint-disable-next-line no-nested-ternary
  if (activeBonus.length > 0) {
    activeBonus = activeBonus.map(data => (
      { ...data, description: (typeof data.description === 'string') ? parseDescription(data.description) : data.description }
    ));

    activeBonus = {
      current: activeBonus[0],
      all: activeBonus,
    };
  }

  return activeBonus;
};

export const getUserAllBonuses = async () => {
  const state = {
    success: false,
  };

  if (isUserLoggedIn()) {
    const cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
    const { sessionKey } = cookieValues;

    //  Set OptInBonuses

    const optInBonus = await getBonuses(sessionKey);

    if (Object.keys(optInBonus).length === 0 || optInBonus.current === null) {
      const bonusPageDetails = await getBonusPageBySlug();
      return { ...state, bonusPageDetails };
    }

    if (optInBonus?.current?.bonusPlanId) {
      const bonusPageDetails = await getBonusPageBySlug(optInBonus.current);
      return {
        ...state, success: true, optInBonus, bonusPageDetails,
      };
    }
  }
  return state;
};

export const dataCache = new Map();

export const getBonusOffer = async (id) => {
  let userBonus = {
    success: false,
    bonusesByPlayerStatus: [],
    currentBonus: {
      data: {},
    },
    depositNr: null,
  };

  let depositNr = dataCache.get('depositNr');

  if (!depositNr) {
    depositNr = await checkBonusPlanAvailable();
    dataCache.set('depositNr', depositNr);
  }
  const { data } = await new Casino.FetchContent(`pages/${id}`).perform();

  userBonus.currentBonus = data.featured_promotion;

  if (data.id && (data.feature_articles_by_player_status.length > 0)) {
    userBonus = {
      ...userBonus,
      success: true,
      bonusesByPlayerStatus: data.feature_articles_by_player_status,
      depositNr,
      currentBonus: data.featured_promotion,
    };

    const addCurrentBonus = (bonusType) => {
      const bonus = data.feature_articles_by_player_status
        .find(status => status.player_status === bonusType);

      userBonus.currentBonus = bonus?.featured_article
        ? bonus.featured_article
        : data.featured_promotion;
    };

    if (isUserLoggedIn()) {
      switch (depositNr) {
        case 0:
          addCurrentBonus('NEW_PLAYER');
          break;
        case 1:
          addCurrentBonus('1ST_DEPOSIT');
          break;
        case 2:
          addCurrentBonus('2ND_DEPOSIT');
          break;
        default:
          userBonus.currentBonus = data.featured_promotion;
      }
    }
  }

  return { ...userBonus };
};

export const amlCheck = async (playerInfo) => {
  const playerDetails = {
    id: playerInfo.userId,
    given_name: playerInfo.firstName,
    surname: playerInfo.lastName,
    birthdate: playerInfo.birthDate,
    country: playerInfo.country,
    site_name: 'fruits4real',
  };

  await new Casino.AmlCheck(playerDetails).perform();
};

export const anyConsentToAccept = (user) => {
  const { tac, pp } = user || {};

  if (tac != null || pp != null) {
    return !tac || !pp;
  }

  return false;
};

export const urlCheck = (url) => {
  const homeUrl = `/${i18next.language}/`;
  const tempUrl = url.split('/');
  const pageType = tempUrl[2];

  return url === homeUrl || pageType === i18next.t('common:games_slug');
};

export const isInViewPort = (element, percentage = 0) => {
  if (!element) return;

  const DOMelement = element.getBoundingClientRect();
  const viewPortPercentage = (
    (window.innerHeight
    || document.documentElement.clientHeight) / 100
  ) * percentage;

  // eslint-disable-next-line consistent-return
  return (
    DOMelement.top >= 0
    && DOMelement.left >= 0
    && DOMelement.bottom <= (
      (window.innerHeight
      || document.documentElement.clientHeight) + viewPortPercentage
    )
    && DOMelement.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const lowerCaseAllWordsExceptFirstLetters = str => str.replace(/\S*/g, word => word.charAt(0) + word.slice(1).toLowerCase());

export function simpleFormatDate(date) {
  return String(date).substring(0, 10);
}

export const isHomePage = url => !url.split('/')[2]?.length;

export const isPhone = () => /Mobi/i.test(window.navigator.userAgent);

export const isRestrictedPlayer = () => new URLSearchParams(window.location.search).get('restricted');
