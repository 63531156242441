// eslint-disable-next-line import/prefer-default-export
export {
  setCategoriesAndProviders,
  initCategoriesAndProviders,
  updateGamesAndCategories,
  SET_CATEGORIES_AND_PROVIDERS,
} from './categoriesAndProviderActions';

export {
  setError,
  resetGames,
  resetDisplayCount,
  updateGames,
  setGamesByCategory,
  setGamesByCategories,
  setGamesBasedOnProvider,
  setActiveCategory,
  loadMore,
  loadMoreCMS,
  loadMoreFromCms,
  loadMoreSearchFromCms,
  setIsFetching,
  setSearchedGames,
  clearSearchedGames,
  setActiveGame,
  setGame,
  updateFavoriteGames,
  SET_ERROR,
  RESET_GAMES,
  RESET_DISPLAY_COUNT,
  UPDATE_GAMES,
  SET_GAMES_BY_CATEGORY,
  SET_GAMES_BY_PROVIDER,
  SET_ACTIVE_CATEGORY,
  LOAD_MORE,
  LOAD_MORE_FROM_CMS,
  SET_IS_FETCHING,
  SET_SEARCHED_GAMES,
  CLEAR_SEARCHED_GAMES,
  SET_GAME,
  SET_FAVORITE_GAMES,
} from './gamesAction';
