import {
  LOCALE_CHANGED,
} from '../actions/localesActions';

const initialState = {
  currentLocale: null,
  availableLocales: [],
  udpateDate: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOCALE_CHANGED:
      return {
        ...state,
        currentLocale: action.currentLocale,
        availableLocales: [...new Set(action.availableLocales)],
        udpateDate: undefined,
      };
    default:
      return state;
  }
}
