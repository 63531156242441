import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ErrorNotification = ({
  onClick, title, subtitle, description, errorMessages, isActive,
}) => {
  const { t } = useTranslation('buttons');
  return (
    <div className={`modal m-modal-base modal-confirm modal-fx-superScaled ${isActive ? 'is-active' : ''}`}>
      <div className="modal-card modal-content">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <h2 className="is-size-3 is-size-5-mobile has-text-primary has-text-centered">{title}</h2>
          </div>
          <button type="button" className="delete" aria-label="close" onClick={onClick} />
        </header>
        <section className="modal-card-body  is-active">
          <div className="notification is-danger has-text-white">
            <div className="media">
              <div className="media-content has-text-centered">
                <p className="has-text-white is-size-4">
                  {subtitle}
                </p>
                <p className="has-text-white is-size-8">
                  {description}
                </p>
                {errorMessages && (
                  <p className="has-text-white is-size-8">
                    <ul>
                      {errorMessages.map(error => (<li className="">{error}</li>))}
                    </ul>
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <div className="columns is-centered is-full-width">
            <div className="column is-4-tablet is-hidden-mobile">
              <button
                type="button"
                className="button button-modal-close has-background-light is-medium is-full-width"
                onClick={onClick}
              >
                &nbsp;&nbsp;
                {t('close')}
                &nbsp;&nbsp;
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

ErrorNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  errorMessages: PropTypes.instanceOf(Array),
  isActive: PropTypes.bool,
};

ErrorNotification.defaultProps = {
  isActive: true,
  description: '',
  title: '',
  subtitle: '',
  errorMessages: [],
};

export default ErrorNotification;
