const loginSuccessForPopups = (partyId, email) => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_POPUPS === 'true') {
    window.$solitics.loginSuccess({
      memberId: partyId,
      email,
      brand: process.env.REACT_APP_SOLITICS_BRAND_TOKEN,
      popupToken: process.env.REACT_APP_SOLITICS_POPUP_TOKEN,
      branch: process.env.REACT_APP_SOLITICS_BRANCH_NAME,
    });
  }
};

const soliticsOnLogoutPopus = () => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_POPUPS === 'true') {
    window.$solitics.onLogout();
    window.location.reload();
  }
};

const setExternalUserIdForPushNotification = (externalUserId) => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_PUSH_NOTIFICATION === 'true') {
    window.OneSignal.push(() => {
      window.OneSignal.setExternalUserId(externalUserId);
    });
  }
};

const removeExternalUserIdForPushNotification = () => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_PUSH_NOTIFICATION === 'true') {
    window.OneSignal.push(() => {
      window.OneSignal.removeExternalUserId();
    });
  }
};

export const soliticsOnLogout = () => {
  soliticsOnLogoutPopus();
  removeExternalUserIdForPushNotification();
};

export const soliticsPing = () => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_POPUPS === 'true') {
    window.$solitics.emit('ping', null, { page: 'current page' });
  }
};

export const soliticsLoginSuccess = (partyId, email) => {
  loginSuccessForPopups(partyId, email);
  setExternalUserIdForPushNotification(partyId);
};

export const soliticsInitPushNotification = () => {
  if (process.env.REACT_APP_ENABLE_SOLITICS_PUSH_NOTIFICATION === 'true') {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(() => {
      window.OneSignal.init({
        appId: process.env.REACT_APP_ENABLE_ONE_SIGNAL_NOTIFICATION,
        safari_web_id: process.env.REACT_APP_ENABLE_ONE_SIGNAL_WEB_SAFARI,
        notifyButton: {
          enable: true,
        },
      });
    });
  }
};
