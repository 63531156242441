import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes('/provider/') && !pathname.includes('/category/')) {
      window.scrollTo(0, 0);
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && (window.orientation === 90 && window.innerWidth < 846)) {
      configureAnchors({ offset: 1 });
      goToAnchor('gamesAnchor', false);
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && (window.orientation === 0 && window.innerWidth < 846)) {
      configureAnchors({ offset: -80 });
      goToAnchor('gamesAnchor', false);
    } else if ((pathname.includes('/provider/') || pathname.includes('/category/')) && (window.innerWidth > 846)) {
      configureAnchors({ offset: 1 });
      goToAnchor('gamesAnchor', false);
    }
  }, [pathname]);
  return null;
}
