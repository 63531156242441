import React, { useState } from 'react';

const PasswordField = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <input
        {...props}
        type={visible ? 'text' : 'password'}
        minLength="6"
        maxLength="16"
        passwordrules="required: upper; required: lower; required: digit; required: [-().&@?'#,/&quot;+]; max-consecutive: 2"
      />
      <span className="icon icon-visibility is-small is-right" onClick={() => setVisible(!visible)}>
        <i className={`fas ${visible ? 'fa-eye-slash' : 'fa-eye'}`} />
      </span>
    </>
  );
};

export default PasswordField;
