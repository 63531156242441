import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useQuery() {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const addQuery = React.useCallback((key, value) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(key, value);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  }, [history, pathname, search]);

  const removeQuery = React.useCallback((queryParam) => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete(queryParam);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  }, [history, pathname, search]);

  const replaceQuery = React.useCallback(
    (replacedQueryParam, key, value) => {
      const searchParams = new URLSearchParams(search);

      if (searchParams.has(replacedQueryParam)) {
        searchParams.delete(replacedQueryParam);
        searchParams.set(key, value);
      }

      history.push({
        pathname,
        search: searchParams.toString(),
      });
    },
    [history, pathname, search],
  );

  return {
    search,
    query,
    addQuery,
    removeQuery,
    replaceQuery,
  };
}

export default useQuery;
