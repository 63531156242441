import React, { Component, lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import Casino, { context } from '@dialinvest/react-casino';
import IdleTimer from 'react-idle-timer';
import { Cookies, CookiesProvider, withCookies } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import Router from './Router';
import ModalWrapper from '../../components/Modal/ModalWrapper';
import { config as i18nextConfig } from '../../translations';
import '../../styles/main.scss';
import TimeOut from './timeOut';
import {
  isUserLoggedIn,
  isPrerenderAgent,
  formatLangToIso,
  getQueryLocale,
  getSessionCookie,
  getPlayerInfo,
  isRestrictedPlayer,
} from '../../components/Helpers';
import { setUserData, setUserLoggedIn } from '../../redux/actions/userActions';
import { langChange } from '../../redux/actions/localesActions';
import PageLoader from '../../components/PageLoader';
import RestrictedCountry from '../../components/RestrictedCountry';
import PageTypeContextProvider from './PageTypeContextProvider';
import { soliticsInitPushNotification } from '../Solitics';
import { surveyConfig } from '../../components/Page/SourceOfWealth/surveyConfig';
import GamesInitializer from './GamesInitializer';
import { initMatamo } from './matomo';

const RestrictedWithdrawPage = lazy(() => import('../../components/Page/RestrictedWithdraw/RestrictedWithdraw'));

i18next.init(i18nextConfig);

export const VALID_OFFICE_COOKIE_NAME = 'HAS_VALID_OFFICE_IP';
class App extends Component {
  availableLocales = [];

  constructor(props) {
    super(props);
    const { cookies } = this.props;

    this.idleTimer = null;
    this.timeOut = new TimeOut(cookies);
    this.timeOutTime = Number(process.env.REACT_APP_TIMEOUT_MINUTES) * 60000 - 10000;
    this.pageType = null;
    this.state = {
      isLoading: true,
      languageDetected: false,
      restrictedCountry: false,
      userLanguage: null,
      gamesRefresh: false,
      isUserRestricted: isRestrictedPlayer(),
      user: {
        email: null,
        tac: false,
        pp: false,
        rec_emails: false,
        sessionKey: null,
        sourceOfWealth: null,
        kycStatus: null,
      },
    };
  }

  componentDidMount() {
    const { setUserLoggedInAs } = this.props;
    document.addEventListener('mousedown', this.handleClick, false);
    // eslint-disable-next-line global-require
    const detectBrowserLanguage = require('detect-browser-language');
    this.setState({ userLanguage: detectBrowserLanguage() });
    this.getPreSignupCheck();
    this.fetchLocales();
    setUserLoggedInAs(isUserLoggedIn());
    this.storeUserDataInContext();
    this.initReactGA();
    soliticsInitPushNotification();
    initMatamo();
  }

  // istanbul ignore next
  storeUserDataInContext = async () => {
    const { isUserRestricted } = this.state;
    const { storeUserData } = this.props;

    try {
      if (isUserLoggedIn() && !isUserRestricted) {
        const cookieDetails = getSessionCookie();
        const response = await getPlayerInfo();

        const sourceOfWealth = response?.data?.extraInfo?.find(
          item => item.key === surveyConfig.KEY,
        )?.value;

        const username = response?.data?.userId;
        const partyId = response?.data?.partyId;
        const kycStatus = response?.data?.kycStatus;
        const sessionKey = response?.data?.sessionKey;
        const email = response?.data?.email;

        const userObj = {
          user: {
            oldUserId: cookieDetails?.oldUserId,
            currency: cookieDetails?.currency,
            registrationStatus: cookieDetails?.registrationStatus,
            language: cookieDetails?.language,
            tac: cookieDetails?.tac,
            pp: cookieDetails?.pp,
            rec_emails: cookieDetails?.rec_emails,
            email,
            sessionKey,
            sourceOfWealth,
            username,
            partyId,
            kycStatus,
          },
        };

        this.setState(userObj);
        storeUserData(userObj.user);
      }
      this.initReactGA();
      soliticsInitPushNotification();
    } catch (error) {
      this.setState({ user: null });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  initReactGA = () => {
    ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS);
    ReactGA.send("pageview");
  };

  fetchLocales = async () => {
    const result = await new Casino.FetchContent('locales/').perform();
    this.availableLocales = result.items();
    this.detectLang();
  };

  getPreSignupCheck = async () => {
    const cookies = new Cookies();

    const isSuperUser = new URLSearchParams(window.location.search).get('superuser') === 'true';

    const hasValidIp = !!cookies.get(VALID_OFFICE_COOKIE_NAME);

    if (isSuperUser || hasValidIp) {
      try {
        const response = await fetch('https://api64.ipify.org').then(resp => resp.text());
        const validIp = response === process.env.REACT_APP_OFFICE_IP;
        if (validIp) {
          const expiresDate = new Date(Date.now() + (1000 * 60 * 60 * 9));
          cookies.set(VALID_OFFICE_COOKIE_NAME, 'true', { expires: expiresDate });
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (isPrerenderAgent()) {
      return;
    }

    const result = await new Casino.models.Signup().preSignupCheck();
    if (result.data.status === 'GEO_IP_FAILED') {
      this.setState({ restrictedCountry: true });
    }
  };

  onPageTypeChange = (pageType, prevPageType) => {
    if (pageType === this.pageType) return;

    if (['HomePage', 'GamePage'].includes(pageType) && prevPageType !== 'gamePage' && this.pageType !== null) {
      this.setState(prevState => ({
        ...prevState,
        gamesRefresh: !prevState.gamesRefresh,
      }));
    }

    this.pageType = pageType;
  };

  detectLang = async () => {
    let lang = i18next.language;

    const { locales } = this.props;
    const { onLangChange } = this.props;
    const { userLanguage } = this.state;
    const storageLanguage = localStorage.getItem('browserLanguage');

    if (userLanguage !== null && storageLanguage === null) {
      // eslint-disable-next-line max-len
      const browserLanguage = this.availableLocales
        .filter(items => items.iso === formatLangToIso(userLanguage))
        .map(item => item.locale);
      /* istanbul ignore next */
      if (browserLanguage[0] !== null && browserLanguage[0] !== undefined) {
        lang = browserLanguage[0];
        localStorage.setItem('browserLanguage', lang);
      } else {
        lang = 'en-int';
        localStorage.setItem('browserLanguage', lang);
      }
    }

    if (locales.currentLocale !== null && storageLanguage !== null) {
      lang = locales.currentLocale;
    }

    const pathArray = window.location.pathname.split('/');

    if (pathArray[1] !== '' && this.isAvailableLang(pathArray[1])) {
      lang = pathArray[1];
    } else if (getQueryLocale() && this.isAvailableLang(getQueryLocale())) {
      lang = getQueryLocale();
    }

    onLangChange(lang, this.availableLocales);

    i18next.changeLanguage(lang);
    new Casino.models.Locales().changeLanguage(lang);

    this.setState({ languageDetected: true, userLanguage: null });
  };

  isAvailableLang = (lang) => {
    const index = this.availableLocales.findIndex(p => p.locale === lang);

    return index > -1;
  };

  onUserAction = () => {
    if (!this.isLoggedIn()) return false;
    this.timeOut.extendCookie();

    return true;
  };

  onIdle = () => {
    if (!this.isLoggedIn() || this.pageType === 'gamePage') return false;
    this.timeOut.timeOut();

    return true;
  };

  isLoggedIn = () => {
    const { cookies } = this.props;

    return typeof cookies.get('Session') !== 'undefined';
  };

  render() {
    const {
      restrictedCountry,
      user,
      isLoading,
      isUserRestricted,
    } = this.state;
    // eslint-disable-next-line max-len
    if (restrictedCountry) {
      return (
        <I18nextProvider i18n={i18next}>
          <RestrictedCountry />
        </I18nextProvider>
      );
    }

    if (isLoading) return <PageLoader />;

    if (isUserRestricted) {
      return (
        <Suspense fallback={<PageLoader />}>
          <BrowserRouter>
            <CookiesProvider>
              <PageTypeContextProvider onPageTypeChange={this.onPageTypeChange}>
                <I18nextProvider i18n={i18next}>
                  <RestrictedWithdrawPage />
                </I18nextProvider>
              </PageTypeContextProvider>
            </CookiesProvider>
          </BrowserRouter>
        </Suspense>
      );
    }

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          onAction={this.onUserAction}
          debounce={250}
          timeout={this.timeOutTime}
          events={['mousedown', 'touchstart']}
        />

        <CookiesProvider>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <PageTypeContextProvider onPageTypeChange={this.onPageTypeChange}>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                  <context.UserContextProvider user={user}>
                    <GamesInitializer />
                    <ModalWrapper>
                      <Router />
                    </ModalWrapper>
                  </context.UserContextProvider>
                </GoogleReCaptchaProvider>
              </PageTypeContextProvider>
            </I18nextProvider>
          </BrowserRouter>
        </CookiesProvider>
      </>
    );
  }
}

App.propTypes = {
  cookies: PropTypes.instanceOf(Object).isRequired,
  locales: PropTypes.instanceOf(Object).isRequired,
  onLangChange: PropTypes.func.isRequired,
  setUserLoggedInAs: PropTypes.func.isRequired,
  storeUserData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locales: state.locales,
});

export const mapDispatchToProps = dispatch => ({
  onLangChange: (currentLocale, availableLocales) => dispatch(
    langChange(currentLocale, availableLocales),
  ),
  setUserLoggedInAs: newState => dispatch(setUserLoggedIn(newState)),
  storeUserData: payload => dispatch(setUserData(payload)),
});

export default compose(
  hot(module),
  connect(mapStateToProps, mapDispatchToProps),
  withCookies,
)(App);
