const currencyMark = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  CAD: '$',
  NOK: 'Kr',
  AUD: '$',
  SEK: 'Kr',
  HUF: 'Ft',
  NZD: 'NZ$',
  ZAR: 'R',
  CHF: 'Fr',
  PEN: 'S/',
  PLN: 'zł',
  BRL: 'R$',
  MXN: 'Mex$',
};

export default currencyMark;

export const currencyAmount = {
  EUR: '30',
  USD: '30',
  CAD: '30',
  AUD: '30',
  NZD: '30',
  CHF: '30',
  PEN: '100',
  PLN: '100',
  BRL: '150',
  NOK: '300',
  ZAR: '500',
  MXN: '700',
};

export const currencyPredefinedAmounts = {
  EUR: '30,100,500',
  USD: '30,100,500',
  CAD: '30,100,500',
  AUD: '30,100,500',
  NZD: '30,100,500',
  CHF: '30,100,500',
  PEN: '100,400,2000',
  PLN: '100,400,2000',
  BRL: '150,500,3000',
  NOK: '300,1000,5000',
  ZAR: '500,2000,8000',
  MXN: '700,2000,12000',
};
