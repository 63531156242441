import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initCategoriesAndProviders, resetGames } from '../../redux/actions/gamesActions';

function useCategoriesAndProviders(search) {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(state => state.userState.userLoggedIn);

  const params = new URLSearchParams(search);

  const countryPreview = params.get('country_preview')
    ? params.get('country_preview').toUpperCase()
    : '';

  React.useEffect(() => {
    if (!userLoggedIn) dispatch(resetGames());
    dispatch(initCategoriesAndProviders(countryPreview));
  }, [dispatch, countryPreview, userLoggedIn]);

  return null;
}

export default useCategoriesAndProviders;
