import React from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import PageTitle from '../PageTitle';
import Banner from '../../Layout/Banner';

const TextPage = ({ data }) => (
  <BasePage data={data}>
    <div className="has-background-dark main-content-parent">
      <Banner data={data} />
      <section className="section m-page-wrapper">
        <div className="container is-fluid">
          <div className="columns is-centered is-mobile">
            <div className="column is-12-mobile is-12-tablet is-9-widescreen m-page-width-wrapper">
              <div className="content typography">
                <PageTitle title={data.title} show={data.meta.show_title} />
                <div className="content typography has-link-underline" dangerouslySetInnerHTML={{ __html: data.body }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </BasePage>
);


TextPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      show_title: PropTypes.bool,
    }),
  }),
};

TextPage.defaultProps = {
  data: {
    meta: {
      show_title: true,
    },
  },
};

export default TextPage;
