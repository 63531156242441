import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import PageTitle from '../PageTitle';
import Banner from '../../Layout/Banner';

class SocialPage extends PureComponent {
  render() {
    const { data } = this.props;

    return (
      <BasePage data={data}>
        <Banner data={data} />
        <section className="section m-page-wrapper">
          <div className="container is-fluid">
            <div className="columns is-multiline is-centered">
              <div className="column is-12-mobile is-12-tablet is-9-widescreen">
                <div className="content">
                  <PageTitle title={data.title} show={data.meta.show_title} />
                  <p><strong className="has-text-success">{ data.sub_title }</strong></p>
                  {/* eslint-disable-next-line react/no-danger */}
                  <p dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>
              <br />
              <div className="column is-5 is-7-tablet">
                <div className="columns is-multiline is-centered is-mobile m-social">
                  { data.sections.map(item => (
                    <div key={item.title} className="column is-4">
                      <a href={item.button_external_link} rel="noopener noreferrer" target="_blank">
                        <img className="social-image" src={item.image.url} alt={item.title} />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </BasePage>
    );
  }
}

SocialPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    sections: PropTypes.instanceOf(Array).isRequired,
    meta: PropTypes.instanceOf(Object),
  }),
};

SocialPage.defaultProps = {
  data: {
    meta: {
      show_title: true,
    },
  },
};

export default SocialPage;
