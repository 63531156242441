import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  otherComponent,
  ...rest
}) => {
  const userLoggedIn = useSelector(state => state.userState.userLoggedIn);

  if (!userLoggedIn) {
    return <Redirect to="/" />;
  }

  if (otherComponent) {
    return (
      <Route {...rest} render={() => <Layout>{otherComponent}</Layout>} />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  layout: PropTypes.instanceOf(Object).isRequired,
  otherComponent: PropTypes.node,
};

ProtectedRoute.defaultProps = {
  otherComponent: null,
};

export default ProtectedRoute;
