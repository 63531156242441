import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import ErrorNotification from '../Notifications/error';
import Signup from '.';

class SignupDispatcher extends PureComponent {
  REQUEST_SUCCESS = 'SUCCESS';

  constructor(props) {
    super(props);
    this.signup = new Casino.models.Signup();
    this.state = {
      errorMessage: false,
      showModal: false,
      requested: false,
    };
  }

  closeButton = () => {
    const { props } = this;
    props.onToggleModal();
  };

  preSignupCheck() {
    this.setState({ requested: true });
    this.signup.preSignupCheck().then((result) => {
      if (result.data.status === this.REQUEST_SUCCESS) {
        this.setState({ showModal: true, errorMessage: false });
      } else {
        this.setState({ errorMessage: true, showModal: false });
      }
    }).catch(() => this.setState({ showModal: true, errorMessage: false }));
  }

  renderItem() {
    const { errorMessage, showModal } = this.state;
    let renderedItem = '';
    if (showModal === true) {
      renderedItem = <Signup />;
    } else if (errorMessage === true) {
      const { t } = this.props;
      renderedItem = (
        <ErrorNotification
          onClick={this.closeButton}
          title={t('geo_ip_failed_title')}
          subtitle={t('geo_ip_failed_subtitle')}
          description={t('geo_ip_failed_description')}
        />
      );
    }
    return renderedItem;
  }

  render() {
    const { isOpen } = this.props;

    if (!isOpen) {
      return '';
    }

    const { requested } = this.state;
    if (!requested) {
      this.preSignupCheck();
    }

    const renderedItem = this.renderItem();

    return renderedItem;
  }
}

SignupDispatcher.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.signUpModal?.isOpen,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModal: () => dispatch({ type: 'TOGGLE_MODAL' }),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('errors'),
)(SignupDispatcher);
