export const surveyConfig = {
  DELAY: 3000, // 3seconds
  KEY: 'SourceOfWealth',
  FILLED_MESSAGE: 'REVIEW',
  FILLED_MESSAGE_BLOCKED: 'REVIEW_BLOCKED',
  REQUIRED: 'REQUIRED',
  REQUIRED_BLOCKED: 'REQUIRED_BLOCKED',
  DEFAULT_QUESTIONS: 'DEFAULT_QUESTIONS',
  INITIAL_QUESTIONS: ['REQUIRED', 'REQUIRED_BLOCKED'],
  PROOF_QUESTIONS: [
    'PROOF_OF_INCOME_EMPLOYMENT',
    'PROOF_OF_INCOME_PENSION',
    'PROOF_OF_INCOME_RENTAL',
    'PROOF_OF_INCOME_DIVIDEND',
    'PROOF_OF_INCOME_ROYALTIES',
    'PROOF_OF_INCOME_FAMILY_SUPPORT',
    'PROOF_OF_INCOME_OTHER',
  ],
  NEED_TO_REDIRECT: [
    // These statuses redirects to the Source of Wealth page
    'REQUIRED',
    'REQUIRED_BLOCKED',
    'PROOF_EMPLOYMENT',
    'PROOF_EMPLOYMENT_BLOCKED',
    'PROOF_PENSION',
    'PROOF_PENSION_BLOCKED',
    'PROOF_RENTAL',
    'PROOF_RENTAL_BLOCKED',
    'PROOF_DIVIDEND',
    'PROOF_DIVIDEND_BLOCKED',
    'PROOF_ROYALTIES',
    'PROOF_ROYALTIES_BLOCKED',
    'PROOF_FAMILY_SUPPORT',
    'PROOF_FAMILY_SUPPORT_BLOCKED',
    'PROOF_OTHER',
    'PROOF_OTHER_BLOCKED',
    'REVIEW_BLOCKED',
  ],
  SUCCES_FILE_UPLOAD_MESSAGE: 'File has been uploaded',
};

const surveyProofBlockedTypes = surveyConfig.PROOF_QUESTIONS
  .map(item => `${item}_BLOCKED`);

export const surveyProofTypes = surveyConfig.PROOF_QUESTIONS.concat(surveyProofBlockedTypes)
  .reduce((arr, item) => ({ ...arr, [item.slice(0, 5) + item.slice(15)]: item.includes('BLOCKED') ? item.slice(0, -8) : item }), {});

export const fieldTypes = {
  radio: 'radiobutton',
  checkBox: 'checkbox',
  checkBoxWithEditBox: 'checkbox+editbox',
  editBox: 'editbox',
  fileUpload: 'file',
  list: 'list',
};
