import React from 'react';
import PropTypes from 'prop-types';

const PromotionHeader = ({ hiddenHero, children }) => (
  <div className={`columns is-multiline is-mobile m-promo-columns${hiddenHero ? ' is-hidden-touch' : ''}`}>
    { children }
  </div>
);

PromotionHeader.propTypes = {
  children: PropTypes.instanceOf(Object),
  hiddenHero: PropTypes.bool,
};

PromotionHeader.defaultProps = {
  children: [],
  hiddenHero: false,
};

export default PromotionHeader;
