import React from 'react';
import PropTypes from 'prop-types';

export const RenderField = ({
  name,
  icon,
  label,
  input,
  placeholder,
  meta: {
    touched,
    error,
    active,
  },
}) => (
  <label htmlFor={name} id={`${name}_label`} className="label">
    {label}
    <div className="control has-icons-left">
      <input
        {...input}
        className={`input is-medium ${(active || touched) && error ? ' is-danger' : ''}`}
        required="required"
        type="text"
        placeholder={placeholder}
      />
      <span className="icon is-small is-left">
        <i className={`fas ${icon}`} />
      </span>
    </div>
    {(active || touched) && ((error && <span><p className="help is-danger">{error}</p></span>))}
  </label>
);

RenderField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
};

RenderField.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
  meta: {
    error: undefined,
  },
};
