import React from 'react';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';
import useQuery from '../useQuery';

function useGamesAnchor() {
  const { query } = useQuery();

  const category = query.get('category');
  const provider = query.get('provider');
  const studio = query.get('studio');
  const search = query.get('search');

  React.useEffect(() => {
    if (category || provider || studio || search) {
      let offset;

      if (window.orientation === 90 && window.innerWidth < 846) offset = 1;
      if (window.orientation === 0 && window.innerWidth < 846) offset = -80;
      if (window.innerWidth > 846) offset = 1;

      configureAnchors({ offset });
      goToAnchor('gamesAnchor', false);
    }
  }, [query, category, provider, studio, search]);

  return null;
}

export default useGamesAnchor;
