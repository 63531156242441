import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from './BackgroundImage';

const Banner = ({ data }) => (
  <>
    {
      data.banner_video
      && (
        <div className="carousel-video m-promo-video-area">
          <video muted loop autoPlay playsInline>
            <source src={data.banner_video.url} type="video/mp4" />
          </video>
        </div>
      )
    }
    {
      data.banner && !data.banner_video
      && (
        <BackgroundImage src={data.banner.meta.download_url} elementClass="carousel m-carousel m-carousel-accounts">
          <div className="container is-fluid" />
        </BackgroundImage>
      )
    }
  </>
);

Banner.propTypes = {
  data: PropTypes.instanceOf(Object),
};

Banner.defaultProps = {
  data: {},
};

export default Banner;
