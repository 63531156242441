import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Page404 = ({ onClick }) => {
  const { t } = useTranslation('fields');

  return (
    <>
      <section className="section has-background-dark is-viewport-height is-flex">
        <div className="container is-fluid">
          <div className="columns is-centered">
            <div className="column is-6-widescreen is-12 has-text-centered">
              <div className="card has-background-black has-border-radius-8">
                <div className="card-content has-text-centered has-text-white modal-confirm">
                  <img width="150" src={`${process.env.PUBLIC_URL}/images/F4R-preloader.png`} alt="Fruits4Real" />
                  <br />
                  <br />
                  <i className="fas fa-exclamation-circle fa-3x" />

                  <div className="box has-background-mid-gray has-text-white">
                    <div className="media">
                      <div className="media-content has-text-centered content">
                        <p>
                          <strong className="has-text-white is-size-4">
                            {t('common:404_body_1')}
                          </strong>
                          <br />
                          <br />
                          <span dangerouslySetInnerHTML={{ __html: t('common:404_body_2') }} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="card-content container">
                    <div className="columns is-centered">
                      <div className="column is-6">
                        <a
                          id="return"
                          href="/#"
                          className="button is-large is-success"
                          onClick={e => onClick(e)}
                        >
                          <i className="fas fa-angle-left" />
                        &nbsp;&nbsp;
                          {t('buttons:home')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Page404.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Page404;
