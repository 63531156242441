import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import { toggleModalVipApply } from '../../../redux/actions/vipApplyModalActions';
import { clipHTMLBody } from '../../Helpers';

export class VipApply extends PureComponent {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  toggleModal = (e) => {
    e.preventDefault();
    const { props } = this;
    props.toggleModalVipApply();
    clipHTMLBody();
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      // Do whatever when esc is pressed
      props.toggleModalVipApply();
      clipHTMLBody();
    }
  };

  render() {
    const { t, isOpen, message } = this.props;

    return (
      <div id="modal-vip-apply" className={`modal m-modal-base modal-fx-superScaled ${isOpen ? 'is-active' : 'is-inactive'}`}>
        <div className="modal-background" />
        <div className="modal-content-wrapper">
          <div className="modal-card modal-content">
            <header className="modal-card-head">
              <div className="modal-card-title">
                <h2 className="is-size-3 is-size-5-mobile has-text-primary has-text-centered">
                  {t('common:vip_apply_title')}
                </h2>
              </div>
              <button
                id="delete"
                type="button"
                className="delete"
                aria-label="close"
                onClick={e => this.toggleModal(e)}
              />
            </header>
            <section className="modal-card-body">
              <div className="notification is-success has-text-white">
                <div className="media">
                  <div className="media-content has-text-centered">
                    <i className="fas fa-check-circle fa-3x" />
                    <strong className={`has-text-white is-size-4 is-size-5-mobile ${message ? 'applied' : ''}`}>
                      {message ? t('common:vip_already_applied_body') : t('common:vip_apply_body_1')}
                    </strong>
                    {t('common:vip_apply_body_2')}
                  </div>
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <div className="columns is-centered is-full-width">
                <div className="column is-4-tablet is-hidden-mobile">
                  <button
                    id="close"
                    type="button"
                    className="button button-modal-close has-background-light is-medium is-full-width"
                    onClick={e => this.toggleModal(e)}
                  >
                    <i className="icon-close" />
                    &nbsp;&nbsp;
                    {t('buttons:close')}
                    &nbsp;&nbsp;
                  </button>
                </div>

              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

VipApply.propTypes = {
  toggleModalVipApply: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.vipApplyModal?.isOpen,
  message: state.vipApplyModal?.message,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalVipApply: () => dispatch(toggleModalVipApply()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(VipApply);
