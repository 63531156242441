import accountEn from './en/account.json';
import buttonsEn from './en/buttons.json';
import commonEn from './en/common.json';
import errorsEn from './en/errors.json';
import fieldsEn from './en/fields.json';
import currenciesEn from './en/currencies.json';
import languagesEn from './en/languages.json';
import securityQuestionsEn from './en/securityQuestions.json';
import accountDe from './de/account.json';
import buttonsDe from './de/buttons.json';
import commonDe from './de/common.json';
import errorsDe from './de/errors.json';
import fieldsDe from './de/fields.json';
import currenciesDe from './de/currencies.json';
import languagesDe from './de/languages.json';
import securityQuestionsDe from './de/securityQuestions.json';
import accountFr from './fr/account.json';
import buttonsFr from './fr/buttons.json';
import commonFr from './fr/common.json';
import errorsFr from './fr/errors.json';
import fieldsFr from './fr/fields.json';
import currenciesFr from './fr/currencies.json';
import languagesFr from './fr/languages.json';
import securityQuestionsFr from './fr/securityQuestions.json';
import accountEs from './es/account.json';
import buttonsEs from './es/buttons.json';
import commonEs from './es/common.json';
import errorsEs from './es/errors.json';
import fieldsEs from './es/fields.json';
import currenciesEs from './es/currencies.json';
import languagesEs from './es/languages.json';
import securityQuestionsEs from './es/securityQuestions.json';
import accountFi from './fi/account.json';
import buttonsFi from './fi/buttons.json';
import commonFi from './fi/common.json';
import errorsFi from './fi/errors.json';
import fieldsFi from './fi/fields.json';
import currenciesFi from './fi/currencies.json';
import languagesFi from './fi/languages.json';
import securityQuestionsFi from './fi/securityQuestions.json';

export default {
  en: {
    account: accountEn,
    buttons: buttonsEn,
    common: commonEn,
    errors: errorsEn,
    fields: fieldsEn,
    currencies: currenciesEn,
    languages: languagesEn,
    security_questions: securityQuestionsEn,
  },
  de: {
    account: accountDe,
    buttons: buttonsDe,
    common: commonDe,
    errors: errorsDe,
    fields: fieldsDe,
    currencies: currenciesDe,
    languages: languagesDe,
    security_questions: securityQuestionsDe,
  },
  fr: {
    account: accountFr,
    buttons: buttonsFr,
    common: commonFr,
    errors: errorsFr,
    fields: fieldsFr,
    currencies: currenciesFr,
    languages: languagesFr,
    security_questions: securityQuestionsFr,
  },
  es: {
    account: accountEs,
    buttons: buttonsEs,
    common: commonEs,
    errors: errorsEs,
    fields: fieldsEs,
    currencies: currenciesEs,
    languages: languagesEs,
    security_questions: securityQuestionsEs,
  },
  fi: {
    account: accountFi,
    buttons: buttonsFi,
    common: commonFi,
    errors: errorsFi,
    fields: fieldsFi,
    currencies: currenciesFi,
    languages: languagesFi,
    security_questions: securityQuestionsFi,
  },
};
