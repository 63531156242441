import React from 'react';
import PasswordField from './PasswordField';

const BaseInput = (props) => {
  const {
    valid,
    touched,
    validation,
    errorDescription,
    description,
    config,
    value,
    onChange,
    onlineValid,
    corrected,
    correctedMessage,
  } = props;

  const inputIsSuccess = 'is-success';
  const inputIsDanger = 'is-danger';
  const inputClasses = ['input', 'is-medium'];
  const errorDescriptionLabel = errorDescription || 'This field is invalid';
  const iconIsInProgress = 'fas fa-spinner fa-spin';
  const iconIsSuccess = 'fas fa-check has-text-success';
  const iconIsDanger = 'fas fa-exclamation-triangle has-text-danger';

  let rightIcon;
  let warningMessage;
  let patternAsString;

  function patternToString(pattern) {
    if (pattern) {
      patternAsString = pattern.toString().substr(1);

      return patternAsString.substring(0, patternAsString.length - 1);
    }
  }

  if (!valid && touched && validation.required) {
    inputClasses.push(inputIsDanger);
    rightIcon = iconIsDanger;
    warningMessage = <p className="help is-danger">{errorDescriptionLabel}</p>;
  } else if (valid && touched && validation.required && !onlineValid && validation.inline) {
    rightIcon = iconIsInProgress;
  } else if (valid && touched && validation.required) {
    inputClasses.push(inputIsSuccess);
    rightIcon = iconIsSuccess;
  }

  // Prepared correct regex for HTML5
  patternAsString = patternToString(validation.pattern);

  let input = (
    <input
      className={inputClasses.join(' ')}
      type={config.type}
      value={value}
      onChange={onChange}
    />
  );

  const isPassword = config.type === 'password';

  const correctedMessageContainer = (
    <p className="help is-success">
      {correctedMessage}
    </p>
  );

  if (isPassword) {
    input = (
      <PasswordField
        className={inputClasses.join(' ')}
        type={config.type}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    // const { description, validation, config, value, onChange } = props;
    <div className="column is-6 field">
      <label className="label">{description}</label>
      <div className={`control has-icons-left has-icons-right${isPassword ? ' custom-password-field multiple-icons' : ''}`}>
        {input}
        <span className="icon is-small is-left">
          <i className={config.leftIcon} />
        </span>
        <span className="icon is-small is-right">
          <i className={rightIcon} />
        </span>
      </div>
      {corrected && correctedMessageContainer}
      {warningMessage}
    </div>
  );
};

export default BaseInput;
