import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { reset } from 'redux-form';
import store from '../../../containers/App/store';
import PasswordResetRequestModal from './PasswordResetRequestModal';
import { clipHTMLBody } from '../../Helpers';

class PasswordResetRequest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email_address: '',
      secret_question: '',
      secret_answer: '',
      message: '',
      success: '',
      loading: false,
    };
  }

  handleEmailChange = (event) => {
    this.setState({ email_address: event.target.value });
  };

  handleQuestionChange = (event) => {
    this.setState({ secret_question: event.target.value });
  };

  handleAnswerChange = (event) => {
    this.setState({ secret_answer: event.target.value });
  };

  toggleModal = () => {
    const { props, state } = this;
    store.dispatch(reset('resetRequestForm'));
    this.setState({ ...state, message: '' });
    props.toggleModalResetRequest();
    clipHTMLBody();
  };

  submitPasswordReset = async () => {
    const { state } = this;

    const parameters = {
      email: state.email_address,
      secretQuestion: state.secret_question,
      secretAnswer: state.secret_answer,
    };
    this.setState({ loading: true }, async () => {
      const result = await new Casino.ForgotPasswordStep1And2(parameters).perform();
      this.setState({ loading: false }, () => {
        this.setState({
          success: result.success(),
          message: result.message(),
        });
      });
    });
  };

  render() {
    const { isOpen, needSecurityQuestion } = this.props;
    const { message, success, loading } = this.state;

    return (
      <PasswordResetRequestModal
        isOpen={isOpen}
        message={message}
        success={success}
        submitPasswordReset={this.submitPasswordReset}
        handleEmailChange={this.handleEmailChange}
        handleQuestionChange={this.handleQuestionChange}
        handleAnswerChange={this.handleAnswerChange}
        toggleModal={this.toggleModal}
        needSecurityQuestion={needSecurityQuestion}
        loading={loading}
      />
    );
  }
}

PasswordResetRequest.propTypes = {
  toggleModalResetRequest: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  needSecurityQuestion: PropTypes.bool,
};

PasswordResetRequest.defaultProps = {
  needSecurityQuestion: false,
};

export const mapStateToProps = state => ({
  isOpen: state.passwordResetRequestModal?.isOpen,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalResetRequest: () => dispatch({ type: 'TOGGLE_MODAL_RESET_REQUEST' }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PasswordResetRequest);
