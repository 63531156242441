import React from 'react';
import { useTranslation } from 'react-i18next';
import { blog } from '@dialinvest/react-casino';
import BlogPage from './BlogPage';
import PageLoader from '../../PageLoader';

const BlogCategoryWrapper = () => {
  const { i18n } = useTranslation();
  return (
    <blog.BlogFetch lang={i18n.language}>
      {({ data }) => {
        if (data === null) return <PageLoader />;
        return <BlogPage data={data} />;
      }}
    </blog.BlogFetch>
  );
};

export default BlogCategoryWrapper;
