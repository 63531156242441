import React from 'react';
import useActiveGames from '../../../hooks/games/useActiveGames';
import useGames from '../../../hooks/games/useGames';
import GameThumbnailList from '../GameThumbnailList';
import { categoryFilter, providerFilter } from './FilteredGames';

function FilteredGamesFromCms() {
  const { gamesByCategories, filtered } = useGames();
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();

  const category = gamesByCategories[activeCategory?.id];
  const provider = gamesByCategories[activeCategory?.id];

  const categoryToShow = category?.id ? category : provider;

  const games = filtered?.games?.filter(game => providerFilter(
    game,
    activeProvider?.id || activeStudio?.id,
    activeProvider?.type || activeStudio?.type,
  ))
    .filter(game => categoryFilter(game, activeCategory?.id));

  const filteredCategory = {
    ...filtered,
    name: categoryToShow?.name,
    games,
  };

  return (
    <>
      {!filtered.isLoading && (
        <GameThumbnailList
          category={filteredCategory}
          maxLimit={filteredCategory?.displayGamesCount}
        />
      )}
    </>
  );
}

export default FilteredGamesFromCms;
