import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import WidgetThumbnail from '../WidgetThumbnail';
import {
  formatLocalizedDate,
  getPathname,
  stripHtml,
  truncate,
} from '../../Helpers';

const PromotionWidgetItem = ({ data, label }) => {
  const excerpt = data.excerpt
    ? stripHtml(data.excerpt)
    : truncate(stripHtml(data.body), 20);

  return (
    <div className="m-promo-calendar-widget-item column is-12-mobile is-6-tablet is-12-desktop">
      {label && (
        <div className="card-content">
          <h5 className="has-text-white widget-label">{label}</h5>
        </div>
      )}
      <NavLink
        title={data.title}
        to={getPathname(data.meta.html_url)}
        className="level is-mobile m-promo-widget-item"
      >
        <WidgetThumbnail alt={data.title} thumbnail={data.thumbnail} />
        <div className="level-item">
          <div className="m-promo-widget-item-text has-text-right">
            <div className="is-size-7 has-text-white item-date">
              {formatLocalizedDate(data.valid_from, 'D MMM YYYY')}
            </div>
            <h3 className="has-text-white m-promo-widget-title">{excerpt}</h3>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

PromotionWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
};

PromotionWidgetItem.defaultProps = {
  label: null,
};

export default PromotionWidgetItem;
