const SignupState = {
  first: 1,
  second: 2,
  third: 3,
  loading: 4,
  fourth: 5,
  error: 6,
};

export const SignupStateString = {
  1: 'accountDetails',
  2: 'personalDetails',
  3: 'verified',
};

export const previousState = (oldState) => {
  let newState = oldState;
  if (oldState === SignupState.second) {
    newState = SignupState.first;
  } else if (oldState === SignupState.third) {
    newState = SignupState.second;
  } else if (oldState === SignupState.fourth) {
    newState = SignupState.third;
  }

  return newState;
};

export default SignupState;
