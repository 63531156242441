import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import Flickity from 'react-flickity-component';
import Casino from '@dialinvest/react-casino';
import { toggleModalLoyaltyShop } from '../../../redux/actions/loyaltyShopActions';
import { getSession } from '../../Helpers';
import CarouselCell from './CarouselCell';
import SuccessContent from './SuccessContent';
import { balanceRefresh } from '../../../redux/actions/balanceActions';

class LoyaltyShop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      errorMessage: '',
      currentItemCode: '',
    };
    this.cookies = new Cookies();
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.escFunction, false);
  };

  toggleModal = (e) => {
    e.preventDefault();
    const { props } = this;
    this.setState({ success: false, currentItemCode: '' });
    props.toggleModalLoyaltyShop();
  };

  buyItem = async (itemPrice, itemCode) => {
    const { sessionKey } = getSession();
    const { refreshBalance } = this.props;

    const parameters = {
      sessionKey,
      itemCode,
      price: itemPrice,
    };

    this.setState({ loading: true }, async () => {
      const result = await new Casino.models.Account().loyaltyShopPurchaseItem(parameters);
      this.setState({ loading: false }, () => {
        if (result.success()) {
          refreshBalance();
          this.setState({
            success: true, currentItemCode: itemCode, error: false, errorMessage: '',
          });
        } else {
          this.setState({
            // eslint-disable-next-line max-len
            success: true, currentItemCode: itemCode, error: true, errorMessage: result.message(),
          });
        }
      });
    });
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      this.setState({ success: false, currentItemCode: '' });
      // Do whatever when esc is pressed
      props.toggleModalLoyaltyShop();
    }
  };

  myCustomNext = () => {
    /* istanbul ignore next */
    this.flkty.next(true);
  };

  myCustomPrev = () => {
    /* istanbul ignore next */
    this.flkty.previous(true);
  };

  render() {
    const {
      t, isOpen, itemCode, loyaltyList, i18n,
    } = this.props;

    const {
      loading,
      success,
      error,
      errorMessage,
      currentItemCode,
    } = this.state;
    let initialIndex = 0;
    if (loyaltyList !== undefined) {
      // eslint-disable-next-line react/prop-types
      initialIndex = loyaltyList.findIndex(item => item.itemCode === itemCode);
    }

    const flickityOptions = {
      lazyload: true,
      pageDots: false,
      prevNextButtons: false,
      fade: false,
      autoPlay: false,
      adaptiveHeight: false,
      imagesLoaded: true,
      pauseOnHover: false,
      initialIndex,
    };

    const flickityContent = () => (
      <>
        <div className="m-loyalty-modal-nav">
          <button
            type="button"
            id="js-carousel-loyalty-status-nav-left"
            className="m-loyalty-modal-nav-arrow-left"
            style={{ margin: 'unset' }}
            onClick={this.myCustomPrev}
          >
            <img src={`${process.env.PUBLIC_URL}/images/loyalty/carousel-arrow.svg`} alt="" />
          </button>
          <button
            type="button"
            id="js-carousel-loyalty-status-nav-right"
            className="m-loyalty-modal-nav-arrow-right"
            onClick={this.myCustomNext}
          >
            <img src={`${process.env.PUBLIC_URL}/images/loyalty/carousel-arrow.svg`} alt="" />
          </button>
        </div>
        <Flickity
          /* eslint-disable-next-line no-return-assign */
          flickityRef={c => this.flkty = c}
          className="js-carousel-slide"
          options={flickityOptions}
        >
          {(loyaltyList !== undefined) && loyaltyList.map(item => (
            <CarouselCell
              t={t}
              item={item}
              onClick={this.toggleModal}
              onClickBuy={this.buyItem}
              loading={loading}
              i18n={i18n}
            />
          ))}
        </Flickity>
      </>
    );

    const successContent = () => (
      <>
        <div id="carousel-loyalty" className="js-carousel-slide">
          {/* eslint-disable-next-line max-len */}
          {(loyaltyList !== undefined) && loyaltyList.filter(items => items.itemCode === currentItemCode).map(item => (
            <SuccessContent
              t={t}
              item={item}
              error={error}
              errorMessage={errorMessage}
              onClick={this.toggleModal}
              i18n={i18n}
            />
          ))}
        </div>
      </>
    );

    return (
      <>
        <div id="modal-loyalty" style={{ justifyContent: 'normal' }} className={`modal m-modal-base modal-confirm m-modal-fixed modal-fx-superScaled ${isOpen ? 'is-active' : ''}`}>
          <div className="modal-background" />
          <div className="modal-card modal-content">
            <div className="box m-loyalty-modal">
              <button
                id="delete"
                type="button"
                className="modal-close button-modal-close is-large"
                aria-label="close"
                onClick={this.toggleModal}
              />
              {success
                ? successContent()
                : flickityContent()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

LoyaltyShop.propTypes = {
  toggleModalLoyaltyShop: PropTypes.func.isRequired,
  refreshBalance: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  itemCode: PropTypes.string.isRequired,
  loyaltyList: PropTypes.shape({}).isRequired,
  i18n: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  isOpen: state.loyaltyShopModal?.isOpen,
  itemCode: state.loyaltyShopModal?.itemCode,
  loyaltyList: state.loyaltyShopModal?.loyaltyList,
});

export const mapDispatchToProps = dispatch => ({
  toggleModalLoyaltyShop: () => dispatch(toggleModalLoyaltyShop()),
  refreshBalance: () => dispatch(balanceRefresh()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(LoyaltyShop);
