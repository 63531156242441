import {
  BALANCE_REFRESH,
} from '../actions/balanceActions';

const initialState = { refresh: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case BALANCE_REFRESH:
      return { refresh: !state.refresh };

    default:
      return state;
  }
}
