/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const sortPromotionWithRecurrences = (value) => {
  const sortedValue = value.sort((a, b) => {
    const to = moment(b.valid_from).format('YYYY-MM-DD');
    const before = moment(a.valid_from).format('YYYY-MM-DD');

    if (moment(before).isAfter(to)) {
      return 1;
    }
    return -1;
  });
  return sortedValue;
};

const formatRecurrences = (items) => {
  const formated = [];
  if (items.length) {
    items.map((item) => {
      if (item.recurrences && item.recurrences.rules && item.recurrences.rules.length !== 0) {
        item.recurrences.items.map((recurrence) => {
          const newItem = [];
          Object.assign(newItem, item);
          newItem.valid_from = recurrence;
          formated.push(newItem);
          return true;
        });
      } else {
        formated.push(item);
      }
      return true;
    });
  }

  return formated;
};

const removeDuplication = value => [...new Set(value)];

export const adjustRecurrences = (items) => {
  let value = formatRecurrences(items);
  value = removeDuplication(value);
  value = sortPromotionWithRecurrences(value);

  return value;
};
