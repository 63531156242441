import {
  TOGGLE_MODAL_RESET_REQUEST,
} from '../actions/passwordResetRequestActions';

const initialState = { isOpen: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_RESET_REQUEST:
      return { isOpen: !state.isOpen };

    default:
      return state;
  }
}
