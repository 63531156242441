import React from 'react';
import PropTypes from 'prop-types';

const PromotionWrapper = ({ children }) => (
  <section className="section m-promo-section">
    <div className="container is-fluid m-promo-article-layout">
      { children }
    </div>
  </section>
);

PromotionWrapper.propTypes = {
  children: PropTypes.instanceOf(Object),
};

PromotionWrapper.defaultProps = {
  children: [],
};

export default PromotionWrapper;
