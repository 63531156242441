import React from 'react';
import PropTypes from 'prop-types';

const SuccessContent = ({
  t,
  item,
  error,
  errorMessage,
  onClick,
  i18n,
}) => {
  const isoLanguage = i18n.language.split('-')[0];
  let imageUrl = [];
  const languages = {};
  languages.languages = {};
  languages.languages[isoLanguage] = {};
  languages.languages[isoLanguage].imageURL = '';
  languages.languages[isoLanguage].imageDescription = '';
  languages.languages[isoLanguage].Description = '';
  try {
    const parsedValue = JSON.parse(item.imageUrl);
    /* istanbul ignore next */
    // eslint-disable-next-line no-unused-expressions
    (parsedValue !== null) ? imageUrl = parsedValue : '';
  } catch (err) {
    imageUrl[0] = languages;
  }

  // eslint-disable-next-line no-unused-expressions
  (imageUrl[0] === undefined || imageUrl[0].languages === undefined) ? imageUrl[0] = languages : '';

  const buySuccessTitle = () => t('common:superstar_buy_title_success');
  const buyErrorTitle = () => t('errors:superstar_buy_title_error');

  const buySuccessDescription = () => {
    if (item.description.toLowerCase().includes('free spin') || item.description.toLowerCase().includes('freespin')) {
      item.type = 'FREE_SPIN';
    }
    switch (item.type) {
      case 'NORMAL_ITEM':
        return t('common:superstar_buy_normal_item_success');
      case 'FREE_SPIN':
        return t('common:superstar_buy_free_spin_success');
      case 'BONUS_PLAN':
        return t('common:superstar_buy_bonus_success', { bonus: item.bonusPlan.amount });
      default:
        return t('common:superstar_buy_default_success');
    }
  };

  const buyErrorDescription = () => errorMessage;

  return (
    <div className="m-loyalty-carousel-item">
      <div className="m-loyalty-shop-item">
        <p className="m-loyalty-shop-item-label">
          <span>{item.price}</span>
          <br />
          {t('account:whammies')}
        </p>
        <img src={`${imageUrl[0].languages[isoLanguage].imageURL === '' || imageUrl[0].languages[isoLanguage].imageURL === null ? 'https://placehold.it/300x300' : imageUrl[0].languages[isoLanguage].imageURL}`} width="300px" height="300px" alt="" />
      </div>
      <div className="content has-text-centered">
        <h3 className="m-loyalty-modal-title is-size-3-tablet is-size-4">
          {error
            ? buyErrorTitle()
            : buySuccessTitle()}
        </h3>
        <div className="has-text-centered">
          <p className="description">
            {error
              ? buyErrorDescription()
              : buySuccessDescription()}
          </p>
        </div>
        <div className="buttons has-text-centered">
          <button
            id="close"
            type="button"
            className="button is-primary is-size-6 is-outlined button-modal-close"
            onClick={onClick}
          >
            {t('buttons:close')}
          </button>
        </div>
      </div>
    </div>
  );
};

SuccessContent.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  item: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    noOfFreespin: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    bonusPlan: PropTypes.shape({
      amount: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  i18n: PropTypes.func.isRequired,
};

export default SuccessContent;
