import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SignupStase from './SignupState';

export const SignupHeaderStepsList = props => (
  <li className={`step-item ${props.currectStateClass}`}>
    <div className="step-details is-primary">
      <p className="is-size-7-mobile is-size-7">{props.details}</p>
    </div>
    <div className="step-marker">
      <span className="step-number">{props.stepId}</span>
      <i className="step-done fa fa-check" />
    </div>
  </li>
);

SignupHeaderStepsList.propTypes = {
  currectStateClass: PropTypes.string.isRequired,
};

const SignupHeader = (props) => {
  const { t } = useTranslation('common');
  const { cancelButton } = props;
  const isActive = 'is-active';
  const isDefault = '';
  const isCompleted = 'is-completed';

  function getClassState(state) {
    let result = isDefault;
    if (props.signupState !== 6) {
      if (state === props.signupState) {
        result = isActive;
      } else if (state < props.signupState) {
        result = isCompleted;
      }
    } else {
      result = isActive;
    }

    return result;
  }

  const stepsList = [
    { id: 1, value: t('account_details'), currectStateClass: getClassState(SignupStase.first) },
    { id: 2, value: t('personal_details'), currectStateClass: getClassState(SignupStase.second) },
    { id: 3, value: t('sms_verification'), currectStateClass: getClassState(SignupStase.third) },
    { id: 4, value: t('get_your_bonus'), currectStateClass: getClassState(SignupStase.fourth) },
  ];

  const steps = stepsList.map(item => (
    <SignupHeaderStepsList
      key={item.id}
      details={item.value}
      stepId={item.id}
      currectStateClass={item.currectStateClass}
    />
  ));

  return (
    <header className="modal-card-head" id="modal-card-head">
      <div className="columns">
        <div className="column is-5">
          <h1
            className="modal-card-title is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-centered has-text-left-tablet has-text-white"
          >
            {t('sign_up')}
            <br />
            <small className="is-size-5 is-size-6-touch has-text-weight-normal" dangerouslySetInnerHTML={{ __html: t('or_login_to_play') }} />
          </h1>
        </div>
        <div className="column is-7">
          <ul className="steps is-medium m-steps">{steps}</ul>
        </div>
      </div>
      <button type="button" className="delete" aria-label="close" onClick={cancelButton} />
      <button type="button" aria-label="close" onClick={cancelButton} className="m-modal-close-button button-modal-close">
        <i className="fas fa-times-circle" />
      </button>
    </header>
  );
};

SignupHeader.propTypes = {
  cancelButton: PropTypes.func.isRequired,
};

export default SignupHeader;
