import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import MandatoryField from '../../Layout/Fields/MandatoryField';
import { RenderField } from '../../Layout/Fields/renderFields';
import RenderTextAreaField from '../../Layout/Fields/renderTextAreaFields';
import RecaptchaText from '../../Layout/Fields/RecaptchaText';
import validate from '../../../containers/utitilies/validator_email_send';
import PageTitle from '../PageTitle';

export const EmailPageContent = ({
  data,
  handleEmail,
  handleFirstNameChange,
  handleLastNameChange,
  handleEmailChange,
  handleQuestionChange,
  pristine,
  invalid,
}) => {
  const { t } = useTranslation();
  const [validRecaptcha, setValidRecaptcha] = useState(false);

  const onRecaptchaValidation = () => setValidRecaptcha(true);

  return (
    <section className="section m-page-wrapper">
      <div className="container is-fluid">
        <div className="columns is-multiline is-centered">
          <div className="column is-12-mobile is-12-tablet is-9-widescreen">
            <div className="content typography">
              <PageTitle title={data.title} show={data.meta.show_title} />
              <p><strong className="has-text-white">{ data.sub_title }</strong></p>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: data.description }} />
            </div>
          </div>
          <div className="column is-8-tablet is-5-desktop m-contact-form">
            <hr />
            <MandatoryField text="email_fields" />
            <form>
              <div className="field">
                <label className="label">{t('fields:first_name')}</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="firstName"
                    className="input is-medium"
                    type="text"
                    placeholder={t('fields:type_here')}
                    onChange={handleFirstNameChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label">{t('fields:last_name')}</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="lastName"
                    className="input is-medium"
                    type="text"
                    placeholder={t('fields:type_here')}
                    onChange={handleLastNameChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>

                </div>
              </div>

              <div className="field">
                <Field
                  label={t('fields:email')}
                  className="input"
                  id="email"
                  component={RenderField}
                  onChange={handleEmailChange}
                  name="email"
                  required="required"
                  type="text"
                  placeholder={t('fields:type_here')}
                  icon="fa-envelope"
                />
              </div>

              <div className="field">
                <Field
                  label={t('fields:question')}
                  className="input has-background-dark"
                  id="question"
                  component={RenderTextAreaField}
                  onChange={handleQuestionChange}
                  name="question"
                  required="required"
                  placeholder={t('fields:type_here')}
                  icon="fa-envelope"
                />
              </div>
              <div className="has-text-centered content typography">
                <RecaptchaText />
              </div>
              <div className="field has-text-centered">
                <button
                  id="submit"
                  disabled={pristine || invalid || !validRecaptcha}
                  data-modal-target="modal-email-support-confirm"
                  type="submit"
                  className="button is-success"
                  onClick={e => handleEmail(e)}
                >
                  {t('buttons:submit')}
                </button>
              </div>
              <GoogleReCaptcha onVerify={onRecaptchaValidation} />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

EmailPageContent.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
    meta: PropTypes.instanceOf(Object),
  }),
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleEmail: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
};

EmailPageContent.defaultProps = {
  data: {
    meta: {
      show_title: true,
    },
  },
};

export default reduxForm({
  form: 'emailForm',
  validate,
})(EmailPageContent);
