import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

const Skeleton = (
  {
    type,
    color,
    height,
    width,
    style,
    customClass,
    customStyle,
  },
) => (
  <div style={{ ...style, ...customStyle }} className={customClass}>
    <Loader
      type={type}
      color={color}
      height={height}
      width={width}
    />
  </div>
);

Skeleton.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  customClass: PropTypes.string,
  customStyle: PropTypes.instanceOf(Object),
};

Skeleton.defaultProps = {
  type: 'ThreeDots',
  color: 'rgba(255, 255, 255, 0.6)',
  height: '150',
  width: '150',
  customClass: 'loader-container',
  style: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  customStyle: {},
};

export default Skeleton;
