/* eslint-disable camelcase */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProviderLogo from './GameProviderLogo';
import FavoriteButton from './FavoriteButton';
import useGames from '../../../hooks/games/useGames';

const GameThumbnail = ({
  id,
  reference,
  provider,
  name,
  thumbnail,
  supports_demo_play,
  tags,
  slug,
  status,
  mega_thumb,
  vertical_thumbnail,
  favorite,
  studio,
}) => {
  const { t, i18n } = useTranslation();
  const el = useRef(null);
  const { providers } = useGames();
  const history = useHistory();

  const isNew = tags.includes('new');
  let image = 'https://placehold.it/450x300';

  if (thumbnail) image = thumbnail.url;

  const playGame = (realGame) => {
    history.push({
      pathname: `/${i18n.language}/games/${slug}/`,
      search: `${realGame ? '?mode=real' : ''}`,
      state: {
        supportsDemoPlay: supports_demo_play,
        prevPath: window.location.pathname + window.location.search,
      },
    });
  };

  const GameNavLink = () => (
    <>
      <ProviderLogo providerList={providers.items} slug={studio?.slug ? studio.slug : provider.slug} />

      <FavoriteButton
        key={id}
        id={reference}
        provider={provider.id}
        favorite={favorite}
      />

      <button
        type="button"
        key={`${slug}real`}
        onClick={() => playGame(true)}
        className="button is-success button-play-for-real"
      >
        {t('buttons:play_for_real')}
      </button>
      {supports_demo_play && (
        <button
          type="button"
          key={`${slug}free`}
          onClick={() => playGame()}
          className="button-play-for-free"
        >
          {t('buttons:play_for_free')}
        </button>
      )}
    </>
  );

  const GameMaintenance = () => (
    <div className="button is-danger is-maintenance">
      <i className="icon-maintenance is-size-3" />
      <br />
      <p>{t('buttons:under_maintenance')}</p>
    </div>
  );

  return (
    <div
      className={`m-game-grid-item column
        ${mega_thumb ? 'featured-grid' : ''}
        ${vertical_thumbnail?.id ? 'vertical-grid' : ''}`}
      ref={el}
    >
      <LoadImages image={image} name={name} isNew={isNew} t={t} />
      <div className="m-game-grid-buttons is-flex">
        {status === 'enabled' ? <GameNavLink /> : <GameMaintenance />}
      </div>
    </div>
  );
};

GameThumbnail.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.instanceOf(Object),
  supports_demo_play: PropTypes.bool.isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
  slug: PropTypes.string,
  status: PropTypes.string.isRequired,
  mega_thumb: PropTypes.bool,
  vertical_thumbnail: PropTypes.instanceOf(Object),
  id: PropTypes.number,
  provider: PropTypes.instanceOf(Object),
  reference: PropTypes.string,
  favorite: PropTypes.bool,
  studio: PropTypes.instanceOf(Object),
};

GameThumbnail.defaultProps = {
  thumbnail: null,
  mega_thumb: false,
  vertical_thumbnail: {},
  id: 0,
  favorite: false,
  slug: '',
  reference: '',
  provider: {},
  studio: {},
};

export default GameThumbnail;

const LoadImages = ({
  // eslint-disable-next-line react/prop-types
  image, name, isNew, t,
}) => (
  <figure className="thumbnail-image">
    <img
      src={image}
      alt={name}
      height="100%"
      width="100%"
      loading="lazy"
    />
    {isNew && <div className="m-game-grid-item__label">{t('common:new')}</div>}
  </figure>
);
