import resources from './resources';

export const config = {
  debug: false,
  interpolation: { escapeValue: false },
  lng: 'en-int',
  fallbackLng: 'en-int',
  resources,
};

export { resources };
