import React from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import BlogHeader from './BlogHeader';
import BlogWrapper from './BlogWrapper';
import FeaturedArticle from '../Article/FeaturedArticle';
import PromotionWidget from '../Promotion/PromotionWidget';
import BlogArticleManager from './BlogArticleManager';
import PromotionWidgetWrapper from '../Promotion/PromotionWidgetWrapper';

const BlogPage = ({ data }) => (
  <BasePage data={data}>
    <BlogWrapper>
      <BlogHeader>
        {data.featured_blog
          && <FeaturedArticle data={data.featured_blog} title={data.title} showTitle={data.meta.show_title} />}
        {data.featured_promotion
        && (
          <PromotionWidgetWrapper id={data.featured_promotion.id}>
            {({ items }) => (
              <PromotionWidget items={items} linkTo={data.featured_promotion.html_url} />
            )}
          </PromotionWidgetWrapper>
        )}
      </BlogHeader>
      <BlogArticleManager data={data} />
    </BlogWrapper>
  </BasePage>
);

BlogPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    featured_blog: PropTypes.instanceOf(Object).isRequired,
    featured_promotion: PropTypes.instanceOf(Object).isRequired,
  }),
};

BlogPage.defaultProps = {
  data: {},
};

export default BlogPage;
