import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseInput from '../../../Layout/Fields/BaseInput';
import BaseSelect from '../../../Layout/Fields/BaseSelect';
import BaseCheckbox from '../../../Layout/Fields/BaseCheckbox';
import BaseMultibox from '../../../Layout/Fields/BaseMultibox';
import DateSelects from '../../../Layout/Fields/DateSelects';

const Input = (props) => {
  const { t } = useTranslation('common');

  let item;

  const {
    elementType, elementDescription, errorDescription, elementConfig, value, onChange, valid,
    onlineValid, touched, validation, items, title, columnClass, corrected,
  } = props;

  switch (elementType) {
    case 'input':
      item = (
        <BaseInput
          description={elementDescription}
          errorDescription={errorDescription}
          config={elementConfig}
          value={value}
          onChange={onChange}
          valid={valid}
          onlineValid={onlineValid}
          touched={touched}
          validation={validation}
          corrected={corrected}
          correctedMessage={t('phone_number_corrected')}
        />
      );
      break;
    case 'select':
      item = (
        <BaseSelect
          description={elementDescription}
          errorDescription={errorDescription}
          config={elementConfig}
          value={value}
          onChange={onChange}
          valid={valid}
          touched={touched}
          items={items}
          validation={validation}
          columnClass={columnClass}
        />
      );
      break;
    case 'checkbox':
      item = (
        <BaseCheckbox
          title={title}
          description={elementDescription}
          config={elementConfig}
          value={value}
          onChange={onChange}
          valid={valid}
          touched={touched}
          items={items}
          validation={validation}
          errorDescription={errorDescription}
        />
      );
      break;
    case 'multibox':
      item = (
        <BaseMultibox
          title={title}
          errorDescription={errorDescription}
          description={elementDescription}
          config={elementConfig}
          value={value}
          onChange={onChange}
          valid={valid}
          touched={touched}
          items={items}
          validation={validation}
        />
      );
      break;
    case 'date-selects':
      item = (
        <DateSelects
          description={elementDescription}
          errorDescription={errorDescription}
          valid={valid}
          validation={validation}
          onChange={onChange}
          config={elementConfig}
          value={value}
          title={title}
          touched={touched}
          daysTitle={t('fields:day')}
          monthTitle={t('fields:month')}
          yearTitle={t('fields:year')}
        />
      );
      break;
    default:
      break;
  }

  return item;
};

export default Input;
