import {
  TOGGLE_MODAL_RESEND,
} from '../actions/resendVerificationCodeActions';

const initialState = {
  isOpen: false,
  onlySendCodeView: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_RESEND:
      return {
        isOpen: !state.isOpen,
        onlySendCodeView: action.onlySendCodeView,
      };

    default:
      return state;
  }
}
