import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { configureAnchors, goToAnchor } from 'react-scrollable-anchor';
import GameBrowser from '../../Game/GameBrowser';
import BasePage from '../Base';
import Banner from '../../Layout/Banner';

const GamesPage = ({ data }) => {
  useEffect(() => {
    const offset = (window.innerWidth < 846) ? -80 : 1;
    configureAnchors({ offset });
    goToAnchor('gamesAnchor', false);
  }, []);

  return (
    <div className="games-page-wrapper">
      <BasePage data={data}>
        <Banner data={data} />
        <GameBrowser />
      </BasePage>
    </div>
  );
};

GamesPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }),
};

GamesPage.defaultProps = {
  data: {},
};

export default GamesPage;
