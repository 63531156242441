import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { Cookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import AccountBalanceWrapper from '../Account/AccountBalanceWrapper';
import { capitalize, callCashierModal } from '../../Helpers';

export const BalanceField = ({ text, balance }) => (
  <div className="card m-user-dashboard-info has-background-x-grey">
    <div className="card-content is-flex">
      <div className="level">
        <div className="level-left">
          <h3 className="has-text-white is-marginless is-size-6-touch is-size-6">
            {text}
          </h3>
        </div>
        <div className="level-right">
          <h3 className="has-text-white is-marginless is-size-6-touch is-size-6 balance">
            {balance}
          </h3>
        </div>
      </div>
    </div>
  </div>
);

BalanceField.propTypes = {
  balance: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const HeroBalanceBodyDetailed = ({ balance, withdrawableBalance, bonusBalance }) => {
  function callModal(e) {
    callCashierModal(e);
  }
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const { sessionKey } = new Cookies().get('Session');

      const userData = await new Casino.PlayerInfo(sessionKey).perform();
      const nameToDisplay = (userData.data.firstName !== '' && userData.data.firstName !== null) ? userData.data.firstName : userData.data.userId;

      setName(nameToDisplay);
    };
    fetchUser();
  }, []);

  return (
    <div className="card m-user-dashboard-card m-dashboard-balance content is-size-6-touch">
      <div className="card-title">
        <h4 className="has-text-white is-size-6-mobile has-text-centered-mobile">{`${t('common:welcome_back')}, ${capitalize(name?.toLowerCase())}!`}</h4>
      </div>
      <div className="card-content has-text-white content">
        <BalanceField text={t('account:your_balance')} balance={balance} />
        <BalanceField text={t('account:withdrawable_balance')} balance={withdrawableBalance} />
        <BalanceField text={t('account:bonus_balance')} balance={bonusBalance} />
      </div>
      <div className="card-content has-text-white content">
        <div className="buttons">
          <a
            href="/#"
            id="withdraw"
            className="button is-large is-dark-green is-size-5-mobile is-uppercase is-full-width"
            onClick={e => callModal(e)}
          >
            {t('buttons:deposit_now')}
          </a>
        </div>
      </div>
    </div>
  );
};

HeroBalanceBodyDetailed.propTypes = {
  balance: PropTypes.string,
  withdrawableBalance: PropTypes.string,
  bonusBalance: PropTypes.string,
};

HeroBalanceBodyDetailed.defaultProps = {
  balance: '00.00',
  withdrawableBalance: '00.00',
  bonusBalance: '00.00',
};

export const HeroBalanceBody = ({ balance }) => {
  function callModal(e) {
    callCashierModal(e);
  }
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const { sessionKey } = new Cookies().get('Session');

      const userData = await new Casino.PlayerInfo(sessionKey).perform();
      const nameToDisplay = (userData.data.firstName !== '') ? userData.data.firstName : userData.data.userId;

      setName(nameToDisplay);
    };
    fetchUser();
  }, []);

  return (
    <div className="card m-user-dashboard-card content is-size-6-touch">
      <div className="card-title">
        <h4 className="has-text-white is-size-6-mobile has-text-centered">{`${t('common:welcome_back')}, ${capitalize(name.toLowerCase())}!`}</h4>
      </div>
      <div className="card-content has-text-white content">
        <div className="card m-user-dashboard-info has-background-x-grey">
          <div className="card-content is-flex">
            <div className="level">
              <div className="level-left">
                <h3 className="has-text-white has-text-center is-marginless is-size-6-touch is-size-5">
                  <i className="icon-deposit" />
                  &nbsp;
                  {t('account:your_balance')}
                </h3>
              </div>
              <div className="level-right">
                <h3 className="has-text-white is-marginless is-size-6-touch is-size-5 balance">{balance}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <a
            href="/#"
            id="withdraw"
            className="button is-large is-success is-size-7-mobile is-uppercase is-full-width"
            onClick={e => callModal(e)}
          >
            {t('buttons:deposit_now')}
          </a>
        </div>
      </div>
    </div>
  );
};

HeroBalanceBody.propTypes = {
  balance: PropTypes.string,
};

HeroBalanceBody.defaultProps = {
  balance: '00.00',
};

const HeroBalance = () => (
  <AccountBalanceWrapper disableGeneralError>
    {props => (
      <HeroBalanceBody {...props} />
    )}
  </AccountBalanceWrapper>
);

export default HeroBalance;
