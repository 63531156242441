import React from 'react';
import { useDispatch } from 'react-redux';
import Games from '../../lib/api/cms/gamesService/Games';
import FavoriteGames from '../../lib/api/omega/playerService/games/FavoriteGames';
import usePlayer from '../usePlayer';
import useGames from './useGames';
import { updateFavoriteGames } from '../../redux/actions/gamesActions';

const gamesApi = new Games();
const favoriteGamesApi = new FavoriteGames();

function useFavoriteGames() {
  const dispatch = useDispatch();
  const { player } = usePlayer();
  const { providers } = useGames();

  const fetchFavoriteGames = React.useCallback((reference) => {
    const response = gamesApi.getFavoriteGamesByReference(reference);
    return response;
  }, []);

  const addToFavoriteGames = React.useCallback(async (gameId, providerId) => {
    const platformCode = providers.items
      ?.find(provider => provider.id === providerId)?.platform_code;

    const result = await favoriteGamesApi.addFavoriteGame(
      gameId,
      platformCode,
      player.sessionKey,
    );

    if (result.data.status === 'SUCCESS') {
      dispatch(updateFavoriteGames(player.sessionKey));
    }
  }, [dispatch, player.sessionKey, providers.items]);

  const removeFromFavoriteGames = React.useCallback(
    async (gameId, providerId) => {
      const platformCode = providers.items?.find(
        provider => provider.id === providerId,
      )?.platform_code;

      const favoriteGamesResponse = await favoriteGamesApi.removeFavoriteGame(
        gameId,
        platformCode,
        player.sessionKey,
      );

      if (favoriteGamesResponse.data?.status === 'SUCCESS') {
        dispatch(updateFavoriteGames(player.sessionKey));
      }
    },
    [dispatch, player.sessionKey, providers.items],
  );

  return {
    fetchFavoriteGames,
    addToFavoriteGames,
    removeFromFavoriteGames,
  };
}

export default useFavoriteGames;
