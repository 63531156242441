import {
  TOGGLE_MODAL_ERROR,
} from '../actions/errorModalActions';

const initialState = {
  isOpen: false,
  message: null,
  connectionError: false,
  calledFunction: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL_ERROR:
      return {
        ...state,
        isOpen: action.isOpen || !state.isOpen,
        message: action.message,
        connectionError: action.connectionError,
        calledFunction: action.calledFunction,
      };
    default:
      return state;
  }
}
