import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollableAnchor from 'react-scrollable-anchor';
import GameMenu from '../GameMenu';
import GameProviderMenu from '../GameProviderMenu';
import GameSearch from '../GameSearch';
import useGamesAnchor from '../../../hooks/games/useGamesAnchor';

const GameNavigation = () => {
  const { t } = useTranslation('common');
  const [isActive, setIsActive] = useState(false);
  const handleTrigger = active => setIsActive(active);
  useGamesAnchor();

  return (
    <ScrollableAnchor id="gamesAnchor">
      <div className={`m-game-nav-parent ${isActive ? ' is-active' : ''}`}>
        <div className="m-game-nav container is-fluid">
          <div className="level is-mobile">
            <div className="level-left">
              <GameMenu />
            </div>
            <div className="level-right is-flex">
              <GameProviderMenu title={t('providers')} />
              <GameSearch title={t('search')} onTrigger={handleTrigger} />
            </div>
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default React.memo(GameNavigation);
