export const TOGGLE_MODAL_ERROR = 'TOGGLE_MODAL_ERROR';

export function toggleModalError(message = null, isOpen = null, connectionError = true, calledFunction = '') {
  return {
    type: TOGGLE_MODAL_ERROR,
    message,
    isOpen,
    connectionError,
    calledFunction,
  };
}
