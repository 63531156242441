import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BackgroundImage = (
  {
    src,
    children,
    elementClass,
    timeOut,
  },
) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const onLoad = () => {
      /* istanbul ignore next */
      setTimeout(() => setLoaded(true), timeOut);
    };

    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => onLoad();
  }, [src, timeOut]);

  const style = {};

  if (loaded) {
    style.backgroundImage = `url(${src})`;
  }

  return (
    <div className={`${elementClass}${!loaded ? ' has-background-dark' : ''}`} style={style}>
      {children}
    </div>
  );
};

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  elementClass: PropTypes.string,
  timeOut: PropTypes.number,
};

BackgroundImage.defaultProps = {
  elementClass: '',
  timeOut: 1,
};

export default BackgroundImage;
