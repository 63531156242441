import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const BaseMultibox = (props) => {
  const {
    value,
    valid,
    touched,
    items,
    description,
    onChange,
    errorDescription,
    validation,
  } = props;

  const { t } = useTranslation('common');
  let warningMessage = '';
  const errorDescriptionLabel = errorDescription || 'This field is invalid';
  const defaultClassName = ['column', 'is-6', 'field'];
  let newItems = [];

  if (!valid && touched && validation.required) {
    warningMessage = <p className="help is-danger">{errorDescriptionLabel}</p>;
  }

  if (items) {
    let itemCount = 0;
    newItems = items.map((item) => {
      const checked = item.value === value;
      itemCount += 1;
      const input = (
        <input
          key={itemCount}
          style={{ display: 'none' }}
          className="is-checkradio is-medium"
          id={`registration_${item.title}`}
          type="radio"
          name="registration"
          checked={checked}
          value={item.value}
          onChange={onChange}
        />
      );
      itemCount += 1;
      const label = (
        <label key={itemCount} htmlFor={`registration_${item.title}`}>{t(item.description)}</label>
      );
      return [input, label];
    });
  }

  return (
    <div className={defaultClassName.join(' ')}>
      <label className="label">{description}</label>
      <div className="field m-field-radio">
        {newItems}
      </div>
      {warningMessage}
    </div>
  );
};

BaseMultibox.propTypes = {
  errorDescription: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
};

BaseMultibox.defaultProps = {
  errorDescription: '',
  valid: false,
  validation: {},
  value: '',
};

export default BaseMultibox;
