import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setGamesByCategories, setActiveCategory, setActiveGame } from '../../redux/actions/gamesActions';
import useGames from './useGames';
import { isHomePage } from '../../components/Helpers';
import useActiveGames from './useActiveGames';
import useQuery from '../useQuery';
import { usePageTypeContext } from '../../containers/App/PageTypeContext';

const allowedPageTypes = [
  'HomePage',
  'GamePage',
  'GameCategoryPage',
  'GameProviderPage',
  'gamePage',
];

let fetched = false;

function useGamesController() {
  const { categories, providers } = useGames();
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();
  const { pathname } = useLocation();
  const { pageType } = usePageTypeContext();
  const { query } = useQuery();
  const dispatch = useDispatch();
  const newSearch = query.get('search');
  const isSuperUser = query.get('superuser');

  const selectedCategory = !!activeCategory?.id
          || !!activeProvider?.id
          || !!activeStudio?.id;

  useEffect(() => {
    if (categories.isLoading && providers.isLoading && fetched) return;

    if (allowedPageTypes.includes(pageType)) {
      if (
        (isHomePage(pathname)
          && (!newSearch
          || isSuperUser) && !selectedCategory)
        || (pageType === 'GamePage' && !selectedCategory)
      ) {
        dispatch(setGamesByCategories(categories.items, isMobile));
      } else if (activeCategory?.id || activeProvider?.id || activeStudio?.id) {
        dispatch(
          setActiveCategory({
            category: activeCategory,
            provider: activeProvider,
            gameStudio: activeStudio,
            mobile: isMobile,
          }),
        );
      } else if (pageType === 'GameProviderPage') {
        dispatch(
          setActiveCategory({
            category: null,
            provider: activeProvider,
            mobiele: isMobile,
          }),
        );
      } else if (pageType === 'gamePage') {
        if (pageType === 'gamePage') {
          const [, , gameSlugWithoutLocale, gameSlug] = pathname.split('/');
          dispatch(setActiveGame(gameSlug || gameSlugWithoutLocale));
        }
      }
      fetched = true;
    }
  }, [
    dispatch,
    categories,
    providers,
    activeCategory,
    activeProvider,
    activeStudio,
    selectedCategory,
    pageType,
    pathname,
    newSearch,
    isSuperUser,
  ]);

  return null;
}

export default useGamesController;
