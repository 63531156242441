import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateSelect from './select';

const DateSelects = ({
  onChange, value, validation, valid, description, errorDescription, config, title, daysTitle,
  yearTitle, monthTitle, touched,
}) => {
  const DAY_INDEX = 2;
  const MONTH_INDEX = 1;
  const YEAR_INDEX = 0;
  const DATE_ARRAY_LENGTH = 3;

  const inputIsSuccess = 'is-success';
  const inputIsDanger = 'is-danger';
  const inputClasses = ['input', 'is-medium'];
  const errorDescriptionLabel = errorDescription || 'This field is invalid';
  const iconIsSuccess = 'fas fa-check has-text-success';
  const iconIsDanger = 'fas fa-exclamation-triangle has-text-danger';
  const { t } = useTranslation();

  const handleChange = (index, event) => {
    let splited = value.split('-');

    if (splited.length !== DATE_ARRAY_LENGTH) {
      splited = new Array(DATE_ARRAY_LENGTH).fill('0');
    }

    splited[index] = event.target.value;

    onChange({
      target: {
        value: splited.join('-'),
      },
    });
  };

  const getExtraErrorDescription = () => {
    const splited = value.split('-');
    if (splited.length === DATE_ARRAY_LENGTH
      && splited[DAY_INDEX] !== '0'
      && splited[MONTH_INDEX] !== '0'
      && splited[YEAR_INDEX] !== '0') {
      const today = moment().format('YYYY-MM-DD');
      if (!moment(splited.join('-')).isBefore(today)) {
        return t('errors:required_please_select_a_valid_date');
      }
    }
    return errorDescriptionLabel;
  };

  const year = (new Date()).getFullYear() - 18;
  const years = Array.from(new Array(100), (val, index) => -index + year);
  const months = Array.from(new Array(12), (val, index) => index + 1);
  const days = Array.from(new Array(31), (val, index) => index + 1);

  let rightIcon;
  let warningMessage;

  if (!valid && touched && validation.required) {
    inputClasses.push(inputIsDanger);
    warningMessage = <p className="help is-danger">{getExtraErrorDescription()}</p>;
    rightIcon = iconIsDanger;
  } else if (valid && touched && validation.required) {
    inputClasses.push(inputIsSuccess);
    rightIcon = iconIsSuccess;
  }


  return (
    <>
      <div className="column is-6 field">
        <label id="dateOfBirthLabel" htmlFor="dateofbirth" className="label">{description}</label>
        <div className="m-control-calendar is-medium">
          <div className="field is-grouped">
            <div className="m-dummy-input-container">
              <div className="control has-icons-left has-icons-right">
                <div className={inputClasses.join(' ')}>
                  <input id={`registration_${title}`} name={`registration[${title}]`} className="input is-medium is-hidden" readOnly type="date" value={value} />
                </div>
                <span className="icon is-small is-left">
                  <i className={config.leftIcon} />
                </span>
                <span className="icon is-small is-right">
                  <i className={rightIcon} />
                </span>
              </div>
            </div>
            <div className="m-date-selects field is-grouped">
              <DateSelect id="day" value={value.split('-')[DAY_INDEX] || undefined} title={daysTitle} index="0" items={days} onChange={e => handleChange(DAY_INDEX, e)} />
              <p className="control is-size-4-tablet is-size-6 m-select-seperator">/</p>
              <DateSelect id="month" value={value.split('-')[MONTH_INDEX] || undefined} title={monthTitle} index="1" items={months} onChange={e => handleChange(MONTH_INDEX, e)} />
              <p className="control is-size-4-tablet is-size-6 m-select-seperator">/</p>
              <DateSelect id="year" value={value.split('-')[YEAR_INDEX] || undefined} title={yearTitle} index="2" items={years} onChange={e => handleChange(YEAR_INDEX, e)} />
            </div>
          </div>
        </div>
        {warningMessage}
      </div>
    </>
  );
};

DateSelects.propTypes = {
  description: PropTypes.string.isRequired,
  config: PropTypes.shape({ leftIcon: PropTypes.string.isRequired }).isRequired,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  daysTitle: PropTypes.string.isRequired,
  monthTitle: PropTypes.string.isRequired,
  yearTitle: PropTypes.string.isRequired,
  errorDescription: PropTypes.string.isRequired,
  validation: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
  valid: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

DateSelects.defaultProps = {
  value: '',
};

export default DateSelects;
