import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { withTranslation } from 'react-i18next';
import PromotionWrapper from '../Promotion/PromotionWrapper';
import PromotionHeader from '../Promotion/PromotionHeader';
import ArticleMenu from './ArticleMenu';
import FeaturedArticle from './FeaturedArticle';
import ArticlePageDetails from './ArticlePageDetails';
import PromotionWidget from '../Promotion/PromotionWidget';
import BasePage from '../Base';
import PromotionWidgetWrapper from '../Promotion/PromotionWidgetWrapper';
import BlogCategoryMenu from '../Blog/BlogCategoryMenu';
import { getBonusOffer } from '../../Helpers';
import Skeleton from '../../Layout/Skeleton';

class ArticlePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      parent: null,
      bonusOffer: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { data } = this.props;
    let bonusOffer = {};

    if (data.meta.parent.meta.type === 'content.PromotionPage') {
      bonusOffer = await this.getBonus(data.meta.parent.id);
    }

    const parentPage = await this.fetchPage(data.meta.parent.id);

    this.setState({
      parent: parentPage.data,
      bonusOffer,
    });
  }

  getBonus = async (id) => {
    const bonus = await getBonusOffer(id);
    return bonus;
  }

  fetchPage = async (id) => {
    const result = await new Casino.FetchContent(`pages/${id}`).perform();
    return result;
  };

  fetchParentPage = async (id) => {
    const parentPage = await this.fetchPage(id);
    return parentPage;
  };

  articleWidget = () => {
    const { parent } = this.state;

    let widget;
    if (parent && parent.meta.type === 'content.BlogPage' && parent.featured_promotion) {
      /* istanbul ignore next */
      widget = (
        <PromotionWidgetWrapper id={parent.featured_promotion.id}>
          {({ items }) => (
            <PromotionWidget items={items} linkTo={parent.featured_promotion.html_url} />
          )}
        </PromotionWidgetWrapper>
      );
    }
    return widget;
  };

  render() {
    const {
      parent,
      bonusOffer,
    } = this.state;

    const { t, i18n, data } = this.props;
    const widget = this.articleWidget();

    let menuHandle = `${i18n.language}_promotion-menu`;
    let menuTitle = t('common:promotions');

    if (parent?.promotion_menu) {
      menuHandle = parent.promotion_menu.handle;
      menuTitle = parent.promotion_menu.title;
    }

    const customStyle = {
      minHeight: '540px',
      width: '100%',
      marginTop: '3rem',
    };

    return (
      <BasePage data={data}>
        <PromotionWrapper>
          <PromotionHeader hiddenHero>
            {!parent && <Skeleton customStyle={customStyle} />}
            {parent && parent.featured_blog && parent.meta.type === 'content.BlogPage'
            && <FeaturedArticle data={parent.featured_blog} title={parent.title} />}
            {parent && parent.featured_promotion && parent.meta.type === 'content.PromotionPage'
            && <FeaturedArticle data={bonusOffer.currentBonus} title={parent.title} />}
            {widget}
          </PromotionHeader>
          {parent && parent.meta.type === 'content.BlogPage'
            && <BlogCategoryMenu data={parent} title={t('common:blog')} />}
          {parent && parent.meta.type === 'content.PromotionPage'
            && <ArticleMenu handle={menuHandle} title={menuTitle} />}
          <ArticlePageDetails data={data} />
        </PromotionWrapper>
      </BasePage>
    );
  }
}

ArticlePage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};

export default withTranslation()(ArticlePage);
