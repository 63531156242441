import React from 'react';
import PropTypes from 'prop-types';

const BlogWrapper = ({ children }) => (
  <section className="section m-promo-section">
    <div className="container is-fluid m-promo-article-layout">
      { children }
    </div>
  </section>
);

BlogWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogWrapper;
