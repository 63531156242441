import React from 'react';
import PropTypes from 'prop-types';

const BlogHeader = ({ children }) => (
  <div className="columns is-multiline is-mobile m-promo-columns">
    { children }
  </div>
);

BlogHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogHeader;
